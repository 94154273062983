import { AbstractChain, Point } from '@scfe-common/chain-tracker';
import chains from './config';

class OperateChain extends AbstractChain {
  name: string;
  status: Record<string, any>;
  pointList: Point[];
  constructor(chain: any) {
    super();
    this.name = chain.name;
    this.status = chain.status;
    this.status.type = 'operateTime';
    this.pointList = chain.getPointList.call(this);
  }
}
const chainList = chains.map((chain) => {
  return new OperateChain(chain);
});

export default chainList;
