// tsp 默认翻译缩写
const locales = {
  'en': { _name: 'English' },
  'zh-Hans': { _name: '简体中文' },
  'zh-Hant': { _name: '繁體中文' },
  'vi': { _name: 'Vietnamese' },
  'th': { _name: 'Thai' },
  'id': { _name: 'Bahasa Indonesia' },
  'ph': { _name: 'Tagalog' },
  'ms-my': { _name: 'Bahasa Malayu' },
  'es-MX': { _name: 'Spanish' },
};

// 现有系统缩写与 tsp map 对应
const tspLanguageMap = new Map([
  ['zh-CN', 'zh-Hans'],
  ['zh-TW', 'zh-Hant'],
  ['tl-PH', 'ph'],
  ['ms-MY', 'ms-my'],
  ['sp-MX', 'es-MX'],
]);

export { locales, tspLanguageMap };
