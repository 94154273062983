// import Layout from '../../views/layout/index.vue';
const home = {
  path: '/',
  name: 'home',
  // component: () => import('@/views/layout/index.vue'),
  component: () => import('@/views/layout/index.vue'),
  meta: {
    menuHide: true,
  },
  redirect: '/home',
  children: [
    {
      path: '/home',
      name: 'homePage',
      component: () => import('../../views/layout/home.vue'),
      meta: {
        navList: [],
      },
    },
  ],
};

export default home;
