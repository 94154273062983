import { numericTracker, reporter } from '@/utils/qms';
export interface TransactionData {
  /**
   * 事务名称(API)
   */
  name: string;
  /**
   * 事务ID
   */
  id: string;
  /**
   * 父级事务ID
   */
  parentId?: string;
  /**
   * 总耗时, ms
   */
  duration: number;
  /**
   * 结果编码，0为成功
   */
  code: number;
  /**
   * 业务逻辑码（原始值）
   */
  bizCode?: number;
  /**
   * http状态码
   */
  httpCode?: number;
  /**
   * 追踪ID
   */
  traceId: string;
  /**
   * 信息
   */
  msg?: string;
  /**
   * 开始时间
   */
  startTime: number;
  /**
   * 服务端处理耗时, ms
   */
  backendDuration?: number;
  /**
   * 服务端请求ID
   */
  requestId?: string;
  /**
   * 更多信息
   */
  extraData?: ExtraData;
}

// ⚠️ TypeScript 4.1 版本以上才支持 Template Literal Types
export interface ExtraData {
  /**
   * 值为string类型的key，以_s结尾
   */
  // [stringKey: `${string}_s`]: string;
  /**
   * 值为number类型的key，以_n结尾
   */
  // [numberKey: `${string}_n`]: number;
  [propName: string]: string | number;
}

// 上报
export const reportTransaction = (data: TransactionData) => {
  return reporter.report({
    s: location.pathname + location.hash,
    dt: 'transaction', // 报到transaction
    d: data,
    r: 1, // 不采样
  } as any);
};

export const reportErrorCount = function (data: any = {}) {
  if (numericTracker) {
    numericTracker.collect({
      d: {
        type: 'exposure',
        ...data,
      },
      dt: 'count',
      s: location.href,
    });
  }
};

export default reporter;
