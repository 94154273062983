import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

@Module
class ThreePL extends VuexModule {
  laneCodeList = [];
  // laneCodeMap: any = {};
  @Mutation
  setThreePL(list: any): void {
    this.laneCodeList = list || [];
    // list.forEach((item: any) => {
    //   const { value, label } = item;
    //   this.laneCodeMap[value] = label;
    // });
  }
  @Action({ commit: 'setThreePL' })
  threePlSearch(resp: any) {
    return resp;
  }
}
export default ThreePL;
