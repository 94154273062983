export default function isEmpty(val: any) {
  if (val === '' || val === null || val === undefined) {
    return true;
  }

  if (typeof val === 'object') {
    return !Object.keys(val).length;
  }

  return false;
}

export function emptyListFormat(list: any[]) {
  list.forEach((row: any) => {
    Object.keys(row).forEach((key: string) => {
      if (isEmpty(row[key])) {
        row[key] = '-';
      }
    });
  });
  return list;
}
