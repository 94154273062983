<template>
  <div class="navbar">
    <s-breadcrumb separator="/" class="breadcrumb-container">
      <s-breadcrumb-item
        v-for="item in navList"
        :key="item.name"
        @click.native="openLink(item.routeName)"
      >
        {{ $i18n(item.title) }}
      </s-breadcrumb-item>
    </s-breadcrumb>
    <div class="navbar-functions">
      <div
        v-if="$hasPermission('PC.Settings.JumpWFM')"
        class="system-out-button mr10"
        @click="handleSystemOutToWFM"
      >
        <svg-icon class="system-out-icon" icon-class="system-out" />
        WFM
      </div>
      <!-- <div class="system-out-button" @click="handleSystemOut">
        <svg-icon class="system-out-icon" icon-class="system-out" />
        Old System
      </div> -->
      <s-dropdown size="small">
        <span>{{ lang }}</span>
        <s-dropdown-menu slot="dropdown">
          <s-dropdown-item
            @click="handleLanguage(item)"
            v-for="item in languages"
            :key="item.value"
          >
            {{ item.label }}
          </s-dropdown-item>
        </s-dropdown-menu>
      </s-dropdown>
      <SIconChevronDown style="width: 16px" />

      <div class="avatar">
        <img :src="user.userInfo.header || defaultHeader" alt="user-avatar" />
      </div>

      <s-dropdown size="small">
        <span>{{ user.userInfo.display_name || user.userInfo.email }}</span>
        <template #dropdown>
          <s-dropdown-menu>
            <s-dropdown-item :disabled="true" @click="handleItemClick">
              {{ user.userInfo.email }}
            </s-dropdown-item>
            <s-dropdown-item @click.native="handleChangePassword">
              {{ $i18n('Change Password') }}
            </s-dropdown-item>
            <s-dropdown-item @click="handleLogoutClick">
              {{ $i18n('Logout') }}
            </s-dropdown-item>
          </s-dropdown-menu>
        </template>
      </s-dropdown>
      <SIconChevronDown style="width: 16px" />
      <ChangePasswordDialog
        v-if="changePasswordDialogVisible"
        :onConfirm="onChangePasswordConfirm"
        :onCancel="onChangePasswordCancel"
        :userDetail="user.userInfo"
        :visible="changePasswordDialogVisible"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { logOut } from '@/api/basic/user';
import { Route } from 'vue-router';
import { getCurrentLocale, getTranslateJSon, setLocale } from '@/i18n';
import { getSelectOptions } from '@/utils/safeGet';
import { tspLanguageMap } from '@/i18n/locales';
import { getWFMSystemUrl } from '@/utils/getEnv';
import defaultHeader from '@/assets/default-header.png';
import { checkLoginSwitch, getTobURL } from '@/utils/tobLogin';
import ChangePasswordDialog from './change-password-dialog.vue';
import { resetMyPwd } from '@/api/system-config/userlist';
import TranslateReport from '@/utils/translateReport';

@Component({
  components: {
    ChangePasswordDialog,
  },
})
export default class Navbar extends Vue {
  public defaultHeader = defaultHeader;

  languageIcon = false;
  navList: object[] = [];
  setLocale = setLocale;
  getTranslateJSon = getTranslateJSon;
  getCurrentLocale = getCurrentLocale;
  getSelectOptions = getSelectOptions;
  tspLanguageMap = tspLanguageMap;
  lang = 'English';
  languages: any = [];
  backendLanguages: any;
  mockLanguages: any = {
    en: 'English',
    id: 'Bahasa Indonesia',
    ['zh-CN']: '简体中文',
    ['zh-TW']: '繁体中文',
    vi: 'Vietnamese',
    th: 'Thai',
    ['tl-PH']: 'Tagalog',
    ['ms-MY']: 'Bahasa Malayu',
    ['sp-MX']: 'Spanish',
  };
  $route: any;
  $router: any;

  changePasswordDialogVisible = false;

  enableTobLogin = false;

  @State user!: any;
  @State route!: any;

  async onChangePasswordConfirm(formData: any) {
    await resetMyPwd({
      new_pwd: formData.new_pwd,
      old_pwd: formData.old_pwd,
    });
    this.changePasswordDialogVisible = false;
  }

  onChangePasswordCancel() {
    this.changePasswordDialogVisible = false;
  }

  @Watch('$route', { immediate: true })
  onAfterChanged(to: any) {
    this.initNavBar(to);
    this.initSystemTitle(to);
  }

  @Watch('route.to', { immediate: true })
  handleRouteChanged(to: any) {
    if (to?.path?.includes('hubLinehaulTrips')) {
      this.initNavBar(to);
    }
  }

  async created() {
    try {
      this.backendLanguages = await this.$request.get(
        '/api/v2/apps/system/user/get_lang_setting'
      );
      this.languages = this.backendLanguages.data.optional_lange_list.map(
        (item: any) => {
          return {
            label: item.desc,
            value: item.lange,
          };
        }
      );
      this.lang = this.languages.filter((item: any) => {
        return item.value === this.backendLanguages.data.default;
      })[0].label;
      this.initSystemTitle(this.$router.currentRoute);
    } catch (e) {
      console.log('error', e);
    }
  }

  // 跳转到 WFM
  handleSystemOutToWFM() {
    location.href = getWFMSystemUrl();
  }

  async handleChangePassword() {
    if (this.enableTobLogin) {
      window.location.href = await getTobURL('/user/password', [
        'default_region',
        'google_login_redirect',
      ]);
      return;
    }
    this.changePasswordDialogVisible = true;
  }

  async mounted() {
    this.enableTobLogin = await checkLoginSwitch();
  }
  // 登出
  async handleLogoutClick() {
    await logOut();
    this.$store.commit('clearUserInfo');
    this.$router.push({
      name: 'tobLogin',
      query: {
        redirect_url: encodeURI(location.href),
      },
    });
  }

  async handleLanguage(item: any) {
    try {
      await this.$request.post('/api/v2/apps/system/user/set_user_setting', {
        name: 'lang',
        value: item.value,
      });
      this.lang = item.label;
      this.setLocale(item.value);

      this.initSystemTitle(this.$router.currentRoute);
      this.getTranslateJSon(
        this.tspLanguageMap.get(getCurrentLocale()) || getCurrentLocale()
      );
      this.$nextTick(() => {
        TranslateReport.resetData();
      });
      if (
        this.$route.matched[0].path === '/dashboard' ||
        this.$route.matched[0].path === '/regionOverview'
      ) {
        const iframeDom: any = document.getElementById('dashboard-iframe');
        iframeDom.contentWindow.postMessage({ lang: item.value });
      }
    } catch (e) {
      console.log('error', e);
    }
  }

  handleItemClick(e: Event) {
    console.log(e);
  }

  openLink(name: string) {
    if (name === this.$route.name) {
      return;
    }
    this.$router.push({ name }).catch((e: any) => {
      //有些空页面继续重定向回来会报错，属于正常业务逻辑
      console.log(e);
    });
  }

  initNavBar(to: any) {
    this.navList = [];
    if (to.meta && to.meta.navList) {
      this.navList = [
        ...to.meta.navList,
        {
          routeName: to.name,
          title: to.meta.title,
        },
      ];
    } else {
      if (to.matched && to.matched.length > 0) {
        to.matched.forEach((item: any) => {
          this.navList.push({
            routeName: item.name,
            title: item.meta.title,
          });
        });
      }
    }
    this.navList = this.formatNavList(this.navList, to);
  }

  // 用于生成wms网页title
  initSystemTitle(to: any) {
    let systemTitle: any = [];
    const isMenuHideRouteByName = (name: string) => {
      const routes = this.$router.getRoutes();
      const route = routes.find((route: any) => route.name === name);
      return route?.meta?.menuHide && !route?.name?.startsWith?.('PC.');
    };
    // 去掉不显示在菜单栏的路由，保留tab的路由
    const _popMenuHideTitle = () => {
      if (
        systemTitle.length > 2 &&
        isMenuHideRouteByName(
          (systemTitle[systemTitle.length - 1] as any).routeName
        )
      ) {
        systemTitle.pop();
        _popMenuHideTitle();
      }
    };
    if (to.meta && to.meta.navList) {
      // route配置了navList的情况，取navList和当前route的title组合后处理
      systemTitle = [
        ...to.meta.navList,
        {
          routeName: to.name,
          title: to.meta.title,
        },
      ];

      _popMenuHideTitle();
    } else {
      if (to.matched && to.matched.length > 0) {
        // route没有配置navList的情况，取to.matched后处理
        to.matched.forEach((item: any, index: any) => {
          systemTitle.push({
            routeName: item.name,
            title: item.meta.title,
          });
        });
        _popMenuHideTitle();
      }
    }
    // 取最后两项，翻译后，拼接WMS字符串
    systemTitle = systemTitle.splice(-2);
    const title = [];
    systemTitle.forEach(
      (item: any) => item.title && title.push(this.$i18n(item.title))
    );
    title.push(this.$i18n('WMS'));
    document.title = title.join('-');
  }

  /**
   *  支持 ${key} 查找 to.params[key] 的方式替换 title 的 ${key} 部分
   *  例: '${key} ${key2} title' => `${to.params[key]} ${to.params[key2]} title`
   */
  tranParamTitle(title: string, to: Route) {
    let newTitle = title;
    const matchReg = /\$\{(\w+)\}/g;
    const matchArr = title.matchAll(matchReg);
    if (to.params) {
      for (const [fullMatchKey, key] of matchArr) {
        // 默认取 route.param 中匹配的参数，没有匹配替换为 ''
        const paramValue = to.params[key] || '';
        newTitle = newTitle.replace(fullMatchKey, paramValue);
      }
    }
    return newTitle;
  }

  // 转换 navlist title 字段
  formatNavList(navList: any, to: Route) {
    return this.navList.map(({ title = '', ...res }: any) => ({
      title: this.tranParamTitle(title, to),
      ...res,
    }));
  }
}
</script>

<style scoped lang="scss">
.navbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding-right: 24px;
  height: 56px;
  background: #ffffff;
}

.breadcrumb-container {
  max-width: 560px;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;

  ::v-deep .ssc-breadcrumb-item-link {
    white-space: nowrap;
    cursor: pointer;
  }
}

.navbar-functions {
  display: flex;
  align-items: center;
}

.avatar {
  overflow: hidden;
  margin-right: 8px;
  margin-left: 20px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.avatar img {
  width: 32px;
  height: 32px;
}

.system-out-button {
  display: flex;
  align-items: center;
  margin: 6px 16px 6px 0;
  border-radius: 22px;
  padding-right: 16px;
  height: 44px;
  font-size: 12px;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: #f8f9fd;
  }
}

.system-out-icon {
  margin: 0 4px 0 8px;
  width: 16px !important;
  height: 16px !important;
}

.mr10 {
  margin-right: 10px;
}
</style>
