import Layout from '../../views/layout/index.vue';
const dashboard = {
  path: '/dashboard',
  name: 'dashboard',
  meta: {
    title: 'Dashboard',
    icon: 'Dashboard',
  },
  component: () => import('@/views/layout/index.vue'),
  redirect: '/dashboard/tasktracking',
  children: [
    {
      path: '/dashboard/overview',
      name: 'dashboard.whsoverview',
      meta: {
        title: 'Warehouse Overview',
      },
      component: () => import('../../views/dashboard/overview/whs.vue'),
    },
    {
      path: '/dashboard/inbound',
      name: 'dashboard.inbound',
      meta: {
        title: 'Inbound',
      },
      component: () => import('../../views/dashboard/index.vue'),
    },
    {
      path: '/dashboard/outbound',
      name: 'dashboard.outbound',
      meta: {
        title: 'Outbound',
      },
      component: () => import('../../views/dashboard/index.vue'),
    },
    // {
    //   path: '/dashboard/tasktracking',
    //   name: 'dashboard.tasktracking',
    //   meta: {
    //     title: 'Task Tracking',
    //     dwellKey: 'dashboard_task_tracking_inbound',
    //   },
    //   component: () =>
    //     import('../../views/dashboard/task-tracking/task-tracking.vue'),
    // },
    // {
    //   path: '/dashboard/tasktracking/outbound',
    //   name: 'dashboard.tasktracking.outbound',
    //   meta: {
    //     title: 'Task Tracking',
    //     menuHide: true,
    //     dwellKey: 'dashboard_task_tracking_outbound',
    //   },
    //   component: () =>
    //     import(
    //       '../../views/dashboard/task-tracking/task-tracking-outbound.vue'
    //     ),
    // },
    // {
    //   path: '/dashboard/productivityefficiency',
    //   name: 'dashboard.productivityefficiency',
    //   meta: {
    //     title: 'Productivity & Efficiency',
    //   },
    //   redirect: '/dashboard/productivityefficiency/inbound',
    //   component: () => import('@/views/layout/subPage.vue'),
    //   children: [
    //     {
    //       path: '/dashboard/productivityefficiency/inbound',
    //       name: 'PC.Dashboard.ProductivityEfficiency.Inbound',
    //       meta: {
    //         title: 'Productivity & Efficiency',
    //         menuHide: true,
    //         dwellKey: 'dashboard_productivity_efficiency_inbound',
    //         navList: [
    //           {
    //             routeName: 'dashboard',
    //             title: 'Dashboard',
    //           },
    //         ],
    //       },
    //       component: () =>
    //         import(
    //           '@/views/dashboard/productivity-efficiency/productivity-efficiency.vue'
    //         ),
    //     },
    //     {
    //       path: '/dashboard/productivityefficiency/outbound',
    //       name: 'PC.Dashboard.ProductivityEfficiency.Outbound',
    //       meta: {
    //         title: 'Productivity & Efficiency',
    //         menuHide: true,
    //         dwellKey: 'dashboard_productivity_efficiency_outbound',
    //         navList: [
    //           {
    //             routeName: 'dashboard',
    //             title: 'Dashboard',
    //           },
    //         ],
    //       },
    //       component: () =>
    //         import(
    //           '@/views/dashboard/productivity-efficiency/productivity-efficiency.vue'
    //         ),
    //     },
    //   ],
    // },
    {
      path: '/dashboard/inboundplanning',
      name: 'dashboard.inboundplanning',
      meta: {
        title: 'Inbound Planning',
        dwellKey: 'dashboard_inbound_planning',
      },
      component: () =>
        import('../../views/dashboard/inbound-planning/tabs.vue'),
    },
    {
      path: '/dashboard/labormanagement',
      name: 'dashboard.laborManagement',
      meta: {
        title: 'Staff Management',
      },
      component: () =>
        import('../../views/dashboard/labor-management/staff-allocation.vue'),
    },
    {
      path: '/dashboard/stafftracking',
      name: 'dashboard.staffTracking',
      meta: {
        menuHide: true,
        title: 'Staff Management',
      },
      component: () =>
        import('../../views/dashboard/labor-management/staff-tracking.vue'),
    },
    {
      path: '/dashboard/attendancesummary',
      name: 'dashboard.attendanceSummary',
      meta: {
        menuHide: true,
        title: 'Staff Management',
        dwellKey: 'dashboard_Staff_Management_Attendance_Summary',
      },
      component: () =>
        import(
          '../../views/dashboard/labor-management/attendance-summary/index.vue'
        ),
    },
    {
      path: '/dashboard/shiftoverview',
      name: 'dashboard.shiftOverview',
      meta: {
        menuHide: true,
        title: 'Staff Management',
        dwellKey: 'dashboard_Staff_Management_Shift_Overview',
      },
      component: () =>
        import(
          '../../views/dashboard/labor-management/shift-overview/index.vue'
        ),
    },
    {
      path: '/dashboard/locationcyclecount',
      name: 'dashboard.locationCycleCount',
      meta: {
        title: 'Daily Cycle Count',
        dwellKey: 'dashboard_daily_cycle',
      },
      component: () =>
        import('../../views/dashboard/cycle-count/cycle-count-dashboard.vue'),
    },
    {
      path: '/dashboard/fullstockcount',
      name: 'dashboard.fullStockCount',
      meta: {
        title: 'Full Stock Count',
        dwellKey: 'dashboard_full_stock_count',
      },
      component: () =>
        import('../../views/dashboard/full-stock-count/fsc-dashboard.vue'),
    },
    // 配置页要求放在最后面
    {
      path: '/dashboard/configuration',
      name: 'dashboard.configuration',
      meta: {
        title: 'Configuration',
      },
      component: () => import('../../views/dashboard/index.vue'),
    },
  ],
};

export default dashboard;
