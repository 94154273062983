import { ReactRoute } from '@/types/route';
import demoRoutes from './react-demo';
import ruleCenterRoutes from './rule-center';
//建议所有页面的路由增加dmi 前缀防止和vue的路由产生冲突
const subPageRoutes: ReactRoute = {
  path: '/dmi',
  name: 'subPage',
  meta: {
    title: 'rulecenter',
    menuHide: true,
  },
  //  children: [demoRoutes, ruleCenterRoutes],
};
const routes: ReactRoute[] = [subPageRoutes];

export default routes;
