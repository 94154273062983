import { DataCollectorInstance, TransactionCollector } from '@scfe-common/core';
import { handleResponseData } from '../../utils';
import { ActionType, RequestState } from '@scfe-common/chain-tracker';
import { SLA_CHAINS_TYPE } from '@/utils/chain-tracker/constants';

const TRACK_NAME = 'custom/ReportRMSQC';

interface ExtraData {
  sku_quality: number;
  qc_images_length: number;
}

let extraData: Partial<ExtraData> = {};

export function setExtraData(param: Partial<ExtraData>): void {
  extraData = { ...param };
}
export default [
  {
    name: 'ReportRMSQCChain',
    status: {
      isStartInput: false,
      isUploadWithCamera: false,
    },
    collector: {} as TransactionCollector,
    getPointList: function () {
      return [
        {
          type: ActionType.CLICK,
          rule: {
            selectors: '.rms-qc-input',
          },
          handler: () => {
            if (!this.status.isStartInput) {
              this.status.isStartInput = true;
              this.collector =
                DataCollectorInstance.createTransactionCollector();
              this.collector.startTrack({
                name: TRACK_NAME,
              });
            }
          },
        },
        {
          type: ActionType.CLICK,
          rule: {
            selectors: '.rms-qc-camera',
          },
          handler: () => {
            this.status.isUploadWithCamera = true;
          },
        },
        {
          type: ActionType.REQUEST,
          rule: {
            // /api/apps/process/returninbound/riqc/save_parcel_qc
            // api/apps/process/returninbound/riqc/save_qc_batch
            url: /^\/api\/apps\/process\/returninbound\/riqc\/(save_parcel_qc|save_qc_batch)(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, sendDataFn: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode === 0) {
              const results = this.collector.endTrackByName({
                name: TRACK_NAME,
                isSuccess: true,
                endAll: true,
                extraData: {
                  ...extraData,
                  is_upload_with_camera: this.status.isUploadWithCamera ? 1 : 0,
                },
              });
              results.forEach((result) => {
                sendDataFn({
                  pointId: result.name,
                  customData: { ...result, type: SLA_CHAINS_TYPE },
                });
              });
              DataCollectorInstance.removeTransactionCollector({
                traceId: this.collector.traceId,
              });
              this.status.isStartInput = false;
              this.status.isUploadWithCamera = false;
              extraData = {};
            }
          },
        },
      ];
    },
  },
];
