/**
 * Get the corresponding cookie
 * @param name the cookie name
 * @return the cookie
 */
function getCookie(name: string): string | null {
  const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
  const arr = document.cookie.match(reg);
  if (arr) {
    return decodeURIComponent(arr[2]);
  } else {
    return null;
  }
}

/**
 * delete the cookie storaged in the browser
 * @param name the cookie name
 */
function delCookie(name: string): void {
  const exp: Date = new Date();
  exp.setTime(exp.getTime() - 1 * 24 * 60 * 60 * 1000);
  const cval = getCookie(name);
  document.cookie =
    name + '=' + cval + '; expires=' + exp.toUTCString() + '; path=/';
}

/**
 * store the cookie value in the browser
 * @param cname the cookie name
 * @param cvalue the cookie value
 * @param exdays the expiration date of the cookie
 */
function setCookie(cname: string, cvalue: string, exdays: number): void {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + escape(cvalue) + '; ' + expires + ';path=/';
}

export { getCookie, delCookie, setCookie };
