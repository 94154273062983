const isDev = process.env.NODE_ENV !== 'production';

if (isDev) {
  const isAdminModule = __IS_ADMIN_MODULE__;
  window.MODULE_ENTRY_BASE_URL = {
    admin: __ADMIN_MODULE__ || (isAdminModule ? '/v2' : '/module-federation'),
    inwork: [__INWORK_MODULE__, '/v2/module-federation/inwork'],
  };
  // workaround, temporarily fix HMR issue, only in dev env
  // similar issue https://github.com/module-federation/module-federation-examples/issues/358%EF%BC%89
  const currentUpdateFn = (window as any)['webpackHotUpdate_wms_admin'];
  const hmrUpdateFnList = currentUpdateFn ? [currentUpdateFn] : [];
  const hmrUpdateFn = function () {
    hmrUpdateFnList.forEach((item, ...args) => {
      try {
        // eslint-disable-next-line prefer-spread
        item.apply(null, args);
      } catch (e: any) {
        const msg = e.message || '';
        if (!/Cannot (read|set) properties of undefined/.test(msg)) {
          throw e;
        }
      }
    });
  };
  if (!(window as any)['webpackHotUpdate_wms_admin']) {
    Object.defineProperty(window, 'webpackHotUpdate_wms_admin', {
      set(value) {
        hmrUpdateFnList.push(value);
      },
      get() {
        return hmrUpdateFn;
      },
    });
  }
} else {
  window.MODULE_ENTRY_BASE_URL = {
    admin: '/v2',
    inwork: '/v2/inwork',
  };
}

const ENTRY_CACHE: any = {};
window.LOAD_REMOTE_ENTRY = function (
  moduleName: string,
  appId: string,
  filename = 'remoteEntry.js'
) {
  if ((window as any)[moduleName]) {
    // have loaded elsewhere
    return (window as any)[moduleName];
  }
  if (!ENTRY_CACHE[moduleName]) {
    ENTRY_CACHE[moduleName] = new Promise((resolve, reject) => {
      const urls = [window.MODULE_ENTRY_BASE_URL[appId]].flat(Infinity);
      const load = () => {
        const url = urls.shift();
        if (!url) {
          load();
          return;
        }
        const script = document.createElement('script');
        const complete = () => {
          script.onerror = script.onload = null;
          script.parentNode && script.parentNode.removeChild(script);
        };
        script.src = `${url}/${filename}`;
        console.log(url, `${url}/${filename}`);
        script.onload = () => {
          complete();
          if (!(window as any)[moduleName] && urls.length) {
            load();
            return;
          }
          // the injected script has loaded and is available on window
          // we can now resolve this Promise
          let inited = false;
          const proxy = {
            get: (request: any) => (window as any)[moduleName].get(request),
            init: (arg: any) => {
              if (inited) return;
              try {
                (window as any)[moduleName].init(arg);
                inited = true;
              } catch (e: any) {
                reject(new Error('remote container init error: ' + e.message));
                console.error('remote container init error: ' + e.message);
              }
            },
          };
          resolve(proxy);
        };
        script.onerror = () => {
          complete();
          reject(new Error('remote container load error: ' + script.src));
          console.error('remote container load error: ' + script.src);
        };
        document.head.appendChild(script);
      };
      load();
    });
  }
  return ENTRY_CACHE[moduleName];
};
