import Layout from '../../views/layout/index.vue';

const rtsOutbound = {
  path: '/consumables',
  name: 'consumables',
  meta: {
    title: 'Consumables Management',
    icon: 'RTS Outbound',
  },
  redirect: '/consumables/list',
  component: () => import('@/views/layout/index.vue'),
  children: [
    {
      path: 'list',
      name: 'consumables.list',
      meta: {
        title: 'Consumables List',
      },
      component: () =>
        import('@/views/consumables-management/consumables/index.vue'),
    },
    {
      path: 'group',
      name: 'consumables.group',
      meta: {
        title: 'Consumables Group',
        menuHide: true,
      },
      component: () =>
        import('@/views/consumables-management/consumables/index.vue'),
    },
    {
      path: 'list/create',
      name: 'consumables.list.create',
      meta: {
        title: 'Create',
        menuHide: true,
        navList: [
          {
            routeName: 'consumables',
            title: 'Consumables Management',
          },
          {
            routeName: 'consumables.list',
            title: 'Consumables List',
          },
        ],
      },
      component: () =>
        import(
          '@/views/consumables-management/consumables/consumables-list/create.vue'
        ),
    },
    {
      path: 'list/edit/:id',
      name: 'consumables.list.edit',
      meta: {
        title: 'Edit',
        menuHide: true,
        navList: [
          {
            routeName: 'consumables',
            title: 'Consumables Management',
          },
          {
            routeName: 'consumables.list',
            title: 'Consumables List',
          },
        ],
      },
      component: () =>
        import(
          '@/views/consumables-management/consumables/consumables-list/create.vue'
        ),
    },
    {
      path: 'inbound',
      name: 'consumables.inbound',
      meta: {
        title: 'Inbound',
      },
      component: () =>
        import('../../views/consumables-management/inbound/index.vue'),
    },
    {
      path: 'task',
      name: 'consumables.task',
      meta: {
        title: 'Task',
      },
      redirect: '/consumables/task/stockTake',
      component: () => import('../../views/layout/subPage.vue'),
    },
    {
      path: 'task/stockTake',
      name: 'PC.ConsumablesManagement.Task.StockTaskTask.View',
      meta: {
        title: 'Stock Take',
        menuHide: true,
      },
      component: () =>
        import('../../views/consumables-management/task/index.vue'),
    },
    {
      path: 'task/stocktake/detail/:task_id',
      name: 'consumables.stockTakeTask.detail',
      meta: {
        title: 'Stock Take Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'consumables',
            title: 'Consumables Management',
          },
          {
            routeName: 'PC.ConsumablesManagement.Task.StockTaskTask.View',
            title: 'Stock Take Task',
          },
        ],
      },
      component: () =>
        import('../../views/consumables-management/task/stock-take/detail.vue'),
    },
    {
      path: 'task/stocktake/create',
      name: 'consumables.stockTakeTask.create',
      meta: {
        title: 'Create',
        menuHide: true,
        navList: [
          {
            routeName: 'consumables',
            title: 'Consumables Management',
          },
          {
            routeName: 'PC.ConsumablesManagement.Task.StockTaskTask.View',
            title: 'Stock Take Task',
          },
        ],
      },
      component: () =>
        import('../../views/consumables-management/task/stock-take/create.vue'),
    },
    {
      path: 'task/stocktake/edit/:task_id',
      name: 'consumables.stockTakeTask.edit',
      meta: {
        title: 'Edit',
        menuHide: true,
        navList: [
          {
            routeName: 'consumables',
            title: 'Consumables Management',
          },
          {
            routeName: 'consumables.task',
            title: 'Task',
          },
          {
            routeName: 'PC.ConsumablesManagement.Task.StockTaskTask.View',
            title: 'Stock Take Task',
          },
        ],
      },
      component: () =>
        import('../../views/consumables-management/task/stock-take/create.vue'),
    },
    {
      path: 'stockTake/:task_id?',
      name: 'consumables.stockTake',
      meta: {
        title: 'Stock Take',
      },
      component: () =>
        import('../../views/consumables-management/stockTake/index.vue'),
    },
    {
      path: 'task/manualremoval',
      name: 'PC.ConsumablesManagement.Task.ManualRemovalTask.View',
      meta: {
        title: 'Manual Removal',
        menuHide: true,
      },
      component: () =>
        import('../../views/consumables-management/task/index.vue'),
    },
    {
      path: 'task/manualremoval/detail',
      name: 'consumables.manualRemoval.detail',
      meta: {
        title: 'Task Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'consumables',
            title: 'Consumables Management',
          },
          {
            routeName: 'PC.ConsumablesManagement.Task.ManualRemovalTask.View',
            title: 'Manual Removal Task',
          },
        ],
      },
      component: () =>
        import(
          '../../views/consumables-management/task/manual-removal/detail.vue'
        ),
    },
    {
      path: 'task/manualremoval/operation',
      name: 'consumables.manualRemoval',
      meta: {
        title: 'Manual Removal',
        navList: [
          {
            routeName: 'consumables',
            title: 'Consumables Management',
          },
        ],
      },
      component: () =>
        import(
          '../../views/consumables-management/task/manual-removal/operation.vue'
        ),
    },
  ],
};

export default rtsOutbound;
