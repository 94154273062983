// components 操作相关的方法
import { config } from './config';

const componentsCollection: { [key: string]: any }[] = [];

const update = () => {
  let len = componentsCollection.length;
  while (len--) {
    const vm = componentsCollection[len];
    if (vm) {
      vm[config.componentUpdateMethodName]();
      const computedWatchers = vm._computedWatchers;
      if (computedWatchers) {
        Object.keys(computedWatchers).forEach((computed) => {
          computedWatchers[computed].update();
        });
      }
    }
  }
};

function remove(arr: any[], item: any) {
  if (arr.length) {
    const index = arr.indexOf(item);
    if (index > -1) {
      return arr.splice(index, 1);
    }
  }
}

const addComponentInstance = (component: { [key: string]: any }) =>
  componentsCollection.push(component);
const rmComponentInstance = (component: { [key: string]: any }) =>
  remove(componentsCollection, component);

export {
  componentsCollection,
  update,
  addComponentInstance,
  rmComponentInstance,
};
