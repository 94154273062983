import storage from './LFUStorage/storage';
import request from '@/utils/request';
import { IS_CN } from '@/utils/tools';

async function skuInputMappingFunction(val: any) {
  const regExp = /^\$|^#/i;

  if (!regExp.test(val) || IS_CN) {
    return val;
  }

  const getCacheData = storage.getStorage(val);

  if (getCacheData) {
    return getCacheData;
  }

  const params = {
    new_sku_id: val,
  };

  const data = await request.get(
    '/api/v2/apps/basic/sku/get_sku_by_mapping_code',
    {
      params: params,
    }
  );
  if (!data.retcode) {
    if (data.data.sku_id !== undefined) {
      storage.setStorage(val, data.data.sku_id);
      return data.data.sku_id;
    } else {
      return data.data.new_sku_id;
    }
  }

  return val;
}

export default skuInputMappingFunction;
