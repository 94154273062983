import Vue from 'vue';
import Vuex from 'vuex';
import permission from './modules/permission';
import enums from './modules/enums';
import user from './modules/user';
import route from './modules/route';
import threePL from './modules/threepl';
import notification from './modules/notification';
import urlMapJumpToOld from './modules/urlMapJumpToOld';
import warehouseSwitchTime from './modules/warehouseSwitchTime';
import filterOptions from './modules/filter-options';
import warehouseDetail from './modules/warehouse';
import outboundFavoutite from './modules/outboundFavoutite';
import system from './modules/system';
import cycleCount from './modules/cycle-count';
import tabMenu from './modules/tabMenu';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    permission,
    enums,
    user,
    threePL,
    route,
    notification,
    urlMapJumpToOld,
    warehouseSwitchTime,
    filterOptions,
    warehouseDetail,
    outboundFavoutite,
    system,
    cycleCount,
    tabMenu,
  },
});
export default store;
