<template>
  <div
    class="input-range"
    @mouseenter="isHovering = true"
    @mouseleave="isHovering = false"
  >
    <s-input
      class="input-range-input first"
      :placeholder="placeholder[0]"
      @input="handleFirstInputChange"
      v-model.trim="range_value[0]"
      :disabled="disabled"
    />
    <span :class="splitClass">-</span>
    <s-input
      class="input-range-input second"
      :placeholder="placeholder[1]"
      @input="handleSecondInputChange"
      v-model.trim="range_value[1]"
      :disabled="disabled"
    />
    <!-- 为了模拟 form clear 动作 -->
    <div v-if="shouldShowClear" class="ssc-input fake-ssc-input">
      <div @click="handleClear" class="ssc-input-control ssc-input-clear">
        <svg
          viewBox="0 0 16 16"
          data-icon="delete"
          fill="currentColor"
          width="24"
          class="ssc-input-delete-icon"
        >
          <path
            fill-rule="evenodd"
            d="M8 14A6 6 0 108 2a6 6 0 000 12zm2.67-3.33a.47.47 0 000-.67l-2-2 2-2a.47.47 0 10-.67-.67l-2 2-2-2a.47.47 0 10-.67.67l2 2-2 2a.47.47 0 10.67.67l2-2 2 2c.18.18.48.18.67 0z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Model, Prop } from 'vue-property-decorator';

@Component
export default class InputRange extends Vue {
  @Model('change') range_value: Array<any>;
  @Prop() placeholder: Array<string>;
  @Prop() clearable: boolean;
  @Prop() disabled: boolean;

  isHovering = false;

  get shouldShowClear() {
    const { clearable, range_value, isHovering } = this;
    return isHovering && clearable && (range_value[0] || range_value[1]);
  }

  get splitClass() {
    return `to-split ${this.disabled ? ' to-split-disabled' : ''}`;
  }

  handleFirstInputChange(value: string) {
    const { range_value } = this;
    range_value[0] = value || undefined;
    this.$emit('change', range_value);
  }

  handleSecondInputChange(value: string) {
    const { range_value } = this;
    range_value[1] = value || undefined;
    this.$emit('change', range_value);
  }

  handleClear() {
    this.$emit('change', []);
  }
}
</script>

<style scoped lang="scss">
.input-range {
  width: 100%;
  display: flex;
  .to-split {
    display: inline-block;
    width: 20px;
    padding: 4px 8px 0;
    border-top: 1px solid #ecf0f4;
    border-bottom: 1px solid #ecf0f4;
  }
  .to-split-disabled {
    cursor: not-allowed;
    background: #f5f6f9;
    -webkit-text-fill-color: #959ba4;
    color: #959ba4;
  }
  .input-range-input.ssc-input {
    width: 150px;
    ::v-deep input {
      text-align: center;
    }
  }
  .first {
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .second {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .fake-ssc-input {
    border: none;
    flex-grow: inherit;
    position: absolute;
    right: 1px;
    top: 8px;
    align-items: center;
    ::v-deep .ssc-input-control {
      visibility: visible;
    }
  }
}
</style>
