/**
 * @file the bridge between vue and react
 * @author Siqi Li
 */
import type { Component, default as Vue } from 'vue';
import type VueRouterType from 'vue-router/types';
import { Store } from 'vuex';
import { AssetsRouteConfig, ReactRoute } from '@/types/route';
// import { default as vueRouter } from '@/router';
// import store from '@/store';
// import vueInstance from '@/main';
import reactContainerFactory from '@/components/react-container/reactContainerFactory';
import ComponentLayout from '@/views/layout/index.vue';
import ComponnetSubPage from '@/views/layout/subPage.vue';

interface VueStuff {
  router: VueRouterType;
  store: Store<any>;
  // vueInstance: Vue;
}

// export const getVueStuff = (): VueStuff => {
//   return {
//     router: vueRouter,
//     store,
//     // vueInstance: vueInstance as Vue,
//   };
// };

export const convertReactRoutesToVue = (
  reactRoutes: ReactRoute[],
  level = 1
): AssetsRouteConfig[] => {
  const vueRoutes = reactRoutes.map((item) => {
    const { component, props = {}, children, ...rest } = item;
    let finalComponent: Component;

    const hasComponent = !!component; // 如果是Menu的父节点，则没有component，值为false
    if (hasComponent) {
      finalComponent = reactContainerFactory({ name: item.name });
    } else if (level === 1) {
      finalComponent = ComponentLayout;
    } else {
      finalComponent = ComponnetSubPage;
    }

    level++;
    return {
      ...rest,
      component: finalComponent,
      children: children ? convertReactRoutesToVue(children, level) : undefined,
      props: {
        ...props,
        component: hasComponent ? component : undefined,
      },
    };
  });
  return vueRoutes;
};
