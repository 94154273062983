import { Component, Vue, Model, Watch } from 'vue-property-decorator';
import { CreateElement, VNode } from 'vue/types/umd';
import {
  getUTCSecondsBasedWhs,
  getWhTimestampByUTCSeconds,
} from '@/utils/date';
import { getIsDate } from '@/utils/tools';

type DatePickerType = (Date | number)[] | number | null;

@Component
export default class ZonetimeDataPicker extends Vue {
  @Model('update')
  whsDate: DatePickerType;

  @Watch('whsDate', { immediate: true })
  onWhsDateChange() {
    if (this.whsDate) {
      if (Array.isArray(this.whsDate)) {
        this.date = this.whsDate.map((item: Date | number) =>
          getIsDate(item)
            ? new Date(getWhTimestampByUTCSeconds(+item) as number)
            : getWhTimestampByUTCSeconds(+item)
        );
      } else {
        this.date = getIsDate(this.whsDate)
          ? +new Date(getWhTimestampByUTCSeconds(+this.whsDate) as number)
          : getWhTimestampByUTCSeconds(+this.whsDate);
      }
    } else {
      this.date = this.whsDate;
    }
  }

  date: DatePickerType = null;

  handleChange(value: DatePickerType) {
    if (value) {
      // 转换回需要回传给后端的时间戳
      if (Array.isArray(value)) {
        const formatWhsDate = value.map((item: Date | number) =>
          getIsDate(item)
            ? new Date((getUTCSecondsBasedWhs(+item) as number) * 1000)
            : (getUTCSecondsBasedWhs(+item) as number) * 1000
        );
        this.$emit('update', formatWhsDate);
      } else {
        const formatWhsDate = getIsDate(value)
          ? +new Date((getUTCSecondsBasedWhs(+value) as number) * 1000)
          : (getUTCSecondsBasedWhs(+value) as number) * 1000;
        this.$emit('update', formatWhsDate);
      }
    } else {
      this.$emit('update', value);
    }
    // 处理托管 change 事件后的逻辑
    const { change } = this.$listeners;
    if (!change) {
      return;
    }
    if (typeof change === 'function') {
      change && (change as Function)(value);
    } else {
      change.forEach((item) => item(value));
    }
  }

  handleInput(value: DatePickerType) {
    if (value) {
      // 转换回需要回传给后端的时间戳
      if (Array.isArray(value)) {
        const formatWhsDate = value.map((item: Date | number) =>
          getIsDate(item)
            ? new Date((getUTCSecondsBasedWhs(+item) as number) * 1000)
            : (getUTCSecondsBasedWhs(+item) as number) * 1000
        );
        this.$emit('update', formatWhsDate);
      } else {
        const formatWhsDate = getIsDate(value)
          ? +new Date((getUTCSecondsBasedWhs(+value) as number) * 1000)
          : (getUTCSecondsBasedWhs(+value) as number) * 1000;
        this.$emit('update', formatWhsDate);
      }
    } else {
      this.$emit('update', value);
    }
    // 处理托管 input 事件后的逻辑
    const { input } = this.$listeners;
    if (!input) {
      return;
    }
    if (typeof input === 'function') {
      input && (input as Function)(value);
    } else {
      input.forEach((item) => item(value));
    }
  }

  render(h: CreateElement): VNode {
    // return <s-date-picker />
    const listeners = {
      ...this.$listeners,
      change: this.handleChange,
      input: this.handleInput,
    };
    return h('s-date-picker', {
      props: {
        ...this.$props,
        value: this.date,
      },
      attrs: this.$attrs,
      on: listeners,
      scopedSlots: this.$scopedSlots,
    });
  }
}
