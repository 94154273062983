import { DataCollectorInstance, TransactionCollector } from '@scfe-common/core';
import { handleResponseData } from '../../utils';
import { ActionType, RequestState } from '@scfe-common/chain-tracker';
import { SLA_CHAINS_TYPE } from '@/utils/chain-tracker/constants';

export default [
  {
    name: 'SLASaleOutboundCheckingChain',
    status: {
      clickRecheckingBtn: 0,
      errorCount: 0,
    },
    collector: {} as TransactionCollector,
    ALLName: 'custom_SaleOutboundChecking',
    BindStationName: 'custom_SaleOutboundChecking_bind_station',
    GetCheckingTaskDetailName:
      'custom_SaleOutboundChecking_search_checking_task_info',
    ClickRecheckingCountName:
      'custom_SaleOutboundChecking_ClickRecheckingCount',
    ScanSKU: 'custom_SaleOutboundChecking_scan_SKU',
    InputWeightAndPackage: 'custom_SaleOutboundChecking_input_weigh_an_package',
    ErrorCount: 'custom_SaleOutboundChecking_error_count',

    getPointList: function () {
      return [
        // Checking任务开始：点击station ID
        {
          type: ActionType.CLICK,
          rule: {
            selectors: '#checking-scanStationId',
          },
          handler: () => {
            this.collector = DataCollectorInstance.createTransactionCollector();
            this.collector.startTrack({
              name: this.ALLName,
            });
            this.collector.startTrack({
              name: this.BindStationName,
            });
            this.status.errorCount = 0;
          },
        },
        //绑定station ID结束
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/checking\/bind_station(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, sendDataFn: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode === 0) {
              const [result] = this.collector.endTrackByName({
                name: this.BindStationName,
                isSuccess: true,
              });
              sendDataFn({
                pointId: result.name,
                customData: { ...result, type: 'experienceTime' },
              });
            }
          },
        },
        // Checking开始：扫描 checking id
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/checking\/search_checking_task_info(\?.*)?$/,
            state: RequestState.Before,
          },
          handler: () => {
            this.collector.startTrack({
              name: this.GetCheckingTaskDetailName,
            });
          },
        },
        // 可以开始做任务
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/checking\/search_sku_checking_and_checked_list(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, sendDataFn: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode === 0) {
              const [result] = this.collector.endTrackByName({
                name: this.GetCheckingTaskDetailName,
                isSuccess: true,
              });
              sendDataFn({
                pointId: result.name,
                customData: { ...result, type: 'experienceTime' },
              });
            }
          },
        },
        // 扫描SKU开始
        {
          type: ActionType.CLICK,
          rule: {
            selectors: '#skuScanning',
          },
          handler: () => {
            this.collector.startTrack({
              name: this.ScanSKU,
            });
          },
        },
        // 扫描SKU结束
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/checking\/search_checking_possible_sku_list(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, sendDataFn: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode === 0) {
              const [result] = this.collector.endTrackByName({
                name: this.ScanSKU,
                isSuccess: true,
              });
              sendDataFn({
                pointId: result.name,
                customData: { ...result, type: 'experienceTime' },
              });
              // 输入重量和包材信息
              this.collector.startTrack({
                name: this.InputWeightAndPackage,
              });
            }
          },
        },
        // 输入重量信息结束
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/checking\/add_weight(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, sendDataFn: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode === 0) {
              const [result] = this.collector.endTrackByName({
                name: this.InputWeightAndPackage,
                isSuccess: true,
              });
              sendDataFn({
                pointId: result.name,
                customData: { ...result, type: 'experienceTime' },
              });
            }
          },
        },
        // 点击了 Rechecking
        {
          type: ActionType.CLICK,
          rule: {
            selectors: '.reChecking',
          },
          handle: () => {
            if (!this.status.clickRecheckingBtn) {
              this.collector.startTrack({
                name: this.ClickRecheckingCountName,
              });
            }
            this.status.clickRecheckingBtn += 1;
          },
        },
        // 监听所有的请求报错
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/checking\//,
            state: RequestState.After,
          },
          handler: (trackData: any, _: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode !== 0) {
              this.status.errorCount += 1;
            }
          },
        },
        // Checking结束
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/checking\/complete_order(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, sendDataFn: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode === 0) {
              const results = this.collector.endTrackByName({
                name: this.ClickRecheckingCountName,
                extraData: {
                  clickRecheckingBtn_n: this.status.clickRecheckingBtn,
                },
                isSuccess: true,
                endAll: true,
              });
              results.forEach((result) => {
                sendDataFn({
                  pointId: result.name,
                  customData: { ...result, type: 'experienceTime' },
                });
              });

              // 全流程耗时和错误数量上报
              const [allResult] = this.collector.endTrackByName({
                name: this.ALLName,
                isSuccess: true,
                endAll: true,
              });
              sendDataFn({
                pointId: allResult.name,
                customData: { ...allResult, type: 'experienceTime' },
              });
              sendDataFn({
                pointId: this.ErrorCount,
                customData: {
                  errorCount_n: this.status.errorCount,
                  type: 'experienceCount',
                },
              });
              DataCollectorInstance.removeTransactionCollector({
                traceId: this.collector.traceId,
              });
            }
          },
        },
      ];
    },
  },
];
