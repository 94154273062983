import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { getNotificationSoundListV2 } from '../../api/wms-config/notification-sound';

@Module
class Notification extends VuexModule {
  notificationSoundConfig = {};
  @Mutation
  setNotificationSoundConfig(data = {}): void {
    this.notificationSoundConfig = data;
  }
  @Action({ commit: 'setNotificationSoundConfig' })
  async getNotificationSoundConfigList() {
    const resp = await getNotificationSoundListV2();
    return resp.data.list;
  }
}
export default Notification;
