import { DataCollectorInstance, TransactionCollector } from '@scfe-common/core';
import { handleResponseData } from '../../utils';
import { ActionType, RequestState } from '@scfe-common/chain-tracker';
import { SLA_CHAINS_TYPE } from '@/utils/chain-tracker/constants';

export default [
  {
    name: 'SLASaleOutboundSortingChain',
    status: {
      useResumeConfirm: 0,
      isUnSortedListReady: 0,
      isSortedListReady: 0,
      getGridDetailTimes: 0,
      errorCount: 0,
    },
    collector: {} as TransactionCollector,
    ReadyForHandleSortingTaskName:
      'custom_SaleOutboundSorting_readyForHandleSortingTask',
    UseGridDetailTimesName: 'custom_SaleOutboundSorting_useGridDetailTimes',
    ErrorCount: 'custom_SaleOutboundSorting_error_count',
    getPointList: function () {
      return [
        // Sorting开始：输入 Station id
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/sorting\/scan_sorting_station_id(\?.*)?$/,
            state: RequestState.Before,
          },
          handler: () => {
            this.collector = DataCollectorInstance.createTransactionCollector();
            this.collector.startTrack({
              name: this.ReadyForHandleSortingTaskName,
            });
            this.collector.startTrack({
              name: this.UseGridDetailTimesName,
            });
          },
        },
        // 可以开始做 Sorting 任务 场景 1, sorted List 先 unsorted List 返回
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/sorting\/get_unsorted_list(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, sendDataFn: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode === 0) {
              this.status.isUnSortedListReady = 1;
            }
            if (response?.retcode === 0 && this.status.isSortedListReady) {
              const [result] = this.collector.endTrackByName({
                name: this.ReadyForHandleSortingTaskName,
                isSuccess: true,
              });
              sendDataFn({
                pointId: result.name,
                customData: { ...result, type: 'experienceTime' },
              });
            }
          },
        },
        // 可以开始做 Sorting 任务 场景 2, unsorted List 先 sorted List 返回
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/sorting\/get_sorted_list(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, sendDataFn: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode === 0) {
              this.status.isSortedListReady = 1;
            }
            if (response?.retcode === 0 && this.status.isUnSortedListReady) {
              const [result] = this.collector.endTrackByName({
                name: this.ReadyForHandleSortingTaskName,
                isSuccess: true,
              });
              sendDataFn({
                pointId: result.name,
                customData: { ...result, type: 'experienceTime' },
              });
            }
          },
        },
        // 查询了 Grid Detail
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/sorting\/get_grid_detail(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode === 0) {
              this.status.getGridDetailTimes += 1;
            }
          },
        },
        // 监听所有的请求报错
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/sorting\//,
            state: RequestState.After,
          },
          handler: (trackData: any, _: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode !== 0) {
              this.status.errorCount += 1;
            }
          },
        },
        // Sorting 结束
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/sorting\/complete_sorting_task(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, sendDataFn: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode === 0) {
              const results = this.collector.endTrackByName({
                name: this.UseGridDetailTimesName,
                isSuccess: true,
              });
              results.forEach((result) => {
                sendDataFn({
                  pointId: result.name,
                  customData: { ...result, type: 'experienceTime' },
                });
              });
              sendDataFn({
                pointId: this.ErrorCount,
                customData: {
                  getGridDetailTimes_n: this.status.getGridDetailTimes,
                  errorCount_n: this.status.errorCount,
                  type: 'experienceCount',
                },
              });
            }
            DataCollectorInstance.removeTransactionCollector({
              traceId: this.collector.traceId,
            });
          },
        },
      ];
    },
  },
];
