import capitalize, { capitalizeSingle } from '@/utils/capitalize';
import store from '@/store/index';
import { i18n } from '@/i18n';
import { getObjKeyByValue } from '@/utils/tools';
import { SelectOptions } from '@/types/common';

interface ObjectData {
  [propName: string]: any;
}

/** 选择框的选项 */
export interface SelectOption {
  /** 标签名称 */
  label: string;
  /** 选择项的值 */
  value: string | number;
  disabled?: boolean;
}

export const SelectionLabelFixed: Record<string, string> = {
  ['On Going']: 'OnGoing',
  ['One Step']: 'One-Step',
  ['Two Step']: 'Two-Step',
  ['Three Step']: 'Three-Step',
  ['Rearrangement Report']: 'Re-arrangement Report',
  Pc: 'PC',
  Qc: 'QC',
  ['Non-qc Device']: 'Non-QC Device',
  ['No Block']: 'N',
  Block: 'Y',
  ['Sum  Lwh']: 'Sum LWH',
  ['Sync  From  Pms']: 'Sync  From  PMS',
  ['Buyer Never Request R/r But Wh Still Decide To Process Offline']:
    'Buyer never request R/R but WH still decide to process offline',
  ['Ri Order Has Never Been Created (but Buyer Has Requested R/r)']:
    'RI order has never been created (but buyer has requested R/R)',
  ['Ri Order Has Never Been Created (failed Delivery)']:
    'RI order has never been created (failed delivery)',
  ['Un Arrived']: 'Unarrived',
  Aql: 'AQL',
  Asn: 'ASN',
  YES: 'Yes',
  NO: 'No',
  Sa: 'SA',
  ['Hv-high Value']: 'HV-High Value',
  ['Tc-temperature Control']: 'TC-Temperature Control',
  ['RI  RTS  Order  Report( Pii  For  Awb)']:
    'RI RTS Order Report(PII For AWB)',
  ['RTB  Order  Report( Pii  For  Awb)']: 'RTB Order Report(PII For AWB)',
  ['Supplier  List  With  Pii']: 'Supplier List With  PII',
  ['Tc- Temperature  Control']: 'Temperature Control',
  ['Hv- High  Value']: 'High Value',
  ['Re- Inbound- Others']: 'Re-Inbound - Others',
  ['Re- Inbound- RTS']: 'Re-Inbound - RTS',
};

// 修复了safeGet工具函数原来返回undefined的逻辑不正确的问题
export const safeGet = (data: ObjectData, path: string): any => {
  if (!path) return undefined;
  const paths = path.split('.');
  let res = data;
  while (paths.length) {
    const index = paths.shift();
    if (index === undefined) return {};
    res = res[index];
    if (Object.prototype.toString.call(res) !== '[object Object]') return res;
  }
  return res;
};

export const checkPerm = function (
  state: ObjectData,
  perm: string,
  dataPath = 'perm.api_perms'
): boolean {
  const list = safeGet(state, dataPath) || [];
  return list.includes(perm);
};

export const getSelectOptions = function (
  state: ObjectData,
  dataPath = '',
  filterValue?: any
): SelectOptions[] {
  const list = safeGet(state, dataPath) || {};
  if (filterValue || filterValue === 0) {
    Object.keys(list).forEach((item: any) => {
      if (list[item] === filterValue) {
        delete list[item];
      }
    });
  }
  return Object.keys(list).map((item: any) => ({
    label: i18n(SelectionLabelFixed[capitalize(item)] || capitalize(item)),
    value: list[item],
  }));
};

export const getSafeSelectOptions = function (
  state: ObjectData,
  dataPath = '',
  filterValue?: any
): SelectOptions[] {
  let list = safeGet(state, dataPath) || {};
  if (filterValue || filterValue === 0) {
    list = Object.keys(list).reduce((result: Record<string, any>, curItem) => {
      if (list[curItem] !== filterValue) {
        result[curItem] = list[curItem];
      }
      return result;
    }, {});
  }
  return Object.keys(list).map((item: any) => ({
    label: i18n(SelectionLabelFixed[capitalize(item)] || capitalize(item)),
    value: list[item],
  }));
};

export const getSelectOptionsFirstWord = function (
  state: ObjectData,
  dataPath = '',
  filterValue?: any
): any[] {
  const list = safeGet(state, dataPath) || {};
  if (filterValue || filterValue === 0) {
    Object.keys(list).forEach((item: any) => {
      if (list[item] === filterValue) {
        delete list[item];
      }
    });
  }
  return Object.keys(list)
    .map((item: any) => ({
      label:
        SelectionLabelFixed[capitalizeSingle(item) as string] ||
        capitalizeSingle(item),
      value: list[item],
    }))
    .sort((a: any, b: any) => a.value - b.value);
};

export const getSelectOptionsWithoutCapitalize = function (
  state: ObjectData,
  dataPath = '',
  filterValue?: any
): SelectOption[] {
  const list = safeGet(state, dataPath) || {};
  if (filterValue || filterValue === 0) {
    Object.keys(list).forEach((item: any) => {
      if (list[item] === filterValue) {
        delete list[item];
      }
    });
  }
  return Object.keys(list).map((item: any) => ({
    label: item,
    value: list[item],
  }));
};

// 同 getSelectOptions，只是返回的数组处理为对象
export const getSelectOptionsObj = function (
  state: ObjectData,
  dataPath = '',
  filterValue?: any
): Record<string, any> {
  const list = safeGet(state, dataPath) || {};
  if (filterValue || filterValue === 0) {
    Object.keys(list).forEach((item: any) => {
      if (list[item] === filterValue) {
        delete list[item];
      }
    });
  }
  return Object.keys(list)
    .map((item: any) => ({
      label: i18n(SelectionLabelFixed[capitalize(item)] || capitalize(item)),
      value: list[item],
    }))
    .reduce((accu: Record<string, any>, item: any) => {
      const key = item.label;
      accu[key] = item.value;
      return accu;
    }, {});
};

// 不使用 delete 方式处理
export const getSafeSelectOptionsObj = function (
  state: ObjectData,
  dataPath = '',
  filterValue?: any
): Record<string, any> {
  let list = safeGet(state, dataPath) || {};
  if (filterValue || filterValue === 0) {
    list = Object.keys(list).reduce((result: Record<string, any>, curItem) => {
      if (list[curItem] !== filterValue) {
        result[curItem] = list[curItem];
      }
      return result;
    }, {});
  }
  return Object.keys(list)
    .map((item: any) => ({
      label: i18n(SelectionLabelFixed[capitalize(item)] || capitalize(item)),
      value: list[item],
    }))
    .reduce((accu: Record<string, any>, item: any) => {
      const key = item.label;
      accu[key] = item.value;
      return accu;
    }, {});
};

export const getRenderValue = function (
  state: ObjectData,
  dataPath = '',
  value: number | null | undefined
): string {
  const options = safeGet(state, dataPath) || {};
  const index = Object.values(options).indexOf(value);
  const formatLabel =
    SelectionLabelFixed[capitalize(Object.keys(options)[index])] ||
    capitalize(Object.keys(options)[index]);
  try {
    return index === -1 ? '' : i18n(formatLabel);
  } catch {
    return index === -1 ? '' : formatLabel;
  }
};

export const getRenderValueWithoutCapitalize = function (
  state: ObjectData,
  dataPath = '',
  value: number | null
): string {
  const options = safeGet(state, dataPath) || {};
  const index = Object.values(options).indexOf(value);
  const formatLabel =
    SelectionLabelFixed[Object.keys(options)[index]] ||
    Object.keys(options)[index];
  try {
    return index === -1 ? '' : i18n(formatLabel);
  } catch {
    return index === -1 ? '' : formatLabel;
  }
};

export const getRenderValueWithoutUnderline = function (
  state: ObjectData,
  dataPath = '',
  value: string | number,
  exception?: string
) {
  const options = safeGet(state, dataPath) || {};
  const index = Object.values(options).indexOf(value);
  return index === -1
    ? ''
    : Object.keys(options)
    /* eslint-disable */
        [index].split('_')
        .map((element) => {
          if (element === exception) return element;
          return element
            .toLowerCase()
            .replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
        })
        .join(' ');
  /* eslint-enable */
};
export const getRenderValueByOptions = function (
  options: any[],
  value: any
): string {
  const target = options.filter((option) => option.value === value)[0];
  return target && target.label;
};

export const getSelectOptionsWithoutUnderline = function (
  state: ObjectData,
  dataPath = '',
  exception?: string
) {
  const list = safeGet(state, dataPath) || {};
  return Object.keys(list).map((item) => ({
    label: item
      .split('_')
      .map((element) => {
        if (element === exception) return element;
        return element
          .toLowerCase()
          .replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
      })
      .join(' '),
    value: list[item],
  }));
};

export const getSelectOptionsWithoutUnderlineWithoutQuoteSpace = function (
  state: ObjectData,
  dataPath = '',
  exception?: string
) {
  const list = safeGet(state, dataPath) || {};
  return Object.keys(list).map((item) => ({
    label: item
      .split('_')
      .map((element) => {
        if (element === exception) return element;
        return element
          .toLowerCase()
          .replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
      })
      .join(''),
    value: list[item],
  }));
};

export const getSelectOptionsWithoutUnderlineNoSort = function (
  state: ObjectData,
  dataPath = '',
  exception?: string
) {
  const list = safeGet(state, dataPath) || {};
  return Object.keys(list).map((item) => ({
    label: item
      .split('_')
      .map((element) => {
        if (element === exception) return element;
        return element
          .toLowerCase()
          .replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
      })
      .join(' '),
    value: list[item],
  }));
};

export const enumsName = function (enumsKey: string, value: string | number) {
  const _obj = safeGet(store.state.enums, `systemEnums.${enumsKey}`) || {};
  const key = getObjKeyByValue(value, _obj);
  if (SelectionLabelFixed[capitalize(key)]) {
    return i18n(SelectionLabelFixed[capitalize(key)]);
  }
  return capitalize(i18n(key));
};

export const getRenderValueWithoutI18n = function (
  state: ObjectData,
  dataPath = '',
  value: number | null | undefined
): string {
  const options = safeGet(state, dataPath) || {};
  const index = Object.values(options).indexOf(value);
  const formatLabel =
    SelectionLabelFixed[capitalize(Object.keys(options)[index])] ||
    capitalize(Object.keys(options)[index]);
  return index === -1 ? '' : formatLabel;
};

export function filterParams(
  params: any,
  autoTrim = true,
  filters = ['', null, undefined, [], {}]
): any {
  if (!Array.isArray(filters)) throw new Error('filters must be array');
  const filterParams: any = {};
  const filtersJson = filters.map((item) => JSON.stringify(item));

  Object.keys(params).forEach((key) => {
    let value = params[key];
    if (typeof value === 'string') {
      value = autoTrim ? value.trim() : value;
    }

    if (
      !filtersJson.includes(
        autoTrim ? JSON.stringify(value) : JSON.stringify(value)
      )
    )
      filterParams[key] = value;
  });
  return filterParams;
}
