import debounce from 'lodash.debounce';
import Vue from 'vue';
import { numericTracker } from '../qms';

export function CatchError(loadingName?: string) {
  return function (target: any, name: string, descriptor: PropertyDescriptor) {
    const fn = target[name];
    descriptor.value = async function (...args: any[]) {
      if (loadingName) {
        (this as any)[loadingName] = true;
      }
      try {
        return await fn.call(this, ...args);
      } catch (error) {
        console.log('error', error);
      } finally {
        if (loadingName) {
          (this as any)[loadingName] = false;
        }
      }
    };
    return descriptor;
  };
}

export function Debounce(delay: number, config: object = {}) {
  return (target: any, prop: string) => {
    return {
      value: debounce(target[prop], delay, config),
    };
  };
}

interface ConfirmationConfig {
  title: string;
  message: string;
  options?: object;
  type?: string;
}
type ConfirmationConfigFn = () => ConfirmationConfig;
export function Confirmation(
  config: ConfirmationConfig | ConfirmationConfigFn
) {
  return function (target: any, name: string, descriptor: PropertyDescriptor) {
    const fn = descriptor.value;
    let _instance: any = null;
    descriptor.value = function (...args: any[]) {
      let _config: ConfirmationConfig | null = null;
      if (typeof config === 'function') {
        _config = config.call(this);
      } else {
        _config = config;
      }
      const confirmType = _config?.type || '$confirm';
      let showCancelButton = true;
      if (
        _config?.options &&
        (_config.options as any).showCancelButton !== undefined
      ) {
        showCancelButton = (_config.options as any).showCancelButton;
      }
      Vue.prototype[confirmType](
        _config?.message,
        _config?.title,
        Object.assign(
          {
            showCancelButton: showCancelButton,
            beforeClose: (action: string, instance: any, done: Function) => {
              _instance = instance;
              instance.confirmButtonLoading = true;
              if (action === 'confirm') {
                fn.call(this, done, instance, ...args);
              } else {
                done();
              }
            },
          },
          _config?.options || {}
        )
      )
        .then(() => {
          _instance.confirmButtonLoading = false;
        })
        .catch(() => {
          _instance.confirmButtonLoading = false;
        });
    };
    return descriptor;
  };
}

export function Validate(refName: string) {
  return function (target: any, name: string, descriptor: PropertyDescriptor) {
    const fn = descriptor.value;
    descriptor.value = function (...args: any[]) {
      // 将触发校验的代码封装在此
      (this as any).$refs[refName].validate((valid: boolean) => {
        if (valid) {
          fn.call(this, ...args);
        } else {
          return false;
        }
      });
    };
  };
}
