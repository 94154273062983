<template>
  <s-submenu
    :key="child.name"
    v-if="showMenu"
    :index="child.name"
    :name="child.name"
  >
    <template slot="title">
      <span class="ellipsis-item">
        {{ $i18n(child.meta.title) }}
        <span
          v-if="isFullStockCount"
          v-permission="
            'PC.InventoryManagement.FullStockCount.EndFullStockCount'
          "
          @click.stop="handleClickSwitch"
        >
          <svg-icon
            style="width: 16px; height: 16px"
            icon-class="cycle-count-switcher"
            class="cycle-count-switcher-icon"
          />
        </span>
        <span
          v-else
          v-permission="'PC.InventoryManagement.CycleCount.StartFullStockCount'"
          @click.stop="handleClickSwitch"
        >
          <svg-icon
            style="width: 16px; height: 16px"
            icon-class="cycle-count-switcher"
            class="cycle-count-switcher-icon"
          />
        </span>
      </span>
    </template>
    <template v-for="thirdChild in child.children">
      <s-menu-item
        :key="thirdChild.name"
        :name="thirdChild.name"
        :index="thirdChild.index"
        @click.native="openLink(thirdChild.name)"
      >
        <router-link
          class="ellipsis-item menu-item"
          :to="{ name: thirdChild.name }"
          >{{ $i18n(thirdChild.meta.title) }}</router-link
        >
      </s-menu-item>
    </template>
  </s-submenu>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import Store from '@/store';
import {
  CYCLE_COUNT_ROUTE_NAME,
  FULL_STOCK_COUNT_ROUTE_NAME,
} from '@/router/constant';
import { ElLoadingComponent } from 'element-ui/types/loading';
// import { operateFullStockCount } from '@/api/inventory-management/fsc-common';

@Component
export default class CycleCountMenu extends Vue {
  @Prop() readonly openLink: Function;
  @Prop() readonly child: Record<string, any>;

  loadingRef: null | ElLoadingComponent = null;

  get currentCountRouteName() {
    const status = Store.state.cycleCount.isFullStockCount;
    return status ? FULL_STOCK_COUNT_ROUTE_NAME : CYCLE_COUNT_ROUTE_NAME;
  }

  get showMenu() {
    const { child, currentCountRouteName } = this;
    return (
      child?.name === currentCountRouteName &&
      child?.children &&
      child?.children.length > 0
    );
  }

  get isFullStockCount() {
    const cycleCountStore = Store.state.cycleCount;
    return cycleCountStore.isFullStockCount;
  }

  async handleClickSwitch() {
    try {
      if (Store.state.cycleCount.isFullStockCount) {
        await (this as any).$alert(
          this.$i18n(
            'SKU in AV Area will be regarded as missing items.\nAn Inventory Adjustment Task will be created for them.'
          ),
          this.$i18n('Alert')
        );
        await (this as any).$confirm(
          this.$i18n('Confirm to switch into Normal Cycle Count mode ?'),
          'Notice'
        );
        this.loadingRef = this.$loading({
          fullscreen: true,
          text: this.$i18n('Loading'),
        });
        await Store.dispatch('operateFullStockCountSign', false);
        (this as any).loadingRef.close();
        await this.$message.success(this.$i18n('Full Stock Count End'));
      } else {
        await (this as any).$confirm(
          this.$i18n('Confirm to switch into Full Stock Count mode ?'),
          'Notice'
        );
        this.loadingRef = this.$loading({
          fullscreen: true,
          text: this.$i18n('Loading'),
        });
        await Store.dispatch('operateFullStockCountSign', true);
        (this as any).loadingRef.close();
        await this.$message.success(this.$i18n('Full Stock Count Start'));
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.loadingRef && (this.loadingRef as ElLoadingComponent).close();
    }
  }
}
</script>

<style scoped lang="scss">
.cycle-count-switcher-icon {
  vertical-align: middle;
  margin-left: 10px;
}
</style>
