import { DEFAULT_NULL_OR_EMPTY_STRING } from '@/common/constant';
import { format } from '@/utils/date';
import Store from '@/store';

export const formatTime = (value: any, type = 'day') => {
  if (value === 0) {
    return DEFAULT_NULL_OR_EMPTY_STRING;
  }
  return format(value, type) || value || DEFAULT_NULL_OR_EMPTY_STRING;
};

export const formatTimeUTCZero = (value: number, type = 'day') => {
  if (value === 0) {
    return DEFAULT_NULL_OR_EMPTY_STRING;
  }
  const isUTCZero = true;
  return (
    format(value, type, isUTCZero) || value || DEFAULT_NULL_OR_EMPTY_STRING
  );
};

export const clearFSCCreateStorage = () => {
  localStorage.setItem('fsc_create_store', '{}');
};
export const setFSCCreateStorage = (store: Record<string, any>) => {
  localStorage.setItem('fsc_create_store', JSON.stringify(store));
};
export const getFSCCreateStorage = (key?: string) => {
  const store = JSON.parse(localStorage.getItem('fsc_create_store') as string);
  if (store) {
    if (key === undefined) {
      return store || {};
    } else {
      return store[key];
    }
  }
  return {};
};

// 普通盘点 环境下操作 全仓盘点 接口 错误码
const OPERATE_FSC_ERRCODE_IN_CC_ENV = -285022;
// 全仓盘点 环境下操作 普通盘点 接口 错误码
const OPERATE_CC_ERRCODE_IN_FSC_ENV = -285021;
// 检查是否需要跳转，并进行跳转
export function checkAndHandleCycleCountErrcode(errcode: number): boolean {
  switch (errcode) {
    // 普通盘点 环境下操作 全仓盘点 接口, store 处理自动跳转到 ccOrder.list
    case OPERATE_FSC_ERRCODE_IN_CC_ENV: {
      Store.commit('setFullCycleCountFlag', {
        isOpen: false,
        from: 'apiCode',
      });
      return true;
    }
    // 全仓盘点 环境下操作 普通盘点 接口, store 处理自动跳转到 ccOrder.list
    case OPERATE_CC_ERRCODE_IN_FSC_ENV: {
      Store.commit('setFullCycleCountFlag', {
        isOpen: true,
        from: 'apiCode',
      });
      return true;
    }
    default: {
      //
      return false;
    }
  }
}
