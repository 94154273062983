import Vue from 'vue';
import { hasPermission } from '@/utils/checkPermission';

// 注册一个全局自定义指令 `v-permission`
Vue.directive('permission', {
  inserted: (el, binding) => {
    // 如果没有权限就移除此节点
    if (!hasPermission(binding.value)) {
      el.parentNode && el.parentNode.removeChild(el);
    }
  },
});
