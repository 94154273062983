import request from '@/utils/request';

interface InterfaceCreateUser {
  name?: string;
  email?: string;
  phone?: string;
  ip_white_list?: string;
  contract_type?: number;
  hire_status?: number;
}

export const createUser = async (params: InterfaceCreateUser) => {
  const res = await request.post('/api/v2/apps/config/userlist/create_user', {
    ...params,
  });
  return res;
};

interface InterfaceEditUser {
  user_id?: number;
  name?: string;
  email?: string;
  phone?: string;
  status?: number;
}

export const editUser = async (params: InterfaceEditUser) => {
  const res = await request.post('/api/v2/apps/config/userlist/edit_user', {
    ...params,
  });
  return res;
};

interface InterfaceGetUserList {
  name?: string;
  email?: string;
  phone?: string;
  soup_status?: number;
  sourcing_flag?: number;
  active_level?: number;
  status?: number;
  pageno?: number;
  count?: number;
}

export const getUserList = async (params: InterfaceGetUserList) => {
  const res = await request.get('/api/v2/apps/config/userlist/get_user_list', {
    params: {
      ...params,
    },
  });
  return res;
};

interface InterfaceResetUserPassword {
  user_id?: number;
  email?: string;
  password?: string;
}

export const resetUserPassword = async (params: InterfaceResetUserPassword) => {
  const res = await request.post(
    '/api/v2/apps/config/userlist/reset_user_password',
    {
      ...params,
    }
  );
  return res;
};

interface InterfaceSearchEmail {
  email?: string;
  search_type?: number;
}

export const searchEmail = async (params: InterfaceSearchEmail) => {
  const res = await request.get('/api/v2/apps/config/userlist/search_email', {
    params: {
      ...params,
    },
  });
  return res;
};

interface InterfaceUploadUserList {
  excel_file?: object;
}

export const uploadUserList = async (params: InterfaceUploadUserList) => {
  const res = await request.post(
    '/api/v2/apps/conf/userlist/upload_user_list',
    {
      ...params,
    }
  );
  return res;
};

export const getRoleConfiguration = async () => {
  const res = await request.get(
    '/api/v2/apps/config/userlist/get_role_configuration',
    {}
  );
  return res;
};

export const searchAccessStatusLog = async (params: any) => {
  const res = await request.get(
    '/api/v2/apps/config/userlist/search_access_status_log',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

export const createBpoUser = async (params: any) => {
  /* PermissionKey: ALL */
  const res = await request.post(
    '/api/v2/apps/config/userlist/create_bpo_user',
    {
      ...params,
    }
  );
  return res;
};

export const searchUploadTask = async (params: any) => {
  /* PermissionKey: ALL */
  const res = await request.get(
    '/api/v2/apps/config/userlist/search_upload_task',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

export const getBpoRoleList = async (params: any) => {
  /* PermissionKey: ALL */
  const res = await request.get(
    '/api/v2/apps/config/userlist/get_bpo_role_list',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

export const syncUserToTob = async (params: any) => {
  const res = await request.post(
    /* PermissionKey: PC.SystemConfig.UserList.Action.Edit */
    '/api/v2/apps/config/userlist/sync_user_to_tob',
    {
      ...params,
    }
  );
  return res;
};

interface InterfaceResetMyPwd {
  new_pwd: string;
  old_pwd: string;
}

export const resetMyPwd = async (params: InterfaceResetMyPwd) => {
  const res = await request.post(
    /* PermissionKey: ALL */
    '/api/v2/apps/system/user/reset_my_pwd',
    {
      ...params,
    }
  );
  return res;
};
