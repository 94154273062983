import Layout from '../../views/layout/index.vue';
import rms from './rms';
import { getCookie } from '@/utils/cookie';
import { errorReport } from '@/utils/tools';
// import { getInboundConfig } from '@/api/inbound/inbound-config';
// import { safeGet } from '@/utils/safeGet';
// import wmsBasicApi from '@/api/wms-basic/warehouse';
// import store from '@/store';

const returnInbound = {
  path: '/returninbound',
  name: 'returnInbound',
  meta: {
    title: 'Return Inbound',
    icon: 'Return Inbound',
  },
  redirect: '/returninbound/order',
  component: () => import('@/views/layout/index.vue'),
  children: [
    {
      path: 'orderasn',
      name: 'returnInbound.orderasn',
      meta: {
        title: 'Order',
      },
      redirect: '/returninbound/order',
      component: () => import('../../views/layout/subPage.vue'),
    },
    {
      path: 'order',
      name: 'PC.ReturnInbound.Order.ReturnASN.View',
      meta: {
        title: 'ASN',
        menuHide: true,
        filterSave: true,
      },
      component: () => import('../../views/return-inbound/order/asn/index.vue'),
    },
    {
      path: 'queuelist',
      name: 'PC.ReturnInbound.Order.QueueList.View',
      meta: {
        menuHide: true,
        title: 'Queue List',
        icon: '',
      },
      component: () =>
        import('../../views/return-inbound/queue-list/index.vue'),
    },
    {
      path: '/returnInbound/asngroup',
      name: 'returnInbound.asnGroup',
      meta: {
        menuHide: true,
        title: 'ASN Group',
        icon: '',
      },
      component: () => import('../../views/return-inbound/asn-group/index.vue'),
    },
    {
      path: '/returnInbound/asngroup/detail',
      name: 'returnInbound.asnGroup.detail',
      meta: {
        title: 'ASN Group Detail',
        menuHide: true,
        icon: '',
        navList: [
          {
            routeName: 'returnInbound',
            title: 'returnInbound',
          },
          {
            routeName: 'returnInbound.asnGroup',
            title: 'ASN Group',
          },
        ],
      },
      component: () =>
        import('../../views/return-inbound/asn-group/detail.vue'),
    },
    {
      path: 'order/detail/:id',
      name: 'returnInbound.order.detail',
      meta: {
        icon: '',
        title: 'Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'returnInbound',
            title: 'Return Inbound',
          },
          {
            routeName: 'PC.ReturnInbound.Order.ReturnASN.View',
            title: 'Order',
          },
        ],
      },
      component: () =>
        import('../../views/return-inbound/order/asn/detail.vue'),
    },
    {
      path: 'order/create/',
      name: 'returnInbound.order.create',
      meta: {
        icon: '',
        title: 'Create',
        menuHide: true,
        navList: [
          {
            routeName: 'returnInbound',
            title: 'Return Inbound',
          },
          {
            routeName: 'PC.ReturnInbound.Order.ReturnASN.View',
            title: 'Order',
          },
        ],
      },
      component: () =>
        import('../../views/return-inbound/order/asn/create.vue'),
    },
    {
      path: 'task',
      name: 'returnInbound.task',
      meta: {
        title: 'Task',
      },
      redirect: '/returninbound/qctask',
      component: () => import('../../views/layout/subPage.vue'),
    },
    {
      path: 'qctask',
      name: 'PC.ReturnInbound.Task.QCTask.View',
      meta: {
        menuHide: true,
        title: 'QC Task',
        filterSave: true,
      },
      component: () =>
        import('../../views/task-center/inbound-task/qc-task/qc-task.vue'),
    },
    {
      path: 'qctaskdetail',
      name: 'returnInbound.qcTaskDetail',
      meta: {
        title: 'QC Task Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'returnInbound',
            title: 'Return Inbound',
          },
          {
            routeName: 'PC.ReturnInbound.Task.QCTask.View',
            title: 'Inbound Task',
          },
        ],
      },
      component: () =>
        import(
          '../../views/task-center/inbound-task/qc-task/qc-task-detail.vue'
        ),
    },
    {
      path: 'receivetask',
      name: 'PC.ReturnInbound.Task.ReceiveTask.View',
      meta: {
        menuHide: true,
        title: 'Receiving Task',
        filterSave: true,
      },
      component: () =>
        import(
          '../../views/task-center/inbound-task/receive-task/receive-task.vue'
        ),
    },
    {
      path: 'receivetaskdetail',
      name: 'returninbound.receiveTaskDetail',
      meta: {
        title: 'Receiving Task Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'returnInbound',
            title: 'Return Inbound',
          },
          {
            routeName: 'PC.ReturnInbound.Task.ReceiveTask.View',
            title: 'Receiving Task',
          },
        ],
      },
      component: () =>
        import(
          '../../views/task-center/inbound-task/receive-task/receive-task-detail.vue'
        ),
    },
    {
      path: 'arrving',
      name: 'returnInbound.arriving',
      meta: {
        title: 'Arriving',
        icon: '',
      },
      component: () => import('../../views/return-inbound/arriving/index.vue'),
    },
    {
      path: 'qcReceiving',
      name: 'returnInbound.qcReceiving',
      meta: {
        title: 'QC&Receiving',
        icon: '',
      },
      component: () => import('../../views/return-inbound/qc/qc.vue'),
    },
    {
      path: 'ristandardputaway',
      name: 'returnInbound.standardPutaway',
      meta: {
        title: 'Standard Putaway',
        icon: '',
      },
      component: () =>
        import(
          '../../views/return-inbound/standard-putaway/standard-putaway.vue'
        ),
    },
    {
      path: 'rimassputaway',
      name: 'returnInbound.rimassPutaway',
      meta: {
        title: 'Mass Putaway',
        icon: '',
      },
      component: () =>
        import('../../views/return-inbound/mass-putaway/mass-putaway.vue'),
    },
    {
      path: 'putawaytask',
      name: 'PC.ReturnInbound.Task.PutawayTask.View',
      meta: {
        title: 'Putaway Task',
        menuHide: true,
        filterSave: true,
        navList: [
          {
            routeName: 'returnInbound',
            title: 'Return Inbound',
          },
        ],
      },
      component: () =>
        import(
          '../../views/task-center/inbound-task/putaway-task/inbound/putaway-task.vue'
        ),
    },
    {
      path: 'returnToBuyer',
      name: 'returnInbound.returnToBuyer',
      meta: {
        title: 'Return to Buyer',
        icon: '',
        filterSave: true,
      },
      component: () =>
        import('../../views/return-inbound/return-buyer/index.vue'),
    },
    {
      path: 'returnToBuyer/detail',
      name: 'returnInbound.returnToBuyer.detail',
      meta: {
        title: 'Return to Buyer View',
        menuHide: true,
        icon: '',
      },
      component: () =>
        import('../../views/return-inbound/return-buyer/detail.vue'),
    },
    {
      path: 'putawaytask/edit',
      name: 'returnInbound.putawayTask.edit',
      meta: {
        title: 'Putaway Task Edit',
        menuHide: true,
        navList: [
          {
            routeName: 'returnInbound',
            title: 'Return Inbound',
          },
          {
            routeName: 'PC.ReturnInbound.Task.PutawayTask.View',
            title: 'Putaway Task',
          },
        ],
      },
      component: () =>
        import(
          '../../views/task-center/inbound-task/putaway-task/inbound/putaway-task-detail.vue'
        ),
    },
    {
      path: 'putawaytask/detail',
      name: 'returnInbound.putawayTask.detail',
      meta: {
        title: 'Putaway Task Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'returnInbound',
            title: 'Return Inbound',
          },
          {
            routeName: 'PC.ReturnInbound.Task.PutawayTask.View',
            title: 'Putaway Task',
          },
        ],
      },
      component: () =>
        import(
          '../../views/task-center/inbound-task/putaway-task/inbound/putaway-task-detail.vue'
        ),
    },
  ],
};

let returnInboundRouterConfig: any = returnInbound;

const storageKey = 'rms-flag';
let flag = false;
let rmsFlagObj: any = {};
let whs = '';

//判断localstorage 是否有标志，优先使用localstorage的标志
const getFlag = () => {
  try {
    //获取仓库
    whs = getCookie('setting_whs_v2') || '';
    if (!whs) {
      return;
    }
    //获取localstorage rms-flag
    const rmsFlagStr = localStorage.getItem(storageKey);
    if (!rmsFlagStr) {
      return;
    }
    rmsFlagObj = JSON.parse(rmsFlagStr);
    if (typeof rmsFlagObj[whs] != 'undefined') {
      flag = rmsFlagObj[whs];
    }
  } catch (e) {
    errorReport(e);
  }
};

// const getFlag = () => {
//   try {
//     console.log('aaaaaaa', store);

//     flag = store.state.warehouseDetail.warehouse.is_rms || false;
//     console.log('ffffff', flag);
//   } catch (e) {
//     errorReport(e);
//   }
// };

//更新localstorage里的数据
// const updateFlag = async () => {
//   try {
//     if (!whs) {
//       return;
//     }
//     localStorage.setItem('new-rms', flag.toString());
//     const res = await getInboundConfig({ biz_type: '2' }); //退货入库
//     const is_rms = safeGet(res, 'data.is_rms') || 0;
//     if (is_rms !== flag) {
//       rmsFlagObj[whs] = is_rms ? 1 : 0;
//       localStorage.setItem(storageKey, JSON.stringify(rmsFlagObj));
//       // setTimeout(() => {
//       //   location.reload();
//       // }, 200);
//     }
//   } catch (e) {
//     errorReport(e);
//   }
// };

getFlag();
// updateFlag();

if (flag) {
  returnInboundRouterConfig = rms;
}

export default returnInboundRouterConfig;
