import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import router from '@/router';

@Module
class Filter extends VuexModule {
  filterOptions: any = {};
  pageOptions: any = {};
  sortOptions: any = {};
  @Mutation
  setFilterOptions(data: any): void {
    this.filterOptions = { ...this.filterOptions, ...data };
  }
  @Action({ commit: 'setFilterOptions' })
  async getFilterOptions(data: any) {
    const save = (router as any).history.current.meta.filterSave;
    if (!save) {
      return;
    }
    return data;
  }

  @Mutation
  setPageOptions(data: any): void {
    this.pageOptions = { ...this.pageOptions, ...data };
  }
  @Action({ commit: 'setPageOptions' })
  async getPageOptions(data: any) {
    const save = (router as any).history.current.meta.filterSave;
    if (!save) {
      return;
    }
    return data;
  }

  @Mutation
  setSortOptions(data: any): void {
    this.sortOptions = { ...this.sortOptions, ...data };
  }
  @Action({ commit: 'setSortOptions' })
  async getSortOptions(data: any) {
    return data;
  }
}

export default Filter;
