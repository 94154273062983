import Layout from '../../views/layout/index.vue';
const reportCenter = {
  path: '/reportcenter',
  name: 'reportCenter',
  meta: {
    title: 'Report Center',
    icon: 'Report Center',
  },
  redirect: '/reportcenter/reportcenter',
  component: () => import('@/views/layout/index.vue'),
  children: [
    {
      path: 'reportcenter',
      name: 'reportCenter.reportCenter',
      meta: {
        title: 'Report Center',
        icon: 'SIconBasicData',
      },
      component: () => import('@/views/report-center/report-center'),
    },
    {
      path: 'asnreport',
      name: 'reportCenter.inboundASNReport',
      meta: {
        title: 'Inbound ASN Report',
        icon: 'SIconBasicData',
      },
      component: () =>
        import('@/views/report-center/inbound-asn-report/index.vue'),
    },
    {
      path: 'asnskureport',
      name: 'reportCenter.inboundASNSKUReport',
      meta: {
        title: 'Inbound ASN SKU Report',
        icon: 'SIconBasicData',
      },
      component: () =>
        import('@/views/report-center/inbound-asn-sku-report/index.vue'),
    },
    {
      path: 'destination',
      name: 'reportCenter.destinationcalculation',
      meta: {
        title: 'Destination Calculation',
        icon: 'SIconBasicData',
      },
      component: () =>
        import('@/views/report-center/destination-calculation/index.vue'),
    },
  ],
};

export default reportCenter;
