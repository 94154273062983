import { CreateElement } from 'vue';
import SFilterPart from './index';
import { i18n } from '@/i18n/index';
import showOldSystemDateDialog from '@/utils/showOldSystemDateDialog';
import Store from '@/store';
import StoreService from '@/components/store-service/index.vue';
import FilterSelect from './filter-select.vue';

export default function (self: SFilterPart, h: CreateElement) {
  const showMoreText = self.showCollapse ? 'Collapse' : 'More';
  const arrowButton = h('div', {
    class: {
      'arrow-button': true,
      'more': showMoreText === 'More',
      'collapse': showMoreText === 'Collapse',
    },
  });
  const moreButton = h(
    's-button',
    {
      class: 'more-button',
      props: {
        type: 'text',
      },
      on: {
        click() {
          self.showCollapse = !self.showCollapse;
          self.$nextTick(() => {
            // self.$triggerResize();
          });
        },
      },
    },
    [showMoreText, arrowButton]
  );
  const searchButton = h(
    'wms-button',
    {
      class: {
        'filter-search': true,
      },
      props: {
        type: 'primary',
        mode: self.filterData,
        click: async (data: any) => {
          /**
           * 先获取路由名称，路由名称作为 key
           */
          const name = self.$route.name;
          if (name) {
            const params: any = {
              [name]: {
                pageSize:
                  self.filterOptions?.pageOptions[name as string]?.pageSize ||
                  20,
                page: 1,
              },
            };
            // 先初始化 page 配置为第一页
            Store.dispatch('getPageOptions', params);
          }

          // 搜索的时候，开始设置筛选条件
          const routeName = (self as any).$route.name;
          const params: any = {
            [routeName]: {},
          };
          // 遍历查看存在值的并设置
          self.filterConfig.forEach((item) => {
            if (item.prop instanceof Array) {
              const val = self.filterData[item.prop.join('+')];
              val !== undefined &&
                (params[routeName][item.prop.join('+')] = val);
            } else if (item.prop) {
              const val = self.filterData[item.prop];
              val !== undefined && (params[routeName][item.prop] = val);
            }
          });
          // 设置
          Store.dispatch('getFilterOptions', params);

          // 这里需要通过 getFilterData ，这里拿到的时间才是通过时区转换的
          const searchData = self.getFilterData();
          (self.$parent as any).search &&
            (await (self.$parent as any).search(searchData));
          self.onSearch && (await self.onSearch(searchData));
          await showOldSystemDateDialog(searchData);
        },
      },
    },
    [i18n(self.searchText)]
  );
  const resetButton = h(
    'wms-button',
    {
      class: 'filter-reset',
      props: {
        click: () => {
          return new Promise((resolve) => {
            const prevFilterData = { ...self.filterData };
            Object.keys(self.filterData).forEach(
              (key) => (self.filterData[key] = undefined)
            );
            // 触发各个setting的 clear 回调
            self.filterConfig.forEach((item: any) => {
              const { clear, clearable, prop: key } = item;
              if (clear && clearable) {
                const value = self.filterData[key];
                item.clear(
                  value,
                  self.filterConfig,
                  self.changeFilterData,
                  prevFilterData[key]
                );
              }
              // 远程搜索去掉下拉选项
              if (item.remoteMethod) {
                item.selections = [];
              }
            });

            // reset vuex filter value
            const name = self.$route.name;
            if (name) {
              const params: any = {
                [name]: {},
              };
              Store.commit('setFilterOptions', params);
            }

            self.init();
            (self.$parent as any).cancel &&
              (self.$parent as any).cancel(prevFilterData);
            self.onCancel && self.onCancel(prevFilterData);
            /* (self.$parent as any).search &&
              (self.$parent as any).search().then(() => {
                resolve();
              }); */
            (self as Vue).$emit('reset');
            // const searchData = self.getFilterData();
            // (self.$parent as any).search && (self.$parent as any).search(searchData);
            // self.onSearch && self.onSearch(searchData);
            resolve('');
            // self.onSearch && self.onSearch(self.filterData);
          });
        },
      },
    },
    [i18n(self.resetText)]
  );
  const filterSelect = h(FilterSelect, {
    class: '',
    props: {
      allFilter: self.filterList.map((item: any) => item.tLabel),
      keyName: self.keyName + '_filter',
    },
    on: {
      filerSelectChange(value: string[]) {
        self.selectFilterList = value;
        self.init();

        const prevFilterData = { ...self.filterData };
        self.filterConfig.forEach((item: any) => {
          // 为勾选的filter都要执行clear
          if (!value.includes(item.tLabel) && value?.length) {
            const { clear, clearable, prop: key } = item;
            if (Array.isArray(key)) {
              self.filterData[key.join('+')] = undefined;
            } else {
              self.filterData[key] = undefined;
            }

            if (clear && clearable) {
              const value = self.filterData[key];
              item.clear(
                value,
                self.filterConfig,
                self.changeFilterData,
                prevFilterData[key]
              );
            }
            // 远程搜索去掉下拉选项
            if (item.remoteMethod) {
              item.selections = [];
            }
          }
        });
      },
    },
  });
  const storeService = self.showBookmark
    ? h(StoreService, {
        class: '',
        props: {
          bookMarkToolTip: self.bookMarkToolTip,
          keyName: self.keyName,
          data: self.filterData,
        },
        on: {
          change: (value: any) => {
            const res: any = {};

            for (const key in value) {
              if (Object.prototype.hasOwnProperty.call(self.filterData, key)) {
                // 使用了勾选filter功能后 没勾选的value不能赋值
                if (self.showFilterSelect && self.selectFilterList.length) {
                  const config = self.filterConfig.find(
                    (item) => item.prop === key
                  );
                  if (self.selectFilterList.includes(config?.tLabel)) {
                    res[key] = value[key];
                  }
                } else {
                  res[key] = value[key];
                }
              }
            }
            Object.assign(self.filterData, res);
            self.showMoreList();
          },
          loadBookmark: () => {
            (self as Vue).$emit('loadBookmark');
          },
        },
      })
    : null;
  const btnComp = [
    searchButton,
    resetButton,
    self.showFilterSelect && filterSelect,
    storeService,
    self.showMore && moreButton,
  ];
  if (!self.autoCollapse) {
    // 不展示 more/collapse
    btnComp.pop();
  }
  return h(
    'div',
    {
      class: 'filter-buttons',
    },
    btnComp
  );
}
