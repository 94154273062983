import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

@Module
class User extends VuexModule {
  from = {};
  to = {};
  @Mutation
  setRouteFromAndTo(data: any = {}): void {
    this.from = data.from;
    this.to = data.to;
  }
  @Action({ commit: 'setRouteFromAndTo' })
  async getRouteFromAndTo(route: any) {
    return route;
  }
}
export default User;
