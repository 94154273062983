import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { getFavorite } from '@/api/dashboard/outbound';
import { safeGet } from '@/utils/safeGet';
import isString from 'lodash/isString';

@Module
class OutboundFavoutite extends VuexModule {
  // 购物车个数
  shoppingCartNum = 0;
  // 购物车内容
  shoppingCart: any[] = [];
  // 购物车标题
  favouriteFileName = '';
  @Mutation
  setShoppingCart(cart: any): void {
    this.shoppingCartNum = cart?.favorites?.length || 0;
    this.shoppingCart = cart?.favorites || [];
    this.favouriteFileName = cart?.fileName || '';
  }
  @Action({ commit: 'setShoppingCart' })
  async getShoppingCart() {
    const data = safeGet(await getFavorite({}), 'data');
    let favorites = data?.favorites;
    if (!favorites) {
      favorites = [];
    } else {
      favorites = JSON.parse(favorites).map((el: string) => {
        if (isString(el)) {
          return JSON.parse(el);
        }
        return el;
      });
    }
    return (
      {
        favorites: (favorites || []).filter((f: any) => !f?.is_V2),
        fileName: data?.title,
      } || []
    );
  }
}
export default OutboundFavoutite;
