<template>
  <div id="react-container" ref="rootRef"></div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
import { unmountComponentAtNode } from 'react-dom';
import { renderReactComponent } from '@/react-app/utils/render';

export default defineComponent({
  name: 'ReactContainer',
  props: {
    component: Function,
  },
  setup(props) {
    const rootRef = ref(null);

    const renderComponent = () => {
      const propComponent = props.component;
      if (!propComponent || !rootRef.value) {
        throw new Error('component or rootRef is not defined');
      }

      try {
        renderReactComponent(
          propComponent as any,
          rootRef.value as unknown as HTMLElement
        );
      } catch (e) {
        console.error('React Container Error:', e);
      }
    };

    onMounted(() => {
      renderComponent();
    });

    onBeforeUnmount(() => {
      unmountComponentAtNode(rootRef.value as unknown as HTMLElement);
    });

    return {
      rootRef,
    };
  },
});
</script>
