export const getEnv = function () {
  return process.env.VUE_APP_RUN_ENV || 'live';
};

export const getPFB = function () {
  const pfb_tag = window?.__JENKINS_BUILD_MSG__?.['pfb name'] ? 'PFB' : '';
  return pfb_tag;
};

export const isXxEnv = function () {
  // SPWMS-40137需求
  // xx环境首页url: https://wms.ssc.shopee.systems/v2/home
  const isXx = /^wms\.ssc\.shopee\.systems/.test(location.host);
  return isXx || false;
};

export const getOldSystemUrl = function () {
  const env = getEnv();
  let prefix = '';
  if (location.host.toLowerCase().startsWith('tw.wms')) {
    prefix = 'tw.';
  }
  if (env === 'live') {
    return `${location.protocol}//${prefix}wms.shopeemobile.com/ui/`;
  }
  if (env === 'dev') {
    return `${location.protocol}//${prefix}wms.test.shopeemobile.com/ui/`;
  }
  return `${location.protocol}//${prefix}wms.${env}.shopeemobile.com/ui/`;
};

export const getWFMSystemUrl = function () {
  const env = getEnv();
  if (location.hostname.indexOf('.mx') > -1) {
    if (env === 'live') {
      return `${location.protocol}//wfm.ssc.shopee.com.mx/`;
    }
    if (env === 'dev') {
      return `${location.protocol}//wfm.ssc.test.shopee.com.mx/`;
    }
    return `${location.protocol}//wfm.ssc.${env}.shopee.com.mx/`;
  } else {
    if (env === 'live') {
      return `${location.protocol}//wfm.ssc.shopee.com/`;
    }
    if (env === 'dev') {
      return `${location.protocol}//wfm.ssc.test.shopee.com/`;
    }
    return `${location.protocol}//wfm.ssc.${env}.shopee.com/`;
  }
};
