/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { VNode } from 'vue';

@Component
export default class WMSButton extends Vue {
  @Prop()
  readonly title!: string;
  @Prop()
  readonly type!: string;
  @Prop()
  readonly disabled!: boolean;
  @Prop()
  readonly size!: string;
  @Prop()
  readonly shape!: string;
  @Prop()
  readonly plain!: string;
  @Prop()
  readonly click!: Function;
  @Prop({
    default: () => {
      return {};
    },
  })
  readonly mode!: any;
  @Prop()
  readonly fillAll!: any;

  @Watch('$parent.ruleForm')
  async onAfterChanged() {
    try {
      await this.checkRequiredItem();
      this.disabledButton = false;
    } catch (e) {
      console.log('error', e);
      this.disabledButton = true;
    }
  }
  loading = false;
  requiredItemDic = {};
  disabledButton = false;

  async loadingClick() {
    try {
      this.loading = true;
      const formatMode: any = {};
      if (Object.keys(this.mode).length) {
        Object.keys(this.mode).forEach((item: any) => {
          // 特别用于判断 time picker 收集的 [startTimeStamp, endTimeStamp]
          if (item.indexOf('+') >= 0) {
            this.setTimerRangeDateMode(item, this.mode, formatMode);
          } else {
            formatMode[item] = this.mode[item];
          }
        });
        await this.click(formatMode);
      } else {
        await this.click();
      }
    } catch (e) {
      console.log('error', e);
      this.loading = false;
    } finally {
      this.loading = false;
    }
  }
  async showButton() {
    try {
      await this.checkRequiredItem();
      this.disabledButton = false;
    } catch (e) {
      console.log('error', e);
      this.disabledButton = true;
    }
  }

  // 特别用于设置formatmode 的  time picker 收集的 [startTimeStamp, endTimeStamp]
  setTimerRangeDateMode(
    key: string,
    mode: Record<string, any>,
    formatMode: Record<string, any>
  ) {
    const [startKey, endKey] = key.split('+');
    if (mode[key]) {
      const startDate = mode[key][0];
      const endDate = mode[key][1];
      formatMode[startKey] =
        typeof startDate !== 'undefined'
          ? +new Date(startDate) / 1000
          : startDate;
      formatMode[endKey] =
        typeof endDate !== 'undefined' ? +new Date(endDate) / 1000 : endDate;
    }
  }

  checkRequiredItem() {
    return new Promise((resolve, reject) => {
      const temp = (this.$parent as any).ruleForm;
      const validate = Object.keys(this.requiredItemDic).every((item: any) => {
        return temp[item] || temp[item] === 0;
      });
      if (validate) {
        resolve(true);
      } else {
        reject(true);
      }
    });
  }

  formatRequiredItemDic() {
    Object.keys((this.$parent as any).rules).forEach((item: any) => {
      (this.$parent as any).rules[item].forEach((subItem: any) => {
        if (subItem.required) {
          (this.requiredItemDic as any)[item] = true;
        }
      });
    });
  }

  mounted() {
    if (this.fillAll === '' || this.fillAll) {
      this.formatRequiredItemDic();
      window.addEventListener('change', this.showButton);
      this.disabledButton = true;
    }
  }
  destroyed() {
    if (this.fillAll) {
      window.removeEventListener('change', this.showButton);
    }
  }

  render(): VNode {
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    return (
      <s-button
        title={this.title}
        type={this.type}
        disabled={this.disabled || this.disabledButton}
        size={this.size}
        shape={this.shape}
        plain={this.plain}
        loading={this.loading}
        onClick={this.loadingClick}
      >
        {this.$slots.default}
      </s-button>
    );
  }
}
