import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import permission from '../../api/basic/permission';
import routesConfig from '@/router/config';
import { loginRoutesConfig } from '@/router/login-route.config';
import { tabNames } from '@/common/tab-names';
// import { RouteConfig } from 'vue-router';
import { registerPermissions } from '@spx-portal/shared-permissions';

function getMenuHideRouters(routers: any, menuHideRouterNames: any) {
  for (const index in routers) {
    menuHideRouterNames.push(routers[index].name);
    if ('children' in routers[index]) {
      getMenuHideRouters(routers[index].children, menuHideRouterNames);
    }
  }
}

function search(routers: any, menuHideRouterNames: any[]) {
  for (const index in routers) {
    if (routers[index].meta.menuHide) {
      menuHideRouterNames.push(routers[index].name);
      if ('children' in routers[index]) {
        getMenuHideRouters(routers[index].children, menuHideRouterNames);
      }
    } else {
      if ('children' in routers[index]) {
        search(routers[index].children, menuHideRouterNames);
      }
    }
  }
}

@Module
class PermissionMenu extends VuexModule {
  // 接口返回的有权限展示的菜单栏的name的集合
  permissionMenu = [];

  // 接口返回的用户权限id集合
  userPermission = [];

  // 所有可渲染的页面路由
  routers = loginRoutesConfig;

  // 除loginRoutesConfig之外所有可渲染的页面路由
  addRouters: any[] = [];

  // all tab page names: {<one tab page name>: [the rest tab page names for one menu, ...], ...}
  tabPageNamesMap: any = {};

  @Mutation
  setPermissionMenu(data = []): void {
    this.permissionMenu = data;
    const menuHideRouterNames: any[] = [];
    search(routesConfig, menuHideRouterNames);
    this.addRouters = menuHideRouterNames;
  }
  @Mutation
  setUserPermission(data = []): void {
    this.userPermission = data;
  }
  @Mutation
  setRoutes(routers = []): void {
    this.addRouters = routers;
    this.routers = loginRoutesConfig.concat(routers);
  }
  @Mutation
  setTabPageNames(tabNames: any = []): void {
    const m: any = {};
    tabNames.forEach((arr: any[]) => {
      arr.forEach((name: any) => {
        m[name] = arr;
      });
    });
    this.tabPageNamesMap = m;
  }

  @Action({ commit: 'setPermissionMenu', rawError: true })
  async loadPermissionMenu() {
    const resp = await permission.getPermissionMenu();
    return resp.data.list;
  }
  @Action({ commit: 'setUserPermission', rawError: true })
  async loadUserPermission() {
    const resp = await permission.getUserPermission();
    registerPermissions(() => new Set(resp.data.list || []));
    return resp.data.list;
  }
  @Action({ commit: 'setTabPageNames', rawError: true })
  async loadTabPageNames() {
    return tabNames;
  }
  // @Action({ commit: 'setRoutes', rawError: true })
  // generateRoutes() {
  //   const accessedRouters = filterAsyncRouter(
  //     routesConfig,
  //     this.permissionMenu
  //   );
  //   return accessedRouters;
  // }
}
export default PermissionMenu;
