import reactContainerFactory from '@/components/react-container/reactContainerFactory';

import { lazy } from 'react';

const queryCenter = {
  path: '/query',
  name: 'query',
  meta: {
    title: 'Query',
    icon: 'Query',
  },
  redirect: '/query',
  component: () => import('@/views/layout/index.vue'),
  children: [
    {
      path: 'upcuidsku-id',
      name: 'query.upc/uid/skuid',
      meta: {
        title: 'UPC/UID/SKU ID',
      },
      component: () =>
        import('../../views/query-center/upcuidsku-id/index.vue'),
    },
    {
      path: 'locationDeviceValidationTask',
      name: 'query.locationdevicevalidationtask',
      meta: {
        title: 'Location & Device Validation Task',
      },
      component: reactContainerFactory({
        name: 'query.locationdevicevalidationtask',
      }),
      props: {
        component: () =>
          lazy(
            () =>
              import(
                '@/react-app/pages/query-center/location-device-validation-task'
              )
          ),
      },
    },
    {
      path: 'locationDeviceValidationTask/detail/:id',
      name: 'query.locationDeviceValidationTask.detail',
      meta: {
        title: 'View',
        navList: [
          {
            routeName: 'query',
            title: 'Query',
          },
          {
            routeName: 'query.locationdevicevalidationtask',
            title: 'Location & Device Validation Task',
          },
        ],
        menuHide: true,
      },
      component: reactContainerFactory({
        name: 'query.locationDeviceValidationTask',
      }),
      props: {
        component: () =>
          lazy(
            () =>
              import(
                '@/react-app/pages/query-center/location-device-validation-task/detail'
              )
          ),
      },
    },
  ],
};

export default queryCenter;
