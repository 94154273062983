import store from '../store';
import Vue from 'vue';
import { i18n } from '@/i18n/index';
import { pingInOrder } from './ping';
import { reportEvent } from './qms';

const POOR_TYPE = {
  NETWORK_POOR: 1,
  SERVE_POOR: 2,
};
class WeakNetworkMonitor {
  private static _instance: WeakNetworkMonitor;
  protected weakList: any = []; // 弱网的列表数据
  protected toastTime = 0; // 记录弹窗的时间戳

  protected weakNetworkSwitch(): number {
    return store.state.system.weak_network_switch || 0;
  }
  protected showTimeAgain(): number {
    return (store.state.system.weak_network_show_time_again || 30) * 60 * 1000;
  }
  protected timeThreshold(): number {
    return (store.state.system.weak_network_time_threshold || 3) * 60 * 1000;
  }
  protected countThreshold(): number {
    return store.state.system.weak_network_count_threshold || 3;
  }
  protected rttThreshold(): number {
    return store.state.system.weak_network_rtt_threshold || 300;
  }
  protected pingThreshold(): number {
    return store.state.system.weak_network_ping_threshold || 300;
  }
  protected pingDomain(): string {
    return (
      store.state.system.weak_network_ping_domain || 'https://www.google.com/'
    );
  }
  protected apiWhiteList(): string[] {
    const list = (store.state.system.weak_network_api_whiteList || '').split(
      ','
    );
    return list;
  }
  protected routerBlackList(): string[] {
    const list = (store.state.system.weak_network_router_blackList || '').split(
      ','
    );
    return list;
  }
  public static getInstance(): WeakNetworkMonitor {
    // 如果存在instance实例直接返回，如果没有，实例化后返回
    this._instance || (this._instance = new WeakNetworkMonitor());
    return this._instance;
  }

  private getWeakNetParams(): any {
    return {
      showTimeAgain_n: this.showTimeAgain(),
      timeThreshold_n: this.timeThreshold(),
      countThreshold_n: this.countThreshold(),
      rttThreshold_n: this.rttThreshold(),
      pingThreshold_n: this.pingThreshold(),
    };
  }

  public requestTime(config: any = {}): void {
    if (!this.weakNetworkSwitch()) return;
    const { url } = config;
    // 在api白名单且不在路由黑名单的情况下 记录请求的时间
    if (
      this.apiWhiteList().includes(url) &&
      !this.routerBlackList().includes(location.pathname)
    ) {
      config.headers['network-inspector-request-time'] = Date.now();
    }
    return;
  }

  public trackRttTime(response: any = {}): void {
    try {
      if (!this.weakNetworkSwitch()) return;
      const requestHeaders = response.config.headers || {};
      const responseHeader = response.headers || {};
      const startTime = requestHeaders['network-inspector-request-time'];
      if (!startTime) return response;
      const apiDuration = Math.floor(Date.now() - startTime);
      const backendDuration = Number(responseHeader['x-response-et'] || 0); // 获取后端处理时间
      const rttTime = Math.floor(apiDuration - backendDuration); // 计算网络传输的耗时
      if (rttTime >= this.rttThreshold()) {
        this.pushWeakList(rttTime, response?.config?.url);
        this.sendDataEveryPoor();
        this.showWeakNetToast();
      }
    } catch (e) {
      console.error('track main process rtt error: ' + e);
    }
    return response;
  }

  private pushWeakList(rttTime: number, url: string): void {
    const curTimestamp = Date.now();
    const timeThresholdStart = curTimestamp - this.timeThreshold();
    this.weakList.push({
      rttTime: rttTime,
      timestamp: curTimestamp,
      url: url,
    });
    this.weakList = this.weakList.filter(
      (item: any) => item.timestamp >= timeThresholdStart
    );
  }

  private async showWeakNetToast(): Promise<void> {
    const curTimestamp = Date.now();

    // 弱网次数达到阈值
    if (this.weakList.length === this.countThreshold()) {
      // 上次弹窗时间间隔小于showTimeAgain 清空列表不弹窗
      if (this.showTimeAgain() + this.toastTime >= curTimestamp) {
        this.weakList = [];
        return;
      }
      // 获取ping公网的延迟数据
      const pingData = await pingInOrder(this.pingDomain());
      const isGlobalNetworkPoor = pingData.some(
        (item) => item.data > this.pingThreshold()
      );
      this.toastTime = Date.now();
      // const link =
      //   'https://deo.shopeemobile.com/shopee/spx-fe-lib/network-diagnosis-tools/index.html?type=wms';
      if (isGlobalNetworkPoor) {
        Vue.prototype.$message({
          showClose: true,
          message: i18n(
            'Network is unstable, please inform your supervisor to reach out to the local IT team for assistance.'
          ),
          type: 'warning',
          duration: 10000,
        });
        await this.sendDataEveryToast(pingData, POOR_TYPE.NETWORK_POOR);
      } else {
        // const message =
        //   'Server is unstable, please inform your supervisor to raise a PS ticket for assistance.';
        Vue.prototype.$message({
          showClose: true,
          message: i18n(
            'Network is unstable, please inform your supervisor to reach out to the local IT team for assistance.'
          ),
          // dangerouslyUseHTMLString: true,
          // message: `<span>${message}</span><a href=${link} target="_blank"> link</a>`,
          type: 'warning',
          duration: 10000,
        });
        await this.sendDataEveryToast(pingData, POOR_TYPE.SERVE_POOR);
      }
      this.weakList = [];
    } else return;
  }

  private sendDataEveryPoor(): void {
    reportEvent('weakNetworkEveryPoor', {
      rttData_n: this.weakList.at(-1),
      weakParams_n: this.getWeakNetParams(),
    });
  }
  private sendDataEveryToast(pingDataList: any, poorType: number): void {
    reportEvent('weakNetworkEveryToast', {
      pingDataList,
      poorType: poorType,
      rttData_n: this.weakList,
      weakParams_n: this.getWeakNetParams(),
    });
  }
}

export default WeakNetworkMonitor.getInstance();
