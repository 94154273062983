import Layout from '../../views/layout/index.vue';
const move = {
  path: '/moveinbound',
  name: 'moveInbound',
  meta: {
    title: 'MT Inbound',
    icon: 'MT Inbound',
  },
  redirect: '/moveinbound/order',
  component: () => import('@/views/layout/index.vue'),
  children: [
    {
      path: 'orderasn',
      name: 'moveInbound.orderasn',
      meta: {
        title: 'Order',
      },
      redirect: '/moveinbound/order',
      component: () => import('@/views/layout/subPage.vue'),
    },
    {
      path: 'order',
      name: 'PC.MTInbound.Order.MTASN.View',
      meta: {
        menuHide: true,
        title: 'ASN',
        filterSave: true,
      },
      component: () => import('@/views/move/order/index.vue'),
    },
    {
      path: 'queuelist',
      name: 'PC.MTInbound.Order.QueueList.View',
      meta: {
        menuHide: true,
        title: 'Queue List',
        icon: '',
      },
      component: () => import('@/views/move/order/index.vue'),
    },
    {
      path: 'mtorder',
      name: 'PC.MTInbound.Order.MTOrder.View',
      meta: {
        menuHide: true,
        title: 'Move Transfer Inbound',
        icon: '',
        filterSave: true,
      },
      component: () => import('@/views/move/order/index.vue'),
    },
    {
      path: 'task',
      name: 'moveInbound.task',
      meta: {
        title: 'Task',
      },
      redirect: '/moveinbound/qctask',
      component: () => import('../../views/layout/subPage.vue'),
    },
    {
      path: 'qctask',
      name: 'moveInbound.task.qcTask',
      meta: {
        menuHide: true,
        title: 'QC Task',
        filterSave: true,
      },
      component: () =>
        import('../../views/task-center/inbound-task/qc-task/qc-task.vue'),
    },
    {
      path: 'qctask/detail',
      name: 'moveInbound.task.qcTaskDetail',
      meta: {
        title: 'QC Task Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'moveInbound',
            title: 'MT Inbound',
          },
          {
            routeName: 'moveInbound.task.qcTask',
            title: 'QC Task',
          },
        ],
      },
      component: () =>
        import(
          '../../views/task-center/inbound-task/qc-task/qc-task-detail.vue'
        ),
    },
    {
      path: 'receivetask',
      name: 'moveInbound.task.receiveTask',
      meta: {
        menuHide: true,
        title: 'Receiving Task',
        filterSave: true,
      },
      component: () =>
        import(
          '../../views/task-center/inbound-task/receive-task/receive-task.vue'
        ),
    },
    {
      path: 'receivetaskdetail',
      name: 'moveInbound.task.receiveTaskDetail',
      meta: {
        title: 'Receiving Task Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'moveInbound',
            title: 'MT Inbound',
          },
          {
            routeName: 'moveInbound.task.receiveTask',
            title: 'Receiving Task',
          },
        ],
      },
      component: () =>
        import(
          '../../views/task-center/inbound-task/receive-task/receive-task-detail.vue'
        ),
    },
    {
      path: 'putawaytask',
      name: 'moveInbound.task.putawayTask',
      meta: {
        title: 'Putaway Task',
        menuHide: true,
        filterSave: true,
        navList: [
          {
            routeName: 'moveInbound',
            title: 'MT Inbound',
          },
        ],
      },
      component: () =>
        import(
          '../../views/task-center/inbound-task/putaway-task/move-inbound/putaway-task.vue'
        ),
    },
    {
      path: 'putawaytask/edit',
      name: 'moveInbound.task.putawayTask.edit',
      meta: {
        title: 'Putaway Task Edit',
        menuHide: true,
        navList: [
          {
            routeName: 'moveInbound',
            title: 'MT Inbound',
          },
          {
            routeName: 'moveInbound.task.putawayTask',
            title: 'Putaway Task',
          },
        ],
      },
      component: () =>
        import(
          '../../views/task-center/inbound-task/putaway-task/move-inbound/putaway-task-detail.vue'
        ),
    },
    {
      path: 'putawaytask/detail',
      name: 'moveInbound.task.putawayTask.detail',
      meta: {
        title: 'Putaway Task Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'moveInbound',
            title: 'MT Inbound',
          },
          {
            routeName: 'moveInbound.task.putawayTask',
            title: 'Putaway Task',
          },
        ],
      },
      component: () =>
        import(
          '../../views/task-center/inbound-task/putaway-task/move-inbound/putaway-task-detail.vue'
        ),
    },
    // {
    //   path: 'arrivingtask',
    //   name: 'moveInbound.task.arrivingTask',
    //   meta: {
    //     menuHide: true,
    //     title: 'Arriving Task',
    //     filterSave: true,
    //   },
    //   component: () =>
    //     import('../../views/task-center/inbound-task/arriving-task/index.vue'),
    // },
    // {
    //   path: 'arrivingtask/detail/:id',
    //   name: 'moveInbound.task.arrivingTask.detail',
    //   meta: {
    //     title: 'Arriving Task Detail',
    //     menuHide: true,
    //     navList: [
    //       {
    //         routeName: 'moveInbound',
    //         title: 'MT Inbound',
    //       },
    //       {
    //         routeName: 'moveInbound.task.arrivingTask',
    //         title: 'Arriving Task',
    //       },
    //     ],
    //   },
    //   component: () =>
    //     import('../../views/task-center/inbound-task/arriving-task/detail.vue'),
    // },
    {
      path: '/move/arrving',
      name: 'moveInbound.arriving',
      meta: {
        title: 'Arriving',
        icon: '',
      },
      component: () => import('../../views/move/arriving/index.vue'),
    },
    {
      path: 'mtqc',
      name: 'moveInbound.qc',
      meta: {
        title: 'QC',
        icon: '',
      },
      component: () => import('../../views/move/qc/index.vue'),
    },
    {
      path: 'mtreceiving',
      name: 'moveInbound.receiving',
      meta: {
        title: 'Receiving',
        icon: '',
      },
      component: () => import('../../views/move/receiving/receiving.vue'),
    },
    {
      path: '/moveinbound/order/detail/:id',
      name: 'moveInbound.order.detail',
      meta: {
        icon: '',
        title: 'ASN Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'moveInbound',
            title: 'Move',
          },
          {
            routeName: 'PC.MTInbound.Order.MTASN.View',
            title: 'Move Transfer Inbound',
          },
        ],
      },
      component: () => import('@/views/move/order/views/mt-asn-view.vue'),
    },
    {
      path: '/moveinbound/mtorder/detail/:id',
      name: 'moveInbound.mtorder.detail',
      meta: {
        icon: '',
        title: 'MT Order Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'moveInbound',
            title: 'Move',
          },
          {
            routeName: 'PC.MTInbound.Order.MTOrder.View',
            title: 'Move Transfer Inbound',
          },
        ],
      },
      component: () => import('@/views/move/order/views/mt-order-view.vue'),
    },
    {
      path: 'mtstandardputaway',
      name: 'moveInbound.standardPutaway',
      meta: {
        title: 'Standard Putaway',
        icon: '',
      },
      component: () =>
        import('../../views/move/standard-putaway/standard-putaway.vue'),
    },
    {
      path: 'mtmassputaway',
      name: 'moveInbound.mtmassPutaway',
      meta: {
        title: 'Mass Putaway',
        icon: '',
      },
      component: () => import('../../views/move/mass-putaway/mass-putaway.vue'),
    },
  ],
};

export default move;
