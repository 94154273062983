import DashboardTVComponent from '../../views/dashboard-tv-new/index.vue';

const dashboardTV = {
  path: '/dashboardTvNew',
  name: 'dashboardTvNew',
  meta: {
    title: 'DashboardTV',
  },
  component: DashboardTVComponent,
  children: [
    {
      path: '/dashboardTvNew/inboundLeadTime',
      name: 'dashboardTvNew.inboundLeadTime',
      meta: {
        title: 'DashboardTVInboundLeadTime',
        menuHide: true,
      },
      component: () =>
        import(
          '../../views/dashboard-tv-new/productivity-efficiency/lead-time-inbound.vue'
        ),
    },
    {
      path: '/dashboardTvNew/outboundLeadTime',
      name: 'dashboardTvNewNew.outboundLeadTime',
      meta: {
        title: 'DashboardTVOutboundLeadTime',
        menuHide: true,
      },
      component: () =>
        import(
          '../../views/dashboard-tv-new/productivity-efficiency/lead-time-outbound.vue'
        ),
    },
    {
      path: '/dashboardTvNew/inboundInProgress',
      name: 'dashboardTvNew.inboundInProgress',
      meta: {
        title: 'DashboardTVInboundInProgress',
        menuHide: true,
      },
      component: () =>
        import('../../views/dashboard-tv-new/task-tracking/in-progress.vue'),
    },
    {
      path: '/dashboardTvNew/outboundInProgress',
      name: 'dashboardTvNew.outboundInProgress',
      meta: {
        title: 'DashboardTVOutboundInProgress',
        menuHide: true,
      },
      component: () =>
        import('../../views/dashboard-tv-new/task-tracking/in-progress.vue'),
    },
  ],
};

export default dashboardTV;
