import Vue from 'vue';
import { MutationPayload } from 'vuex';
import Store from '@/store';
import VueRouter from 'vue-router';
import { i18n } from '@/i18n/index';
import {
  CYCLE_COUNT_ROUTE_NAME,
  FULL_STOCK_COUNT_ROUTE_NAME,
  DASHBOARD_CYCLE_COUNT_ROUTE_NAME,
  DASHBOARD_FULL_STOCK_COUNT_ROUTE_NAME,
} from './constant';

function replaceCycleCountRoute(
  routeName: string,
  state: any,
  router: VueRouter,
  needToast: boolean
) {
  const {
    cycleCount: { isFullStockCount },
  } = state;
  // 全仓盘点模式下，在普通盘点的路由需要调整到 全仓盘点 路由
  if (
    isFullStockCount &&
    (routeName?.match(CYCLE_COUNT_ROUTE_NAME) ||
      routeName?.match(DASHBOARD_CYCLE_COUNT_ROUTE_NAME))
  ) {
    if (needToast) {
      const text = i18n('Full Stock Count already start');
      Vue.prototype.$message.info(text, true);
    }
    router.replace({ name: FULL_STOCK_COUNT_ROUTE_NAME });
    return;
  }
  // 普通盘点模式下，在全仓盘点的路由需要调整到 普通盘点 路由
  if (
    !isFullStockCount &&
    (routeName?.match(FULL_STOCK_COUNT_ROUTE_NAME) ||
      routeName?.match(DASHBOARD_FULL_STOCK_COUNT_ROUTE_NAME))
  ) {
    if (needToast) {
      const text = i18n('Full Stock Count already end');
      Vue.prototype.$message.info(text, true);
    }
    router.replace({ name: CYCLE_COUNT_ROUTE_NAME });
    return;
  }
}

export function subscribeCCRouterCheck(router: VueRouter) {
  function handleCycleCountSubsribe(
    mutation: MutationPayload,
    state: any,
    routeName: string
  ) {
    const { type, payload } = mutation;
    // 触发 loadOpenFullStockCountSign 时合法情况需要展示 info toast
    const needToast = payload?.from === 'loadOpenFullStockCountSign';
    if (type === 'setFullCycleCountFlag') {
      replaceCycleCountRoute(routeName, state, router, needToast);
    }
  }

  let unSubsribeCycleCount = () => {
    //
  };

  router.beforeEach((to, from, next) => {
    const routeName = to.name;
    // 下面路由都注册事件
    if (
      routeName?.match(CYCLE_COUNT_ROUTE_NAME) ||
      routeName?.match(FULL_STOCK_COUNT_ROUTE_NAME) ||
      routeName?.match(DASHBOARD_CYCLE_COUNT_ROUTE_NAME) ||
      routeName?.match(DASHBOARD_FULL_STOCK_COUNT_ROUTE_NAME)
    ) {
      unSubsribeCycleCount && unSubsribeCycleCount();
      unSubsribeCycleCount = Store.subscribe((mutation, state) =>
        handleCycleCountSubsribe(mutation, state, routeName)
      );
    }
    next();
  });
}
