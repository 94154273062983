export const PAGE_SIZE = 20;

export const PAGE_SIZES = [20, 50, 100, 200];
export const DEFAULT_NULL_OR_EMPTY_STRING = '-';
export const PRINT_DEVICE_LOCAL_STORAGE_KEY = 'printer_name';

export const DATE_FORMAT_OPTIONS = [
  'DD/MM/YYYY',
  'MM/DD/YYYY',
  'YYYY/MM/DD',
] as const;

// YES or NO Type
export const YES_OR_NO_TYPE = {
  YES: 1,
  NO: 0,
};
export const Y_OR_N_TYPE = {
  Y: 1,
  N: 0,
};
export const YES_SELECT = 1;
export const NO_SELECT = 0;

export const IS_UNIT_MANAGE = 1;

export const MAX_COUNT = 99999;
