import { cloneDeep } from 'lodash';
import ReactContainer from './index.vue';

const reactContainerFactory = ({ name }: Record<string, any>) => {
  const Com = cloneDeep(ReactContainer);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Component.name is a read-only property, modify it for debugging in console
  Com.name = `ReactContainer-${name}`;
  return Com;
};

export default reactContainerFactory;
