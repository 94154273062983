import { checkAndHandleCycleCountErrcode } from '../../../views/inventory-management/full-stock-count/utils';
import _request from '@/utils/request';

type GetRequestParamType = Parameters<typeof _request.get>;
type PostRequestParamType = Parameters<typeof _request.post>;

const requestProxy = {
  get: async (...params: GetRequestParamType) => {
    try {
      const res = await _request.get(...params);
      return res;
    } catch (e) {
      checkAndHandleCycleCountErrcode((e as any).retcode);
      throw e;
    }
  },
  post: async (...params: PostRequestParamType) => {
    try {
      const res = await _request.post(...params);
      return res;
    } catch (e) {
      checkAndHandleCycleCountErrcode((e as any).retcode);
      throw e;
    }
  },
};

export default requestProxy;
