import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import routesConfig from '../../router/config';
import { childrenToParent } from '../../utils/menu';
import { getWhsStoreyNameSetup } from '@/api/wms-basic/storey';

@Module
class TabMenu extends VuexModule {
  tabMenuMap: any = {};
  storeyShow = 0;

  @Mutation
  setTabMenu(data: any): void {
    this.tabMenuMap = data;
  }
  // set storey tab
  @Mutation
  setStoreyTab(data: any): void {
    this.storeyShow = data.showStorey;
  }

  @Action({ commit: 'setTabMenu' })
  async loadTabMenu() {
    try {
      const m = childrenToParent(routesConfig);
      return m;
    } catch (err) {
      return {};
    }
  }
  // storage area setting tab permission - storey
  @Action({ commit: 'setStoreyTab' })
  async loadShowStoreyTab() {
    try {
      const { data } = await getWhsStoreyNameSetup();
      return {
        showStorey: data.storey_name_setup || 0,
      };
    } catch (err) {
      return false;
    }
  }
}
export default TabMenu;
