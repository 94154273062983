import Vue from 'vue';
import SvgIcon from '@/components/svgIcon';

// register globally
Vue.component('svg-icon', SvgIcon);

if (!process.env.IS_VITE) {
  const requireAll = (requireContext: any) =>
    requireContext.keys().map(requireContext);
  const req = require.context('./svg', false, /\.svg$/);
  requireAll(req);
}
