import request from './utils/cycle-count-proxy-request';
import { MSG_ARR_RETCODE_LIST } from '@/views/inventory-management/cycle-count/constant';

interface InterfaceCancelCcOrder {
  cc_order_id?: string;
}

export const cancelCcOrder = async (params: InterfaceCancelCcOrder) => {
  const res = await request.post(
    '/api/v2/apps/process/inventory/cyclecount/ccorder/cancel_cc_order',
    {
      ...params,
    }
  );
  return res;
};

interface InterfaceCcGetAllPathwayInfo {
  zone_id?: string;
}

export const ccGetAllPathwayInfo = async (
  params: InterfaceCcGetAllPathwayInfo
) => {
  const res = await request.get(
    '/api/v2/apps/process/inventory/cyclecount/ccorder/cc_get_all_pathway_info',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceGetParkZoneTree {
  whs_id?: number;
  park_data_type?: number;
}
/* PermissionKey: ALL */
export const getParkZoneTree = async (params: InterfaceGetParkZoneTree) => {
  const res = await request.get(
    '/api/v2/apps/basic/location/get_park_zone_tree',
    {
      params: {
        park_data_type: 1,
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceCreateCcOrder {
  sku_location_create_list?: object;
  cc_type?: number;
  cc_operate_function?: number;
  cc_task_stage?: number;
  is_blind_count?: number;
  is_dynamic_count?: number;
  cc_method?: number;
  is_one_by_one?: number;
  source_from?: number;
  source_id?: string;
  is_include_empty_location?: number;
}

export const createCcOrder = async (params: InterfaceCreateCcOrder) => {
  const res = await request.post(
    '/api/v2/apps/process/inventory/cyclecount/ccorder/create_cc_order',
    {
      ...params,
    },
    {
      noErrorCode: MSG_ARR_RETCODE_LIST,
    }
  );
  return res;
};

interface InterfaceGetCcOrderDetail {
  cc_order_id?: string;
}

export const getCcOrderDetail = async (params: InterfaceGetCcOrderDetail) => {
  const res = await request.get(
    '/api/v2/apps/process/inventory/cyclecount/ccorder/get_cc_order_detail',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceGetCcOrderDetailCcTaskList {
  cc_order_id?: string;
  count?: number;
  pageno?: number;
}

export const getCcOrderDetailCcTaskList = async (
  params: InterfaceGetCcOrderDetailCcTaskList
) => {
  const res = await request.get(
    '/api/v2/apps/process/inventory/cyclecount/ccorder/get_cc_order_detail_cc_task_list',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceGetCcOrderDetailLog {
  cc_order_id?: string;
  count?: number;
  pageno?: number;
}

export const getCcOrderDetailLog = async (
  params: InterfaceGetCcOrderDetailLog
) => {
  const res = await request.get(
    '/api/v2/apps/process/inventory/cyclecount/ccorder/get_cc_order_detail_log',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceGetCcOrderDetailSkuLocationBatch {
  cc_order_id?: string;
  sku_id?: string;
  location_id?: string;
  count?: number;
  pageno?: number;
}

export const getCcOrderDetailSkuLocationBatch = async (
  params: InterfaceGetCcOrderDetailSkuLocationBatch
) => {
  const res = await request.get(
    '/api/v2/apps/process/inventory/cyclecount/ccorder/get_cc_order_detail_sku_location_batch',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceGetCcOrderDetailSkuLocationList {
  cc_order_id?: string;
  count?: number;
  pageno?: number;
}

export const getCcOrderDetailSkuLocationList = async (
  params: InterfaceGetCcOrderDetailSkuLocationList
) => {
  const res = await request.get(
    '/api/v2/apps/process/inventory/cyclecount/ccorder/get_cc_order_detail_sku_location_list',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceGetCcOrderDetailSkuLocationUid {
  cc_order_id?: string;
  sku_id?: string;
  location_id?: string;
  count?: number;
  pageno?: number;
}

export const getCcOrderDetailSkuLocationUid = async (
  params: InterfaceGetCcOrderDetailSkuLocationUid
) => {
  const res = await request.get(
    '/api/v2/apps/process/inventory/cyclecount/ccorder/get_cc_order_detail_sku_location_uid',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceSkuCcSearchSku {
  exclude_sku_location_list?: object;
  source_from?: number;
  source_id?: string;
  shop_id?: number;
  purchase_type?: number;
  supplier_id?: string;
  sku_management?: number;
  sku_id?: string;
  is_include_empty_location?: number;
  zone_id?: string;
  pathway_id?: string;
  segment_from?: string;
  segment_to?: string;
  location_id?: string;
  count?: number;
  pageno?: number;
}

export const skuCcSearchSku = async (params: InterfaceSkuCcSearchSku) => {
  const res = await request.post(
    '/api/v2/apps/process/inventory/cyclecount/ccorder/sku_cc_search_sku',
    {
      ...params,
    }
  );
  return res;
};

interface InterfaceLocationCcSearchConfirm {
  confirm_list?: object;
  is_dynamic_count?: number;
  is_include_empty_location?: number;
  cc_operate_function?: number;
}

export const locationCcSearchConfirm = async (
  params: InterfaceLocationCcSearchConfirm
) => {
  const res = await request.post(
    '/api/v2/apps/process/inventory/cyclecount/ccorder/location_cc_search_confirm',
    {
      ...params,
    },
    {
      noErrorCode: MSG_ARR_RETCODE_LIST,
    }
  );
  return res;
};

interface InterfaceLocationCcSearchLocation {
  exclude_sku_location_list?: object;
  zone_id?: string;
  pathway_id?: string;
  segment_from?: string;
  segment_to?: string;
  location_id?: string;
  is_counted_in_current_quarter?: number;
  is_include_empty_location?: number;
  qty_left_from?: number;
  qty_left_to?: number;
  count?: number;
  pageno?: number;
}

export const locationCcSearchLocation = async (
  params: InterfaceLocationCcSearchLocation
) => {
  const res = await request.post(
    '/api/v2/apps/process/inventory/cyclecount/ccorder/location_cc_search_location',
    {
      ...params,
    }
  );
  return res;
};

interface InterfaceMovementCcSearchConfirm {
  confirm_list?: object;
  is_dynamic_count?: number;
  is_location_movement?: number;
  location_confirm_list?: object;
}

export const movementCcSearchConfirm = async (
  params: InterfaceMovementCcSearchConfirm
) => {
  const res = await request.post(
    '/api/v2/apps/process/inventory/cyclecount/ccorder/movement_cc_search_confirm',
    {
      ...params,
    },
    {
      noErrorCode: MSG_ARR_RETCODE_LIST,
    }
  );
  return res;
};

interface InterfaceMovementCcSearchSku {
  exclude_sku_location_list?: object;
  operate_time_from?: number;
  operate_time_to?: number;
  qty_left_from?: number;
  qty_left_to?: number;
  zone_id?: string;
  pathway_id?: string;
  segment_from?: string;
  segment_to?: string;
  location_id?: string;
  cc_operate_function?: number;
  is_location_movement?: number;
  count?: number;
  pageno?: number;
}

export const movementCcSearchSku = async (
  params: InterfaceMovementCcSearchSku
) => {
  const res = await request.post(
    '/api/v2/apps/process/inventory/cyclecount/ccorder/movement_cc_search_sku',
    {
      ...params,
    }
  );
  return res;
};

interface InterfaceSearchCcOrder {
  cc_order_id?: string;
  source_from?: number;
  source_id?: string;
  cc_type?: number;
  cc_operate_function?: number;
  cc_order_status?: number;
  sku_id?: string;
  create_time_from?: number;
  create_time_to?: number;
  finish_time_from?: number;
  finish_time_to?: number;
  cc_task_id?: string;
  count?: number;
  pageno?: number;
}

export const searchCcOrder = async (params: InterfaceSearchCcOrder) => {
  const res = await request.get(
    '/api/v2/apps/process/inventory/cyclecount/ccorder/search_cc_order',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

// interface InterfaceSkuCcGetUploadTemplate {}

export const skuCcGetUploadTemplate = async (params: any) => {
  const res = await request.get(
    '/api/v2/apps/process/inventory/cyclecount/ccorder/sku_cc_get_upload_template',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceSkuCcSearchConfirm {
  confirm_list?: object;
  is_dynamic_count?: number;
}

export const skuCcSearchConfirm = async (
  params: InterfaceSkuCcSearchConfirm
) => {
  const res = await request.post(
    '/api/v2/apps/process/inventory/cyclecount/ccorder/sku_cc_search_confirm',
    {
      ...params,
    },
    {
      noErrorCode: MSG_ARR_RETCODE_LIST,
    }
  );
  return res;
};

interface InterfaceSkuCcSearchSupplierFuzzy {
  supplier_id?: string;
  count?: number;
  pageno?: number;
}

export const skuCcSearchSupplierFuzzy = async (
  params: InterfaceSkuCcSearchSupplierFuzzy
) => {
  const res = await request.get(
    '/api/v2/apps/process/inventory/cyclecount/ccorder/sku_cc_search_supplier_fuzzy',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceSkuCcUploadSku {
  exclude_sku_location_list?: string;
  excel_file?: object;
  is_dynamic_count?: number;
}

export const skuCcUploadSku = async (params: InterfaceSkuCcUploadSku) => {
  const res = await request.post(
    '/api/v2/apps/process/inventory/cyclecount/ccorder/sku_cc_upload_sku',
    {
      ...params,
    }
  );
  return res;
};

export const getCycleCountConfig = async () => {
  const res = await request.get(
    '/api/v2/apps/process/inventory/cyclecount/get_cycle_count_config',
    {
      params: {},
    }
  );
  return res;
};

export const getWhsIsSkuMapping = async () => {
  const res = await request.get(
    '/api/v2/apps/process/inventory/cyclecount/ccorder/get_whs_is_sku_mapping',
    {
      params: {},
    }
  );
  return res;
};

export const getCCSkuLimit = async () => {
  const res = await request.get(
    '/api/v2/apps/process/inventory/cyclecount/get_cycle_count_sku_location_limit_config',
    {
      params: {},
    }
  );
  return res;
};

interface InterfaceGetSkuCCUploadSkuHistoryList {
  data_type_in?: number[];
  relation_id?: number | string;
  pageno?: number;
  count?: number;
}

export const getSkuCCUploadSkuHistoryList = async (
  params: InterfaceGetSkuCCUploadSkuHistoryList
) => {
  return await request.post(
    /* PermissionKey: ALL */
    '/api/v2/apps/basic/importdata/search_import_data_task',
    params
  );
};
