import request from '@/utils/request';

interface InterfaceCheckArrivalGroupOrNot {
  accept_list: string;
}

export const checkArrivalGroupOrNot = async (
  params: InterfaceCheckArrivalGroupOrNot
) => {
  const res = await request.post(
    '/api/v2/apps/process/inbound/transferinbound/trarrival/check_arrival_group_or_not',
    {
      ...params,
    }
  );
  return res;
};

interface InterfaceCompleteArrival {
  inbound_id: string;
  dock_no_list: string[];
  license_plate_num_list: string[];
  transporter_name_list: string[];
  seal_num_list: string[];
}

export const completeArrival = async (params: InterfaceCompleteArrival) => {
  const res = await request.post(
    '/api/v2/apps/process/inbound/transferinbound/trarrival/complete_arrival',
    {
      ...params,
    }
  );
  return res;
};

interface InterfaceCreateAcceptRejectArrival {
  dock_no_list: string;
  accept_list: string;
  reject_list: string;
  license_plate_num_list: string;
  transporter_name_list: string;
  is_group: number;
  seal_num_list: string;
}

export const createAcceptRejectArrival = async (
  params: InterfaceCreateAcceptRejectArrival
) => {
  const res = await request.post(
    '/api/v2/apps/process/inbound/transferinbound/trarrival/create_accept_reject_arrival',
    {
      ...params,
    }
  );
  return res;
};

interface InterfaceCreateArrival {
  sheet_id: string;
}

export const createArrival = async (params: InterfaceCreateArrival) => {
  const res = await request.post(
    '/api/v2/apps/process/inbound/transferinbound/trarrival/create_arrival',
    {
      ...params,
    }
  );
  return res;
};

export const getArrivalConfig = async () => {
  const res = await request.get(
    '/api/v2/apps/process/inbound/transferinbound/trarrival/get_arrival_config',
    {
      params: {
        random: window.crypto.getRandomValues(new Uint32Array(1))[0],
      },
    }
  );
  return res;
};

interface InterfaceGetAsnPalletInfo {
  inbound_id: string;
  pageno?: number;
  count?: number;
}

export const getAsnPalletInfo = async (params: InterfaceGetAsnPalletInfo) => {
  const res = await request.get(
    '/api/v2/apps/process/inbound/transferinbound/trarrival/get_asn_pallet_info',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceSaveArrivalMtb {
  inbound_id: string;
  mtb_id: string;
  mtb_quality: number;
  remark: string;
}

export const saveArrivalMtb = async (params: InterfaceSaveArrivalMtb) => {
  const res = await request.post(
    '/api/v2/apps/process/inbound/transferinbound/trarrival/save_arrival_mtb',
    {
      ...params,
    }
  );
  return res;
};

interface InterfaceScanArrivalMtb {
  inbound_id: string;
  mtb_id: string;
}

export const scanArrivalMtb = async (params: InterfaceScanArrivalMtb) => {
  const res = await request.post(
    '/api/v2/apps/process/inbound/transferinbound/trarrival/scan_arrival_mtb',
    {
      ...params,
    }
  );
  return res;
};

interface InterfaceScanArrivalSheetId {
  sheet_id: string;
}

export const scanArrivalSheetId = async (
  params: InterfaceScanArrivalSheetId
) => {
  const res = await request.post(
    '/api/v2/apps/process/inbound/transferinbound/trarrival/scan_arrival_sheet_id',
    {
      ...params,
    }
  );
  return res;
};

interface InterfaceSearchCheckedArrivalMtb {
  inbound_id: string;
  pageno?: number;
  count?: number;
}

export const searchCheckedArrivalMtb = async (
  params: InterfaceSearchCheckedArrivalMtb
) => {
  const res = await request.get(
    '/api/v2/apps/process/inbound/transferinbound/trarrival/search_checked_arrival_mtb',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceSearchExpectedArrivalMtb {
  inbound_id: string;
  pageno?: number;
  count?: number;
}

export const searchExpectedArrivalMtb = async (
  params: InterfaceSearchExpectedArrivalMtb
) => {
  const res = await request.get(
    '/api/v2/apps/process/inbound/transferinbound/trarrival/search_expected_arrival_mtb',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceSearchMissingArrivalMtb {
  inbound_id: string;
  pageno?: number;
  count?: number;
}

export const searchMissingArrivalMtb = async (
  params: InterfaceSearchMissingArrivalMtb
) => {
  const res = await request.get(
    '/api/v2/apps/process/inbound/transferinbound/trarrival/search_missing_arrival_mtb',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceUpdateArrivalMtb {
  inbound_id: string;
  mtb_id: string;
  mtb_quality: number;
  remark: string;
}

export const updateArrivalMtb = async (params: InterfaceUpdateArrivalMtb) => {
  const res = await request.post(
    '/api/v2/apps/process/inbound/transferinbound/trarrival/update_arrival_mtb',
    {
      ...params,
    }
  );
  return res;
};
