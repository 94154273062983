import capitalize from '@/utils/capitalize';
import { enumsName } from '@/utils/safeGet';
import { CreateElement, VNode } from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Tag } from 'ssc-ui-vue';
import { getDefaultStatusTypeByLabel, DEFAULT_STATUS_TYPE } from './utils';

export type SettingItem = {
  label: string;
  value: string | number;
};

export interface StatusMap {
  default: SettingItem[];
  primary: SettingItem[];
  success: SettingItem[];
  warning: SettingItem[];
  danger: SettingItem[];
  info: SettingItem[];
}

@Component
export default class StatusTag extends Tag {
  @Prop({
    default: () => ({
      default: [],
      primary: [],
      success: [],
      warning: [],
      danger: [],
      info: [],
    }),
  })
  statusMap: StatusMap;

  @Prop() statusValue: string | number; // 当前的 status value
  @Prop() statusEnumsKey: string; // 后端给 enums key
  @Prop({ default: true }) isAutoMap: boolean; // 是否通过label自动匹配获取status type, 忽略 statusMap
  @Prop() prefixLabel: string;
  @Prop({ default: false }) ignoreFormatLabel: boolean;

  @Watch('statusValue', { immediate: true })
  onStatusChange() {
    this.setTypeMapValue();
  }

  statusText = ''; // Tag innerHtml text
  statusType = DEFAULT_STATUS_TYPE; // Tag type

  setTypeMapValue() {
    const { statusValue, statusMap, statusEnumsKey, isAutoMap } = this;
    // 根据 当前 statusValue 返回的 label name 查找 statusType
    if (isAutoMap) {
      this.statusText = enumsName(statusEnumsKey, statusValue) || '';
      this.statusType = getDefaultStatusTypeByLabel(this.statusText);
      return;
    }
    Object.entries(statusMap).find(([typeKey = '', typeValues = []]) => {
      const result = typeValues.find((item: SettingItem) => {
        if (typeof item !== 'object') {
          if (item === statusValue) {
            // 根据 value 查找 枚举里的 keyName
            this.statusText = enumsName(statusEnumsKey, statusValue) || '';
            this.statusType = typeKey;
          }
        } else {
          // 自定义 label 和 value
          if (item['value'] === statusValue) {
            this.statusText = this.ignoreFormatLabel
              ? item['label'] || ''
              : capitalize(item['label'] || '');
            this.statusType = typeKey;
            return true;
          }
        }
      });
      return !!result;
    });
  }

  render(h: CreateElement): VNode {
    const { statusType, statusText } = this;
    // return <s-tag type={statusType} {...this.$props} {...this.$attrs} {...this.$listeners}>{this.$scopedSlots || statusText}</s-tag>;
    return h(
      's-tag',
      {
        props: {
          ...this.$props,
          type: statusType,
        },
        attrs: this.$attrs,
        on: this.$listeners,
        scopedSlots: this.$scopedSlots,
      },
      this.prefixLabel ? this.prefixLabel + statusText : statusText
    );
  }
}
