import SCustomHooks from './index';
import { CreateElement } from 'vue/types/umd';
import { ActionsOrCustomHooks } from '../../interface';
import { i18n } from '@/i18n/index';

export default function (self: SCustomHooks, h: CreateElement) {
  const customHooks: Array<ActionsOrCustomHooks> = self.customHooks;
  return customHooks.map((item: ActionsOrCustomHooks) => {
    return h(
      's-button',
      {
        class: 'custom-hooks-button',
        props: {
          type: 'primary',
        },
        on: {
          click: () => {
            item.handler.call(self);
          },
        },
      },
      [i18n(item.label)]
    );
  });
}
