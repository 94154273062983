import * as core from '@scfe-common/core';
import { getEnv } from './getEnv';
import { getCountry } from './getCountry';

// const addLeadingZero = (t: number) => (t < 10 ? '0' : '') + t.toString();
// function formatTime(date: Date) {
//   const year: string = addLeadingZero(date.getFullYear());
//   const month: string = addLeadingZero(date.getMonth() + 1);
//   const day: string = addLeadingZero(date.getDate());
//   const hour: string = addLeadingZero(date.getHours());
//   const minute: string = addLeadingZero(date.getMinutes());
//   const second: string = addLeadingZero(date.getSeconds());
//   const milliSeconds: number = date.getMilliseconds();
//   return `${year}-${month}-${day} ${hour}:${minute}:${second}.${milliSeconds}`;
// }

const reportURL =
  getEnv() === 'live'
    ? 'https://autobahn.ssc.shopeemobile.com/data/'
    : 'https://autobahn.ssc.test.shopeemobile.com/data/';

// QMS\DAS
const {
  storage: { cookie },
  request,
} = core;
const basicData = {
  bt: 'SBS',
  sbt: 'WMSV2',
  ct: Math.floor(Date.now() / 1000), // 客户端时间
  env: getEnv(),
  // d: {
  //   log: 'default log',
  // },
  dt: 'code_log',
  uid: cookie.get('wms_display_name') || '',
  ut: process.env.VUE_APP_VERSION + '',
  r: 1, //采样率，非必须
  s: location.href,
  sid: core.user.session.get() || '',
  a: getCountry() || '',
  pf: /mobile/i.test(navigator.userAgent) ? 'm' : 'pc',
};

// 日志类型集合
const types = [
  'verbose',
  'debug',
  'info',
  'warn',
  'error',
  'liveDebug',
  'assert',
];
// 日志等级，只上报高于该等级的日志
const level = 'error';
const all = (type: string, message: string) => {
  if (getEnv() === 'dev') {
    return;
  }
  if (types.indexOf(type) < types.indexOf(level)) return;
  const logMessage = `[${type.toUpperCase()}] - ${message}`;
  const data = { ...basicData, d: { log: logMessage, type } };
  return request
    .post(reportURL, data, { _is_report: true } as any)
    .catch((err: any) => {
      console.log('logger error', err);
    });
};

const verbose = (message: string) => all('verbose', message);
const debug = (message: string) => all('debug', message);
const info = (message: string) => all('info', message);
const warn = (message: string) => all('warn', message);
const error = (message: string) => all('error', message);
const liveDebug = (message: string) => all('liveDebug', message);
const assert = (message: string) => all('assert', message);

export default {
  verbose,
  debug,
  info,
  warn,
  error,
  liveDebug,
  assert,
};
