<template>
  <div>
    <template v-for="item in menuList">
      <s-submenu
        :key="item.name"
        v-if="item.children && item.children.length > 0"
        :index="item.name"
        :name="item.name"
      >
        <template slot="title">
          <svg-icon
            style="width: 16px; height: 16px"
            v-if="item.meta.icon"
            :icon-class="item.meta.icon"
            class=""
          />
          <span class="ellipsis-item">{{ $i18n(item.meta.title) }}</span>
        </template>
        <template v-for="child in item.children">
          <!-- 单独控制 cycle-count 与 full-stock-count 的 menu 展示 -->
          <template v-if="cycleCountMainMenu.includes(child.name)">
            <CycleCountMenu
              :key="child.name"
              :child="child"
              :openLink="openLink"
            />
          </template>
          <template v-else-if="dashCycleCountMainMenu.includes(child.name)">
            <CycleCountDashMenuItem
              :key="child.name"
              :item="child"
              :openLink="openLink"
            />
          </template>
          <s-submenu
            :key="child.name"
            v-else-if="child.children && child.children.length > 0"
            :index="child.name"
            :name="child.name"
          >
            <template slot="title">
              <span class="ellipsis-item">{{ $i18n(child.meta.title) }}</span>
            </template>
            <template v-for="thirdChild in child.children">
              <template v-if="ASN_QUOTA_CONFIG_ROUTE_NAME === thirdChild.name">
                <AsnQuotaConfigMenu :key="thirdChild.name" :item="thirdChild" />
              </template>
              <s-menu-item
                v-else
                :key="thirdChild.name"
                :name="thirdChild.name"
                :index="thirdChild.index"
                @click.native="openLink(thirdChild.name)"
              >
                <router-link
                  class="ellipsis-item menu-item"
                  :to="{ name: thirdChild.name }"
                  >{{ $i18n(thirdChild.meta.title) }}</router-link
                >
              </s-menu-item>
            </template>
          </s-submenu>
          <s-menu-item
            :key="child.name"
            v-else
            :name="child.name"
            :index="child.index"
            @click.native="openLink(child.name)"
          >
            <router-link
              class="ellipsis-item menu-item"
              :to="{ name: child.name }"
              >{{ $i18n(child.meta.title) }}</router-link
            >
          </s-menu-item>
        </template>
      </s-submenu>
      <s-menu-item
        :key="item.name"
        v-else
        :name="item.name"
        :index="item.index"
        @click.native="openLink(item.name)"
      >
        <router-link
          class="ellipsis-item menu-item"
          :to="{ name: item.name }"
          >{{ $i18n(item.meta.title) }}</router-link
        >
      </s-menu-item>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { errorReport } from '@/utils/tools';
import Store from '@/store';
import CycleCountMenu from './cycle-count-menu.vue';
import CycleCountDashMenuItem from './cycle-count-dash-menu-item.vue';
import AsnQuotaConfigMenu from './asn-quota-config-menu.vue';
import {
  cycleCountMainMenuRouteName,
  dashboardCycleCountMainMenuRouteName,
  ASN_QUOTA_CONFIG_ROUTE_NAME,
} from '@/router/constant';

@Component({
  name: 'Menu',
  components: {
    CycleCountMenu,
    CycleCountDashMenuItem,
    AsnQuotaConfigMenu,
  },
})
export default class Menu extends Vue {
  @Prop(Array) readonly menuList: [] | undefined;
  /* 单独控制 cycle-count 与 full-stock-count 的 menu 展示 */
  cycleCountMainMenu = cycleCountMainMenuRouteName;
  dashCycleCountMainMenu = dashboardCycleCountMainMenuRouteName;
  ASN_QUOTA_CONFIG_ROUTE_NAME = ASN_QUOTA_CONFIG_ROUTE_NAME;

  openLink(name: string) {
    try {
      if (name === this.$route.name) {
        return;
      }
      // 切换菜单去掉各种筛选条件
      const preName = this.$route.name;
      if (preName) {
        const pageParams: any = {
          [preName]: {
            pageSize: 20,
            page: 1,
          },
        };
        const filterParams: any = {
          [preName]: {},
        };
        Store.dispatch('getPageOptions', pageParams);
        Store.dispatch('getFilterOptions', filterParams);
        Store.dispatch('getSortOptions', filterParams);
      }
    } catch (e) {
      errorReport(e);
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .ellipsis-item {
  overflow: hidden;
  width: 160px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::v-deep .menu-item {
  color: rgba(255, 255, 255, 0.6);

  &:hover {
    color: rgb(255, 255, 255);
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
  }
}

::v-deep {
  .ssc-menu-item-selected .menu-item {
    font-weight: 700;
    color: rgb(255, 255, 255);
  }
}
</style>
