import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { getOldUrlMap } from '../../api/basic/user';

@Module
class OldUrl extends VuexModule {
  toOldMap = {};
  @Mutation
  setMap(data: any): void {
    this.toOldMap = data;
  }
  @Action({ commit: 'setMap' })
  async getOldSystemUrlMap() {
    const { data } = await getOldUrlMap();
    return data;
  }
}
export default OldUrl;
