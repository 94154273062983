import Layout from '../../views/layout/index.vue';

const user = {
  path: '/user',
  name: 'user',
  meta: {
    title: 'User',
    icon: 'Labor Management',
  },
  component: () => import('@/views/layout/index.vue'),
  children: [
    {
      path: 'list',
      name: 'user.list',
      meta: {
        title: 'User List',
      },
      component: () => import('@/views/user/user-list/index.vue'),
    },
    {
      path: 'warehouselist',
      name: 'user.warehouseList',
      meta: {
        title: 'Warehouse List',
      },
      component: () => import('@/views/user/warehouse-list/index.vue'),
    },
    {
      path: 'warehouselist/users/:whsId',
      name: 'user.warehouseList.user',
      meta: {
        title: '${whsId} Users',
        menuHide: true,
        navList: [
          {
            routeName: 'user',
            title: 'User',
          },
          {
            routeName: 'user.warehouseList',
            title: 'Warehouse List',
          },
        ],
      },
      component: () => import('@/views/user/warehouse-list/users.vue'),
    },
    {
      path: 'warehouselist/users/:whsId/:userId',
      name: 'user.warehouseList.user.detail',
      meta: {
        title: 'Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'user',
            title: 'User',
          },
          {
            routeName: 'user.warehouseList',
            title: 'Warehouse List',
          },
          {
            routeName: 'user.warehouseList.user',
            title: '${whsId} Users',
          },
        ],
      },
      component: () => import('@/views/user/warehouse-list/userDetail.vue'),
    },
    {
      path: 'warehouselist/roles/:whsId',
      name: 'user.warehouseListRole',
      meta: {
        title: '${whsId} Roles',
        menuHide: true,
        navList: [
          {
            routeName: 'user',
            title: 'User',
          },
          {
            routeName: 'user.warehouseList',
            title: 'Warehouse List',
          },
        ],
      },
      component: () => import('@/views/user/warehouse-list/roles.vue'),
    },
    {
      path: 'permissiontree',
      name: 'user.permissionTree',
      meta: {
        title: 'Permission Tree',
      },
      component: () => import('@/views/user/permission-tree/index.vue'),
    },
    {
      path: 'keyrelationship',
      name: 'user.keyRelationship',
      meta: {
        title: 'Key Relationship',
      },
      component: () => import('@/views/user/key-relationship/index.vue'),
    },
  ],
};
export default user;
