import request from '@/utils/request';

export default {
  searchWarehouse(params: object) {
    return request.get('/api/v2/apps/basic/location/search_warehouse/', {
      params,
    });
  },
  createWarehouse(params: object) {
    return request.post('/api/v2/apps/basic/location/create_warehouse/', {
      ...params,
    });
  },
  detailWarehouse(whs_id: any) {
    return request.get('/api/v2/apps/basic/location/get_warehouse_detail', {
      params: {
        whs_id,
      },
    });
  },
  editWarehouse(params: object) {
    return request.get('/api/v2/apps/basic/location/update_warehouse/', {
      params,
    });
  },
  getCountryList() {
    return request.get('/api/v2/apps/basic/location/get_country_list', {});
  },
};
