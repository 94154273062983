<template>
  <span class="detail-item">
    <span v-if="isDetail" class="detail-display">
      {{ isDate ? formatTime(value) : formatIfEmptyText(value) }}
      <span v-if="!isEmpty && measureUnit" class="measure-unit">{{
        measureUnit
      }}</span>
    </span>
    <slot v-else></slot>
    <slot name="detail"></slot>
  </span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { format } from '@/utils/date';
import { formatIfEmptyText } from '@/utils/tools';
import { DEFAULT_NULL_OR_EMPTY_STRING } from '@/common/constant';

@Component
export default class DetailItem extends Vue {
  @Prop() value!: string | number;
  @Prop() isDetail!: boolean;
  @Prop() isDate: boolean;
  @Prop() measureUnit: string;

  emplyList = ['', undefined, null, DEFAULT_NULL_OR_EMPTY_STRING];

  formatIfEmptyText = formatIfEmptyText;

  formatTime(time: number) {
    const isDate = true;
    const formatText = formatIfEmptyText(time, isDate);
    return formatText === DEFAULT_NULL_OR_EMPTY_STRING
      ? formatText
      : format(time);
  }

  get isEmpty() {
    return this.emplyList?.includes(this.value as any);
  }
}
</script>
<style scoped>
.detail-display {
  display: inline-block;
  vertical-align: middle;
}
.measure-unit {
  padding-left: 1px;
}
</style>
