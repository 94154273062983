// 不需要转换的固定字符
const IGNORE_TRANS_TEXT_LIST = [
  'SKU',
  'ID',
  'ASN',
  'PDA',
  'MTO',
  'UID',
  'SA',
  'WMS',
  'RT',
  'UPC',
  'WHS',
  'LM',
  'TN',
  'FBS',
  'OMS',
  'PMS',
  'RTS',
  'YES',
  'NO',
  'PC',
  'CC',
  'OB',
  'RI',
  'RTB',
  'CC',
  'OB',
  'CB',
  'OOS',
  'QC',
  'AQL',
  'SBS',
  'B2B2C',
  'B2C',
  'BD',
  'MT',
  'Non-SBS',
  'ABC',
  '3PL',
  'MTB',
  'IBT',
  'FRS',
  'SCS',
  // order_structure_list枚举SalesOrderStructureType的值 MSAQ、SSAQ、SSSQ不需要转换。
  'MSAQ',
  'SSAQ',
  'SSSQ',
];
const ALL_LOWER_TEXT_LIST = ['OF'];

export const capitalizeSingle = (s: string): string => {
  if (!s) return s;
  if (s.length === 1) {
    return s.toUpperCase();
  }
  if (IGNORE_TRANS_TEXT_LIST.includes(s)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return IGNORE_TRANS_TEXT_LIST.find((item) => item === s)!;
  } else if (ALL_LOWER_TEXT_LIST.includes(s)) {
    return s.toLowerCase();
  }
  return s[0].toUpperCase() + s.substring(1).toLowerCase();
};

export default (s: string) => {
  if (!s) return s;
  const arr = s.split(/[\s|_]+/);
  return arr.map(capitalizeSingle).join(' ');
};
