import { DataCollectorInstance, TransactionCollector } from '@scfe-common/core';
import { handleResponseData } from '../../utils';
import { ActionType, RequestState } from '@scfe-common/chain-tracker';
import { SLA_CHAINS_TYPE } from '@/utils/chain-tracker/constants';

export default [
  {
    name: 'SLASaleInboundBoxing',
    status: {
      errorCount: 0,
    },
    collector: {} as TransactionCollector,
    ALLName: 'custom_InboundBoxing',
    PieceByPieceSKU: 'custom_InboundBoxing_piece_by_piece_sku',
    NoPieceByPieceSKU: 'custom_InboundBoxing_no_piece_by_piece_sku',
    ErrorCount: 'custom_InboundBoxing_error_count',
    getPointList: function () {
      return [
        // boxing 任务开始
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/inbound\/poboxing\/scan_sheet_id(\?.*)?$/,
            state: RequestState.Before,
          },
          handler: () => {
            this.collector = DataCollectorInstance.createTransactionCollector();
            this.collector.startTrack({
              name: this.ALLName,
            });
            this.status.errorCount = 0;
          },
        },
        {
          type: ActionType.CLICK,
          rule: {
            selectors: '#boxing-input-sku',
          },
          handler: () => {
            this.collector.startTrack({
              name: this.PieceByPieceSKU,
            });
            this.collector.startTrack({
              name: this.NoPieceByPieceSKU,
            });
          },
        },

        // piece by piece 结束
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/inbound\/poboxing\/scan_and_save_sku(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, sendDataFn: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode === 0) {
              const [result] = this.collector.endTrackByName({
                name: this.PieceByPieceSKU,
                isSuccess: true,
              });
              this.collector.endTrackByName({
                name: this.NoPieceByPieceSKU,
                isSuccess: true,
              });
              sendDataFn({
                pointId: result.name,
                customData: { ...result, type: 'experienceTime' },
              });
              sendDataFn({
                pointId: this.ErrorCount,
                customData: {
                  errorCount_n: this.status.errorCount,
                  type: 'experienceCount',
                },
              });
            }
          },
        },
        // no piece by piece 结束
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/inbound\/poboxing\/save_box_sku(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, sendDataFn: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode === 0) {
              const [result] = this.collector.endTrackByName({
                name: this.NoPieceByPieceSKU,
                isSuccess: true,
              });
              this.collector.endTrackByName({
                name: this.PieceByPieceSKU,
                isSuccess: true,
              });
              sendDataFn({
                pointId: result.name,
                customData: { ...result, type: 'experienceTime' },
              });
            }
          },
        },
        // 监听所有的请求报错
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/inbound\/poboxing\//,
            state: RequestState.After,
          },
          handler: (trackData: any, _: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode !== 0) {
              this.status.errorCount += 1;
            }
          },
        },
        // boxing 结束
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/inbound\/poboxing\/complete_box(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, sendDataFn: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode === 0) {
              // 全流程耗时和错误数量上报
              const [allResult] = this.collector.endTrackByName({
                name: this.ALLName,
                isSuccess: true,
                endAll: true,
              });
              sendDataFn({
                pointId: allResult.name,
                customData: { ...allResult, type: 'experienceTime' },
              });
              sendDataFn({
                pointId: this.ErrorCount,
                customData: {
                  errorCount_n: this.status.errorCount,
                  type: 'experienceCount',
                },
              });
              DataCollectorInstance.removeTransactionCollector({
                traceId: this.collector.traceId,
              });
            }
          },
        },
      ];
    },
  },
];
