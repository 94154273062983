import request from '@/utils/request';

interface InterfaceOutboundInProcessTracking {
  time_type?: number;
  data_type?: number;
}

export const searchOutboundInProcessTracking = async (
  params: InterfaceOutboundInProcessTracking
) => {
  const res = await request.get(
    '/api/v2/apps/dashboard/outbounddashboard/search_inprocess_tracking',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

export const searchOutboundCompletedTracking = async (
  params: InterfaceOutboundInProcessTracking
) => {
  const res = await request.get(
    '/api/v2/apps/dashboard/outbounddashboard/search_inprocess_completed_tracking',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceOutboundPlotTracking {
  time_type?: number;
  data_type?: number;
  task_status?: number;
  time_sequence?: number;
}

export const searchOutboundPlotTracking = async (
  params: InterfaceOutboundPlotTracking
) => {
  const res = await request.get(
    '/api/v2/apps/dashboard/outbounddashboard/search_inprocess_tracking_plot',
    {
      params: {
        ...params,
        time: Date.now(),
      },
    }
  );
  return res;
};

export const downloadOutboundInProcessTracking =
  '/api/v2/apps/dashboard/outbounddashboard/download_inprocess_tracking';

interface InterfaceDownloadOutboundCompleted {
  data_type?: number;
  time_type?: number;
}

export const downloadOutboundCompletedTracking =
  '/api/v2/apps/dashboard/outbounddashboard/download_inprocess_completed_tracking';

export const searchOutboundTaskAssignmentPickingData = async (
  params: any = {}
) => {
  const res = await request.get(
    '/api/v2/apps/dashboard/outbounddashboard/search_process_picking_task',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

export const searchOutboundOrderStructureData = async (params: any = {}) => {
  const res = await request.get(
    '/api/v2/apps/dashboard/outbounddashboard/search_order_structure',
    {
      params: {
        ...params,
        time: Date.now(),
      },
    }
  );
  return res;
};

interface InterfaceAddFavorite {
  user_email?: string;
  favorites?: string;
  title?: string;
}

export const addFavorite = async (params: InterfaceAddFavorite) => {
  const res = await request.post(
    '/api/v2/apps/dashboard/outbounddashboard/add_to_favorite',
    {
      ...params,
    }
  );
  return res;
};

export const downloadOrderStructure = async () => {
  const res = await request.get(
    '/api/v2/apps/dashboard/outbounddashboard/download_order_structure'
  );
  return res;
};

interface InterfaceGetFavorite {
  user_email?: string;
}

export const getFavorite = async (params: InterfaceGetFavorite) => {
  const res = await request.get(
    '/api/v2/apps/dashboard/outbounddashboard/get_favorite',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceSearchBacklogOrder {
  data_type?: number;
  time_type?: number;
  time_sequence?: number;
}

export const searchBacklogOrder = async (
  params: InterfaceSearchBacklogOrder
) => {
  const res = await request.get(
    '/api/v2/apps/dashboard/outbounddashboard/search_backlog_order',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

export const searchEfficient = async () => {
  const res = await request.get(
    '/api/v2/apps/dashboard/outbounddashboard/search_efficient'
  );
  return res;
};

interface InterfaceSearchInProcessTracking {
  data_type?: number;
  time_type?: number;
  time_sequence?: number;
}

export const searchInProcessTracking = async (
  params: InterfaceSearchInProcessTracking
) => {
  const res = await request.get(
    '/api/v2/apps/dashboard/outbounddashboard/search_in_process_tracking',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceSearchInProcessTrackingPlot {
  time_type?: number;
  data_type?: number;
  task_status?: number;
  time_sequence?: number;
}

export const searchInProcessTrackingPlot = async (
  params: InterfaceSearchInProcessTrackingPlot
) => {
  const res = await request.get(
    '/api/v2/apps/dashboard/outbounddashboard/search_in_process_tracking_plot',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

// export const searchOrderStructure = async () => {
//   const res = await request.get(
//     '/api/v2/apps/dashboard/outbounddashboard/search_order_structure'
//   );
//   return res;
// };

// export const searchProcessPickingTask = async () => {
//   const res = await request.get(
//     '/api/v2/apps/dashboard/outbounddashboard/search_process_picking_task'
//   );
//   return res;
// };

export const searchRealTimeUrgentOrder = async () => {
  const res = await request.get(
    '/api/v2/apps/dashboard/outbounddashboard/search_real_time_urgent_order'
  );
  return res;
};

interface InterfaceUpdateFavorite {
  user_email?: string;
  favorites?: string;
  title?: string;
  id?: number;
  is_delete?: number;
}

export const updateFavorite = async (params: InterfaceUpdateFavorite) => {
  const res = await request.post(
    '/api/v2/apps/dashboard/outbounddashboard/update_favorite',
    {
      ...params,
    }
  );
  return res;
};

interface InterfaceDownloadInProcessCompletedTracking {
  time_type?: number;
  data_type?: number;
  time_sequence?: number;
}

export const downloadInProcessCompletedTracking = async (
  params: InterfaceDownloadInProcessCompletedTracking
) => {
  const res = await request.get(
    '/api/v2/apps/dashboard/outbounddashboard/download_in_process_completed_tracking',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceDownInProcessTracking {
  time_type?: number;
  data_type?: number;
  task_status_list?: string;
  time_sequence?: number;
}

export const downInProcessTracking = async (
  params: InterfaceDownInProcessTracking
) => {
  const res = await request.get(
    '/api/v2/apps/dashboard/outbounddashboard/down_in_process_tracking',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceSearchBacklogOrderPlot {
  data_type?: number;
  time_type?: number;
  time_sequence?: number;
  task_status?: number;
}

export const searchBacklogOrderPlot = async (
  params: InterfaceSearchBacklogOrderPlot
) => {
  const res = await request.get(
    '/api/v2/apps/dashboard/outbounddashboard/search_backlog_order_plot',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceSearchBacklogOrderTab {
  data_type?: number;
  time_type?: number;
  time_sequence?: number;
}

export const searchBacklogOrderTab = async (
  params: InterfaceSearchBacklogOrderTab
) => {
  const res = await request.get(
    '/api/v2/apps/dashboard/outbounddashboard/search_backlog_order_tab',
    {
      params: {
        ...params,
        time: Date.now(),
      },
    }
  );
  return res;
};

interface InterfaceSearchInprocessCompletedTracking {
  data_type?: number;
  time_type?: number;
  time_sequence?: number;
}

export const searchInprocessCompletedTracking = async (
  params: InterfaceSearchInprocessCompletedTracking
) => {
  const res = await request.get(
    '/api/v2/apps/dashboard/outbounddashboard/search_inprocess_completed_tracking',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceSearchInProcessTrackingTab {
  data_type?: number;
  time_type?: number;
  time_sequence?: number;
  finished_status?: number;
}

export const searchInProcessTrackingTab = async (
  params: InterfaceSearchInProcessTrackingTab
) => {
  const res = await request.get(
    '/api/v2/apps/dashboard/outbounddashboard/search_inprocess_tracking_tab',
    {
      params: {
        ...params,
        time: Date.now(), // 防止重复发被拦截
      },
    }
  );
  return res;
};

interface InterfaceSearchOrder3pl {
  type?: number;
  time_sequence?: number;
  order_type?: number;
  channel_ids: string;
}

export const searchOrder3pl = async (params: InterfaceSearchOrder3pl) => {
  const res = await request.get(
    '/api/v2/apps/dashboard/outbounddashboard/search_order3pl',
    {
      params: {
        ...params,
        time: Date.now(),
      },
    }
  );
  return res;
};

export const searchOrderStructure = async () => {
  const res = await request.get(
    '/api/v2/apps/dashboard/outbounddashboard/search_order_structure'
  );
  return res;
};

export const searchProcessPickingTask = async () => {
  const res = await request.get(
    '/api/v2/apps/dashboard/outbounddashboard/search_process_picking_task'
  );
  return res;
};
