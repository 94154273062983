export default [
  {
    path: 'organizationstructure',
    name: 'laborManagement.organizationstructure',
    meta: {
      title: 'Organization Structure',
      filterSave: true,
    },
    component: () =>
      import('@/views/labor-management/organization-structure/index.vue'),
  },
];
