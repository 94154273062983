import { Point, AbstractChain, ActionType } from '@scfe-common/chain-tracker';

class DwellTimeChain extends AbstractChain {
  constructor(url: string) {
    super();
    this.status.url = url;
    this.name = 'DwellTimeChain_' + url;
    this.setPointList();
  }
  name = 'DwellTimeChain';
  pointList: Point[] = [];
  status = {
    operationTime: 0, // 链路开始时间
    type: 'dwellTime',
    url: '',
  };

  setPointList = () => {
    this.pointList = [
      {
        type: ActionType.PAGE_VIEW,
        // 进入页面时触发
        rule: { to: '/v2' + this.status.url },
        handler: () => {
          this.status.operationTime = Date.now();
        },
      },
      {
        type: ActionType.PAGE_VIEW,
        // 离开页面时触发
        rule: { from: '/v2' + this.status.url },
        handler: (_trackData, _sendDataFn) => {
          _sendDataFn({
            pointId: 'dwell-time' + this.status.url,
            customData: { ...this.status },
            duration: Date.now() - this.status.operationTime,
          });
        },
      },
    ];
  };
}
const dwellTimePaths = ['/warehouseMap/heatMap'];

const dwellTimeChainList = dwellTimePaths.map(
  (path) => new DwellTimeChain(path)
);

export default dwellTimeChainList;
