import reactContainerFactory from '@/components/react-container/reactContainerFactory';
import { lazy } from 'react';
import { RouteConfig } from 'vue-router';

const wes: RouteConfig = {
  path: '/wes',
  name: 'wes',
  meta: {
    title: 'WES',
    icon: 'Report Center',
  },
  redirect: '/wes/fulfillment-chain-requirement-report',
  component: () => import('@/views/layout/index.vue'),
  children: [
    {
      path: 'fulfillment-chain-requirement-report',
      name: 'wes.fulfillmentchainrequirementreport',
      meta: {
        title: 'Fulfillment Chain Requirement Report',
      },
      component: reactContainerFactory({
        name: 'wes.fulfillmentchainrequirementreport',
      }),
      redirect:
        '/wes/fulfillment-chain-requirement-report/fulfillment-chain-requirement',
      children: [
        {
          path: 'fulfillment-chain-requirement',
          meta: {
            menuHide: true,
            title: 'Fulfillment Chain Requirement',
          },
        },
        {
          path: 'logic-area-requirement',
          meta: {
            menuHide: true,
            title: 'Logic Area Requirement',
          },
        },
        {
          path: 'warehouse-requirement',
          meta: {
            menuHide: true,
            title: 'Warehouse Requirement',
          },
        },
      ],
      props: {
        component: () =>
          lazy(
            () =>
              import(
                '@/react-app/pages/wes/fulfillment-chain-requirement-report'
              )
          ),
      },
    },
  ],
};
export default wes;
