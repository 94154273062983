const tabNames: any = [
  [
    // Consumables Management / Task
    'PC.ConsumablesManagement.Task.StockTaskTask.View',
    'PC.ConsumablesManagement.Task.ManualRemovalTask.View',
  ],
  [
    // Rule Center / QC Rule
    'PC.RuleCenter.QCRule.QCRuleConfig.View',
    'PC.RuleCenter.QCRule.SupplierSetting.View',
    'PC.RuleCenter.QCRule.ReturnQCChecklistConfig.View',
  ],
  [
    // Rule Center / Allocate Rule
    'PC.RuleCenter.AllocateRule.OutboundAllocateRule',
    'PC.RuleCenter.AllocateRule.MTOutbound',
    'PC.RuleCenter.AllocateRule.RTSOutbound',
    'PC.RuleCenter.AllocateRule.Replenishment',
  ],
  [
    // Rule Center / Rule Group
    'PC.RuleCenter.RuleGroup.RuleGroup.View',
    'PC.RuleCenter.RuleGroup.UIDRule.View',
    'PC.RuleCenter.RuleGroup.BatchRule.View',
  ],
  [
    // Sales Outbound / Task
    'PC.SalesOutbound.Task.SubPickingTask.View',
    'PC.SalesOutbound.Task.PickingTask.View',
    'PC.SalesOutbound.Task.DeliveringTask',
    'PC.SalesOutbound.Task.TransitingTask.View',
    'PC.SalesOutbound.Task.PreSortingTask',
    'PC.SalesOutbound.Task.SortingTask',
    'PC.SalesOutbound.Task.CheckingTask.View',
    'PC.SalesOutbound.Task.PackingTask.View',
    'PC.SalesOutbound.Task.ReversePickingTask.View',
  ],
  [
    // WMS Basic / Storage Area Setting
    'PC.WMSBasic.StorageAreaSetting.ZoneCluster.View',
    'PC.WMSBasic.StorageAreaSetting.Zone.View',
    'PC.WMSBasic.StorageAreaSetting.Pathway.view',
    'PC.WMSBasic.StorageAreaSetting.Cell.View',
    'PC.WMSBasic.StorageAreaSetting.InboundStagingLocation',
    'PC.WMSBasic.StorageAreaSetting.OutboundStagingLocation',
  ],
  [
    // MT outbound / Task
    'PC.MTOutbound.Task.Picking.Task.View',
    'PC.MTOutbound.Task.CheckingTask.View',
    'PC.MTOutbound.Task.Packing.Task.View',
    'PC.MTOutbound.Shipping.Task.View',
  ],
  [
    // Inventory Management / Replenishment Strategy
    'PC.InventoryManagement.Replenishment.Strategy.ForecastSales.View',
    'PC.InventoryManagement.Replenishment.Strategy.DailySalesReport.View',
    'PC.InventoryManagement.Replenishment.Strategy.SafetyAndTargetStock.View',
  ],
  [
    // Inbound / Order
    'PC.Inbound.Order.InboundASN.View',
    'PC.Inbound.Order.ASNGroup.View',
    'PC.Inbound.Order.QueueList.View',
    'PC.Inbound.Order.TrackingID.View',
  ],
  [
    // Inbound / Task
    'PC.Inbound.Task.BoxingTask.View',
    'PC.Inbound.Task.QCTask.View',
    'PC.Inbound.Task.ReceiveTask.View',
    'PC.Inbound.Task.PutawayTask.View',
    'PC.Inbound.Task.ReprocessTask.View',
    'PC.Inbound.Task.NewSKUCollectingTask.View',
    'PC.Inbound.Task.IBTPickingTask.View',
  ],
  [
    // MT Inbound / Order
    'PC.MTInbound.Order.MTASN.View',
    'PC.MTInbound.Order.QueueList.View',
  ],
  [
    // Return Inbound / Order
    'PC.ReturnInbound.Order.ReturnASN.View',
    'PC.ReturnInbound.Order.QueueList.View',
  ],
  [
    // Return Inbound / Task
    'PC.ReturnInbound.Task.QCTask.View',
    'PC.ReturnInbound.Task.ReceiveTask.View',
    'PC.ReturnInbound.Task.PutawayTask.View',
  ],
  [
    // Rule Center / Putaway Task Assignment Rule
    'PC.RuleCenter.PutawayTaskAssignmentRule.InboundPutaway.View',
    'PC.RuleCenter.PutawayTaskAssignmentRule.InboundPutawayHighPriorityRule.View',
    'PC.RuleCenter.PutawayTaskAssignmentRule.CycleCountTaskAssignmentRule.View',
  ],
  [
    // Rule Center / Replenishment Rule
    'PC.RuleCenter.ReplenishmentRule.DailySalesConfig.View',
    'PC.RuleCenter.ReplenishmentRule.SKUConfig.View',
  ],
  [
    // WMS Basic / Station Management
    'PC.WMSBasic.StationManagement.StationManagement.View',
    'PC.WMSBasic.StationManagement.RecordSettings.View',
  ],
  [
    // RMS Return Inbound / Return to Seller / Order
    'PC.ReturnInbound.ReturnToSeller.Order.Action.View',
    'PC.ReturnInbound.ReturnToSeller.Order.Carton.View',
  ],
  [
    // RMS Return Inbound / Return to Seller / Task
    'PC.ReturnInbound.ReturnToSeller.Task.PackingTask.View',
    'PC.ReturnInbound.ReturnToSeller.Task.ShippingTask.View',
  ],
  [
    // QueryCenter
    'PC.Query.UPC/UID/SKUID',
  ],
];

export { tabNames };
