<template>
  <div class="store-service-component">
    <s-button type="text" class="store-button" @click="openSaveDialog">{{
      $i18n('Save as Bookmark')
    }}</s-button>
    <s-tooltip
      v-if="bookMarkToolTip"
      placement="top"
      :content="$i18n(bookMarkToolTip)"
      class="tool-tip"
    >
      <s-icon-question-outline width="14" height="14" color="#3274F7" />
    </s-tooltip>
    <s-button
      type="text"
      class="store-button"
      @click="openLoadDialog"
      :disabled="!existBookMarkList.length"
      >{{ $i18n('Load Bookmark') }}</s-button
    >
    <s-dialog
      width="400px"
      :title="$i18n('Save as Bookmark')"
      :append-to-body="true"
      :visible.sync="showSaveDialog"
      class="save-dialog"
    >
      <s-form ref="saveFormRef" :model="saveModel" :rules="saveRules">
        <s-form-item
          :label="$i18n('Bookmark Type')"
          prop="type"
          label-position="top"
        >
          <s-radio-group
            v-model="saveModel.type"
            size="medium"
            vertical
            @change="changeType"
          >
            <s-popover
              v-if="existBookMarkList.length >= MaxTemplateNum"
              placement="top"
              :content="$i18n('Only up to 6 new bookmarks can be added')"
            >
              <s-radio
                slot="reference"
                :label="NewBookMarkType"
                :disabled="true"
              >
                <span>{{ $i18n('New Bookmark') }}</span>
              </s-radio>
            </s-popover>
            <s-radio v-else :label="NewBookMarkType">
              <span>{{ $i18n('New Bookmark') }}</span>
            </s-radio>
            <s-radio
              :label="ExistBookMarkType"
              :disabled="!existBookMarkList.length"
              >{{ $i18n('Existing Bookmark') }}</s-radio
            >
          </s-radio-group>
        </s-form-item>
        <s-form-item
          v-if="saveModel.type === NewBookMarkType"
          :label="$i18n('Bookmark Name')"
          prop="template_name"
          label-position="top"
        >
          <s-input
            v-model="saveModel.template_name"
            width="350px"
            :clearable="true"
          />
        </s-form-item>
        <s-form-item
          v-if="saveModel.type === ExistBookMarkType"
          :label="$i18n('Bookmark')"
          prop="type"
          label-position="top"
        >
          <s-radio-group v-model="saveModel.template_id" size="medium" vertical>
            <s-radio
              v-for="(templateInfo, index) in existBookMarkList"
              :label="templateInfo.template_id"
              :key="templateInfo.template_id"
              :value="templateInfo.template_id"
            >
              {{ $i18n(templateInfo.template_name) }}
              <span v-if="index === 0" class="g-recently-used-text">{{
                $i18n('Recently Used')
              }}</span>
            </s-radio>
          </s-radio-group>
        </s-form-item>
      </s-form>
      <div slot="footer">
        <s-button @click="handleSaveCancel" style="margin-right: 16px">
          {{ $i18n('Cancel') }}
        </s-button>
        <s-button type="primary" @click="handleSaveConfirm">
          {{ $i18n('Confirm') }}
        </s-button>
      </div>
    </s-dialog>
    <s-dialog
      width="400px"
      :title="$i18n('Load Bookmark')"
      :append-to-body="true"
      :visible.sync="showLoadDialog"
      class="load-dialog"
    >
      <s-form ref="loadFormRef" :model="loadModel" :rules="loadRules">
        <s-form-item
          :label="$i18n('Bookmark')"
          prop="template_id"
          label-position="top"
        >
          <s-radio-group v-model="loadModel.template_id" size="medium" vertical>
            <s-radio
              v-for="(templateInfo, index) in existBookMarkList"
              :label="templateInfo.template_id"
              :key="templateInfo.template_id"
              :value="templateInfo.template_id"
            >
              {{ $i18n(templateInfo.template_name) }}
              <span v-if="index === 0" class="g-recently-used-text">{{
                $i18n('Recently Used')
              }}</span>
            </s-radio>
          </s-radio-group>
        </s-form-item>
      </s-form>
      <div slot="footer">
        <s-button @click="handleLoadCancel" style="margin-right: 16px">
          {{ $i18n('Cancel') }}
        </s-button>
        <s-button type="primary" @click="handleLoadConfirm">
          {{ $i18n('Confirm') }}
        </s-button>
      </div>
    </s-dialog>
  </div>
</template>

<script lang="ts">
import { reportEvent } from '@/utils/qms';
import { Component, Vue, Prop, Model } from 'vue-property-decorator';
import {
  createTemplate,
  getTemplateList,
  updateTemplate,
  recentlyUsed,
} from './api';
import { NewBookMarkType, ExistBookMarkType, MaxTemplateNum } from './const';

@Component({
  name: 'StoreServiceComponent',
})
export default class StoreServiceComponent extends Vue {
  @Prop({ default: () => '' }) bookMarkToolTip: string;
  // @Prop({ default: {} }) data!: string;

  @Model('change') data!: any;
  @Prop() keyName: string;

  showSaveDialog = false;
  showLoadDialog = false;
  NewBookMarkType = NewBookMarkType;
  ExistBookMarkType = ExistBookMarkType;
  MaxTemplateNum = MaxTemplateNum;

  saveModel: any = {
    type: NewBookMarkType,
    template_name: null,
    template_id: null,
  };

  loadModel: any = {
    template_id: null,
  };

  saveRules = {
    type: [
      {
        required: true,
        message: this.$i18n('Type is required'),
      },
    ],
    template_name: [
      {
        required: true,
        message: this.$i18n('Bookmark Name is required'),
      },
    ],
    template_id: [
      {
        required: true,
        message: this.$i18n('Bookmark is required'),
      },
    ],
  };

  loadRules = {
    template_id: [
      {
        required: true,
        message: this.$i18n('Bookmark is required'),
      },
    ],
  };

  existBookMarkList: any = [];

  mounted() {
    this.getList();
  }

  async getList() {
    this.existBookMarkList = await getTemplateList(this.keyName);
  }

  getNewTemplateName() {
    const date = new Date();

    const year = date.getFullYear().toString().slice(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    const nameHeader = 'Bookmark_' + year + month + day;
    let nameNum = 1;
    this.existBookMarkList.forEach((item: any) => {
      if (item.template_name.match(`(?<=${nameHeader}).*`)) {
        nameNum++;
      }
    });

    return nameHeader + '_' + nameNum;
  }

  changeType() {
    (this.$refs['saveFormRef'] as any).clearValidate();
  }

  openSaveDialog() {
    this.showSaveDialog = true;
    this.saveModel.type = NewBookMarkType;
    if (this.existBookMarkList.length) {
      this.saveModel.template_id = this.existBookMarkList[0].template_id;
    }
    if (this.existBookMarkList.length >= MaxTemplateNum) {
      this.saveModel.type = ExistBookMarkType;
    }
    this.saveModel.template_name = this.getNewTemplateName();
  }
  handleSaveCancel() {
    this.showSaveDialog = false;
  }

  async createTemplate() {
    const templateData = JSON.stringify(this.data);
    await createTemplate(
      templateData,
      this.keyName,
      this.saveModel.template_name
    );
  }

  async updateTemplate() {
    const templateData = JSON.stringify(this.data);
    await updateTemplate(this.saveModel.template_id, templateData);
  }

  async handleSaveConfirm() {
    if (this.saveModel.type === NewBookMarkType) {
      await this.$validate('saveFormRef');
      await this.createTemplate();
    } else {
      await this.$validate('saveFormRef');
      await this.updateTemplate();
    }
    this.showSaveDialog = false;
    this.$message.success(this.$i18n('Bookmark saved Successfully'));
    await this.getList();
    reportEvent('store-service-btn-save', {
      name: this.keyName,
    });
  }

  openLoadDialog() {
    this.showLoadDialog = true;
    this.loadModel.template_id = this.existBookMarkList[0].template_id;
  }
  handleLoadCancel() {
    this.showLoadDialog = false;
  }
  async handleLoadConfirm() {
    await recentlyUsed(this.loadModel.template_id);
    const templateInfo =
      this.existBookMarkList.find(
        (item: any) => item.template_id === this.loadModel.template_id
      ) || {};
    const data = JSON.parse(templateInfo.data || '');
    this.$emit('change', data);
    this.$emit('loadBookmark');
    this.$message.success(this.$i18n('Load Bookmark Success!'));
    this.showLoadDialog = false;
    reportEvent('store-service-btn-load', {
      name: this.keyName,
    });
  }
}
</script>

<style lang="scss" scoped>
.store-service-component {
  display: flex;
  align-items: center;
  margin-right: 16px;
  .store-button {
    font-weight: 500;
    font-size: 14px;
    margin-left: 16px;
  }
  .tool-tip {
    margin-left: 4px;
  }
}
</style>
<style lang="scss">
.g-recently-used-text {
  color: rgba(238, 77, 45, 1);
  border: 1px solid rgba(238, 77, 45, 0.5);
  border-radius: 2px;
  margin-left: 8px;
  padding: 2px;
  font-size: 12px;
  font-weight: 500;
}
</style>
