const inventoryAdjustment = {
  path: 'adjust',
  name: 'inventoryManagement.adjust',
  meta: {
    title: 'Inventory Adjustment',
    filterSave: true,
  },
  // redirect: '/inventorymanage/adjust/list',
  component: () => import('@/views/inventory-management/inventory-adjustment'),
  children: [
    {
      path: 'view',
      name: 'inventoryManagement.adjust.view',
      meta: {
        title: 'View',
        menuHide: true,
      },
      exact: true,
      component: () =>
        import('@/views/inventory-management/inventory-adjustment/view'),
    },
    {
      path: 'create',
      name: 'inventoryManagement.adjust.create',
      meta: {
        title: 'Create',
        menuHide: true,
      },
      exact: true,
      component: () =>
        import('@/views/inventory-management/inventory-adjustment/create'),
    },
    {
      path: 'approve',
      name: 'inventoryManagement.adjust.approve',
      meta: {
        title: 'Approve',
        menuHide: true,
      },
      exact: true,
      component: () =>
        import('@/views/inventory-management/inventory-adjustment/approve'),
    },
    {
      path: 'adjust',
      name: 'inventoryManagement.adjust.adjust',
      meta: {
        title: 'Adjust',
        menuHide: true,
      },
      exact: true,
      component: () =>
        import('@/views/inventory-management/inventory-adjustment/adjust'),
    },
  ],
};

export default inventoryAdjustment;
