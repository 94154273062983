import { DataCollectorInstance, TransactionCollector } from '@scfe-common/core';
import { handleResponseData } from '../../utils';
import { ActionType, RequestState } from '@scfe-common/chain-tracker';
import { SLA_CHAINS_TYPE } from '@/utils/chain-tracker/constants';

export default [
  {
    name: 'SLASaleOutboundPackingChain',
    status: {
      useResumeConfirm: 0,
      errorCount: 0,
    },
    collector: {} as TransactionCollector,
    ALLName: 'custom_SaleOutboundPacking',
    UseResumeConfirmName: 'custom/SaleOutboundPacking/useResumeConfirm',
    ReadyForHandlePackingTaskName:
      'custom/SaleOutboundPacking/readyForHandlePackingTask',
    ErrorCount: 'custom_SaleOutboundPacking_error_count',
    getPointList: function () {
      return [
        // Packing开始：输入 User id
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/packing\/validate_packing_user(\?.*)?$/,
            state: RequestState.Before,
          },
          handler: () => {
            this.collector = DataCollectorInstance.createTransactionCollector();
            this.collector.startTrack({
              name: this.ReadyForHandlePackingTaskName,
            });
            this.collector.startTrack({
              name: this.ALLName,
            });
            this.status.errorCount = 0;
          },
        },
        // 可以开始做 Packing 任务
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/packing\/search_packing_task(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, sendDataFn: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode === 0) {
              const [result] = this.collector.endTrackByName({
                name: this.ReadyForHandlePackingTaskName,
                isSuccess: true,
              });
              sendDataFn({
                pointId: result.name,
                customData: { ...result, type: SLA_CHAINS_TYPE },
              });
            }
          },
        },
        // 点击了 Resume Confirm
        {
          type: ActionType.CLICK,
          rule: {
            selectors: '.resumeConfirm',
          },
          handle: () => {
            this.status.useResumeConfirm = 1;
            this.collector.startTrack({
              name: this.UseResumeConfirmName,
            });
          },
        },
        // 监听所有的请求报错
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/packing\//,
            state: RequestState.After,
          },
          handler: (trackData: any, _: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode !== 0) {
              this.status.errorCount += 1;
            }
          },
        },
        // Packing 结束
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/packing\/complete_packing_task(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, sendDataFn: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode === 0) {
              const results = this.collector.endTrackByName({
                name: this.UseResumeConfirmName,
                isSuccess: true,
                endAll: true,
                extraData: {
                  useResumeConfirm_n: this.status.useResumeConfirm,
                },
              });
              results.forEach((result) => {
                sendDataFn({
                  pointId: result.name,
                  customData: { ...result, type: SLA_CHAINS_TYPE },
                });
              });

              // 全流程耗时和错误数量上报
              const [allResult] = this.collector.endTrackByName({
                name: this.ALLName,
                isSuccess: true,
                endAll: true,
              });
              sendDataFn({
                pointId: allResult.name,
                customData: { ...allResult, type: 'experienceTime' },
              });
              sendDataFn({
                pointId: this.ErrorCount,
                customData: {
                  errorCount_n: this.status.errorCount,
                  type: 'experienceCount',
                },
              });
            }
            DataCollectorInstance.removeTransactionCollector({
              traceId: this.collector.traceId,
            });
          },
        },
      ];
    },
  },
];
