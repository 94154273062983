import Layout from '../../views/layout/index.vue';

const cctv = {
  path: '/cctv-management',
  name: 'cctvmanagement',
  meta: {
    title: 'CCTV Management',
    icon: 'Inbound',
  },
  component: () => import('@/views/layout/index.vue'),
  children: [
    {
      path: '/cctv/video',
      name: 'cctvmanagement.video',
      meta: {
        title: 'Video View',
      },
      redirect: '/cctv-management/video',
    },
    {
      path: '/cctv/photo',
      name: 'cctvmanagement.photo',
      meta: {
        title: 'Photo View',
      },
      redirect: '/cctv-management/photo',
    },
    {
      path: '/cctv/device',
      name: 'cctvmanagement.device',
      meta: {
        title: 'Device',
      },
      redirect: '/cctv-management/device',
    },
  ],
};

export default cctv;
