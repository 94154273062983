<template>
  <div>
    <div :class="createFormClass">
      <s-form :model="model" :rules="rules" :label-width="labelWidth">
        <s-form-item
          v-for="item in createFromList"
          :key="item.prop"
          :label="item.label"
          :prop="item.prop"
          :inline="false"
        >
          <span>{{ model[item.prop] }}</span>
          <s-input
            v-model.trim="model[item.prop]"
            v-if="item.formType === 'input' && !isDetailItem[item.prop]"
            :disabled="isDisabledDic[item.prop]"
            @change="isActiveConfirmButton"
          />
          <s-input
            v-model.trim="model[item.prop]"
            v-if="item.formType === 'textarea' && !isDetailItem[item.prop]"
            type="textarea"
            :disabled="isDisabledDic[item.prop]"
            @change="isActiveConfirmButton"
          />
          <s-select
            v-model="model[item.prop]"
            v-if="item.formType === 'select' && !isDetailItem[item.prop]"
            :disabled="isDisabledDic[item.prop]"
            @select="isActiveConfirmButton"
            :style="item.style"
          >
            <s-option
              v-for="option in item.selections"
              :key="option.value"
              :value="option.value"
              :label="option.label"
            />
          </s-select>
        </s-form-item>
      </s-form>
      <div class="create-form-button" v-if="pageType !== 'Detail'">
        <s-button class="create-form-cancel-button" @click="cancel">{{
          this.$i18n('Cancel')
        }}</s-button>
        <wms-button
          class="create-form-confirm"
          type="primary"
          :disabled="!activeConfirmButton"
          :mode="this.model"
          :click="this.confirm"
          >{{ this.$i18n('Confirm') }}</wms-button
        >
      </div>
    </div>
    <div class="gradient-line" v-if="pageType !== 'Detail'"></div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  getRenderValue,
  getRenderValueWithoutCapitalize,
} from '@/utils/safeGet';
@Component
export default class CreateForm extends Vue {
  @Prop({ default: [] }) private formConfig!: [];
  @Prop() labelWidth!: string;
  @Prop() url!: string;
  @Watch('formConfig')
  onAfterChanged() {
    this.init();
  }
  model: any = {};
  rules = {};
  showCreateFormItemDic: any = {};
  createFromList: any = [];
  isDisabledDic = {};
  isDetailItem = {};
  requiredItems: any = [];
  pageType = '';
  activeConfirmButton = false;
  createFormClass = {};
  async init() {
    this.pageType = this?.$route?.meta?.title;
    this.formConfig.forEach((item: any) => {
      item.label = this.$i18n(item.label);
      this.$set(this.model, item.prop, item.defaultValue || undefined);
      if (item.isRequired) {
        this.requiredItems.push(item.prop);
        this.$set(this.rules, item.prop, [
          {
            required: true,
            message: `Please ${
              item.formType === 'input' ? 'Input' : 'Select'
            } ${item.label}`,
            trigger: 'blur',
          },
        ]);
      }
      this.getCreateForm(this.pageType, item);
    });
    this.createFromList = this.formConfig.filter((item: any) => {
      return this.showCreateFormItemDic[item.prop];
    });
    this.createFromList.forEach(async (item: any, index: number) => {
      if (typeof item.selections === 'function') {
        (this.createFromList[index] as any).selections = await (
          this.createFromList[index] as any
        ).selections();
      }
    });
    if (this.pageType === 'Detail' || this.pageType === 'Edit') {
      const res = await (this.$parent as any).formDetail();
      this.model = { ...res.data };
      this.createFromList.forEach(async (item: any) => {
        if (item.enums === 'CountryRegionType') {
          this.model[item.prop] = getRenderValueWithoutCapitalize(
            this.$store.state,
            `enums.systemEnums.${item.enums}`,
            this.model[item.prop]
          );
        } else if (item.enums) {
          this.model[item.prop] = getRenderValue(
            this.$store.state,
            `enums.systemEnums.${item.enums}`,
            this.model[item.prop]
          );
        }
      });
    }
    if (this.pageType === 'Detail') {
      this.createFormClass = {
        'create-form': true,
        'create-form-detail': true,
      };
    } else {
      this.createFormClass = {
        'create-form': true,
      };
    }
    if (this.pageType === 'Edit') {
      this.isActiveConfirmButton();
    }
  }
  async mounted() {
    await this.init();
  }
  getCreateForm(pageType: string, item: any) {
    /* eslint-disable */
    switch (pageType) {
      case 'Create':
        this.$set(this.showCreateFormItemDic, item.prop, item.isCreate);
        this.$set(this.isDisabledDic, item.prop, item.isCreateDisabled);
        break;
      case 'Detail':
        this.$set(this.showCreateFormItemDic, item.prop, item.isDetail);
        this.$set(this.isDetailItem, item.prop, item.isDetail);
        break;
      case 'Edit':
        this.$set(this.showCreateFormItemDic, item.prop, item.isEdit);
        this.$set(this.isDisabledDic, item.prop, item.isEditDisabled);
        break;
    }
    /* eslint-enable */
  }
  isActiveConfirmButton() {
    this.activeConfirmButton = this.requiredItems.every((item: any) => {
      return this.model[item] === 0 || this.model[item];
    });
  }
  async confirm() {
    try {
      if (this.pageType === 'Create') {
        await (this.$parent as any).formCreate(this.model);
      } else if (this.pageType === 'Edit') {
        await (this.$parent as any).formEdit(this.model);
      }
      this.$router.go(-1);
    } catch (e) {
      console.log('error', e);
    }
  }
  cancel() {
    this.$router.go(-1);
  }
}
</script>
<style lang="scss" scoped>
.create-form {
  padding: 24px 0 56px 24px;
}
.create-form.create-form-detail {
  padding-bottom: 0;
}
.main-page {
  overflow-y: hidden !important;
}
.gradient-line {
  width: 100%;
  height: 16px;
  position: fixed;
  bottom: 80px;
  transform: scaleX(-1) rotate(-360deg);
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.12) 100%
  );
}
.ssc-form {
  height: calc(100vh - 162px);
  overflow: scroll;
  overflow-x: hidden;
}
.create-form-detail {
  .ssc-form {
    height: unset;
  }
}
.create-form-button {
  width: calc(100% - 264px);
  height: 80px;
  margin-bottom: 0;
  position: fixed;
  bottom: 0;
  text-align: right;
  line-height: 80px;
  background-color: white;
  .create-form-confirm {
    width: 83px;
    margin: 0 24px 0 16px;
  }
  .create-form-cancel-button {
    width: 75px;
  }
}
</style>
