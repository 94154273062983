<template>
  <div class="table-main" ref="mainTable">
    <div
      ref="table"
      :class="{ table: true, behindTable: !this.paginationFixed }"
    >
      <slot name="table"></slot>
    </div>
    <slot name="refresh"></slot>
    <div
      v-show="pagination.total >= 0"
      ref="tableFooter"
      class="tableFooter"
      :class="{
        behindTable: !this.paginationFixed,
        shadow: scrollbar && !scrollBottom,
      }"
    >
      <slot name="statistics"></slot>
      <s-pagination
        v-if="this.paginationFixed"
        class="pagination"
        :total="pagination.total"
        :current-page="pagination.currentPage"
        :page-size="pagination.pageSize || PAGE_SIZE"
        :show-sizer="showSizer"
        :page-sizes="pagination.pageSizes || PAGE_SIZES"
        :show-last-page-number="pagination.showLastPageNumber"
        :show-jumper="showJumper"
        @size-change="pageSizeChange"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { debounce } from 'lodash';
import { Pagination } from '../interface';
import Store from '@/store';
import { State } from 'vuex-class';
import { PAGE_SIZE, PAGE_SIZES } from '@/common/constant';
function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
      value: debounce(target[prop], delay, {
        leading: true,
        trailing: true,
        maxWait: 1000,
      }),
    };
  };
}

@Component({
  components: {},
})
export default class Table extends Vue {
  @Prop() readonly pagination!: Pagination;
  @Prop({ default: true }) readonly paginationFixed!: boolean;
  @Prop({ default: false }) readonly showJumper: boolean;
  @Prop({ default: true }) readonly showSizer: boolean;
  PAGE_SIZES = PAGE_SIZES;
  PAGE_SIZE = PAGE_SIZE;
  tableDiv: any;
  tableFooter: any;
  mainTable: any;
  showPaginationNum = 20; //显示分页条数

  mainPage: HTMLElement | null;
  contentWrapper: HTMLElement | null;
  tableHead: HTMLElement | null;
  loadingIcon: HTMLElement | null;
  scrollbar = false; //是否main-page有滚动条
  scrollBottom = false; //main-page 滚动条是否已经滚到底部

  @State filterOptions: any;

  pageSizeChange(pageSize: number) {
    const name = this.$route.name;
    if (name) {
      const params: any = {
        [name]: {
          pageSize,
          page: this.filterOptions?.pageOptions[name]?.page || 1,
        },
      };
      Store.dispatch('getPageOptions', params);
    }
    this.$emit('pageSizeChange', pageSize);
  }
  pageChange(page: number) {
    const name = this.$route.name;
    if (name) {
      const params: any = {
        [name]: {
          pageSize: this.filterOptions?.pageOptions[name]?.pageSize || 20,
          page,
        },
      };
      Store.dispatch('getPageOptions', params);
    }
    this.$emit('pageChange', page);
  }
  //判断main-page是否出现滚动条,并监听滚动条滚动
  hasScrollbar() {
    const observer = new MutationObserver(() => {
      if (this.mainPage) {
        // console.log('123', this.mainPage.scrollHeight, this.mainPage.clientHeight, this.mainPage);
        if (this.mainPage.scrollHeight > this.mainPage.clientHeight) {
          this.scrollbar = true;
        } else {
          this.scrollbar = false;
        }
        this.setVisibleTableHeight();
      }
    });
    if (this.mainPage) {
      //通过observer 监听div变化，触发判断是否出现滚动条
      observer.observe(this.mainPage, {
        childList: true, //观察子节点变动
        subtree: true, // 观察后代节点变动
        attributes: false, //不观察属性变动
        characterDataOldValue: false, // 记录任何有变动的属性的旧值
      });
      window.addEventListener('scroll', this.scrollCallback);
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('scroll', this.scrollCallback);
      });
    }
  }

  @Debounce(100)
  scrollCallback() {
    if (this.mainPage) {
      // console.log('bb', this.mainPage.scrollTop, this.mainPage.clientHeight, this.mainPage.scrollHeight);
      if (
        this.mainPage.scrollTop + this.mainPage.clientHeight >
        this.mainPage.scrollHeight - 3
      ) {
        this.scrollBottom = true;
      } else {
        this.scrollBottom = false;
      }
    }
  }

  // 计算表格可见内容区高度
  setVisibleTableHeight() {
    // 进入页面获取高度
    this.$nextTick(() => {
      this.contentWrapper = this.getElementByOwnClass(
        '.main-page .table .ssc-table-body .simplebar-wrapper'
      );
      this.tableHead = this.getElementByOwnClass(
        '.main-page .table .ssc-table-header .simplebar-content'
      );
      if (
        this.contentWrapper &&
        this.tableHead &&
        this.tableHead.offsetHeight &&
        this.tableFooter.offsetTop
      ) {
        const mainTableTopadding = window.getComputedStyle(
          this.mainTable
        ).paddingTop;
        (this.contentWrapper as HTMLElement).style.minHeight = `calc(${
          this.tableFooter.offsetTop
        }px - ${
          (this.tableHead as HTMLElement).offsetHeight + 3.6
        }px - ${mainTableTopadding})`;
      }
    });
    this.setLoadingPosition();
  }

  // 计算表格Loading位置
  setLoadingPosition() {
    this.loadingIcon = this.getElementByOwnClass('.loading-spinner');
    if (this.loadingIcon) {
      this.loadingIcon.style.top = `calc(${this.tableFooter.offsetTop / 2}px)`;
    }
  }

  // 获取表格元素系列
  getTableDom() {
    this.tableDiv = this.$refs.table;
    this.tableFooter = this.$refs.tableFooter;
    // this.tableHeight();
    // console.log('aa', (document.querySelector('.ssc-dialog .table-main') as any).parentElement);
    this.mainTable = this.$refs.mainTable;
    this.mainPage = document.querySelector('.main-page');
    if (document.querySelector('.ssc-dialog .table-main')) {
      this.mainPage = (
        document.querySelector('.ssc-dialog .table-main') as any
      ).parentElement;
    }
  }
  // 根据 class 获取 元素
  getElementByOwnClass(className: string) {
    return Array.prototype.find.call(
      document.querySelectorAll(className),
      (el) => {
        return this.mainTable.contains(el);
      }
    );
  }
  created() {
    this.initPage();
  }
  mounted() {
    this.getTableDom();
    this.setVisibleTableHeight();
    this.hasScrollbar();
    window.addEventListener('resize', this.setVisibleTableHeight);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('resize', this.setVisibleTableHeight);
    });
  }

  @Watch('$route.name')
  initPage() {
    const name = this.$route.name;
    const filterSave = (this.$router as any).history.current.meta.filterSave;
    if (!filterSave) {
      // 不需要保存就返回
      return;
    }
    const curPageOptions = this.filterOptions?.pageOptions[name as string];
    if (curPageOptions) {
      // 如果存在保存条件，分别处理
      if (curPageOptions.pageSize && curPageOptions.pageSize !== 20) {
        this.pagination.pageSize = curPageOptions.pageSize;
      }
      if (curPageOptions.page && curPageOptions.page !== 1) {
        this.pagination.currentPage = curPageOptions.page;
      }
    } else {
      /**
       * 如果不存在，设置为 1-20
       * 这里兼容 tab 切换时 routerName 变更不及时问题
       */
      if (this.pagination.pageSize !== 20) {
        this.$emit('pageSizeChange', 20);
      }
      if (this.pagination.currentPage !== 1) {
        this.$emit('pageChange', 1);
      }
    }
  }
}
</script>
<style scoped lang="scss">
.table-main {
  position: relative;
  background: #fff;
  padding-bottom: 5px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.table {
  margin: 0;
  // border: 1px solid #eee;
  // padding-bottom: 16px;
  border-bottom: none;
  position: relative;
  flex-grow: 1;
  display: flex;
  ::v-deep .ssc-table {
    flex-grow: 1;
    & .ssc-table-header .simplebar-wrapper {
      min-height: 100%;
    }
    & .simplebar-content-wrapper {
      min-height: 100%;
    }
    .text-ellipsis-3 {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .ssc-dropdown {
  font-size: 12px;
  font-weight: var(--font-weight-medium);
}
.tableFooter {
  background: #fff;
  padding: 14px 24px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  bottom: 16px;
  position: sticky;
  border-left: 1px solid #ecf0f4;
  border-right: 1px solid #ecf0f4;
  border-bottom: 1px solid #ecf0f4;
  border-radius: 0px 4px 4px 4px;
  margin-top: 2px;
  margin-bottom: 16px;
  &.shadow {
    box-shadow: 0 -17px 17px -17px rgba(0, 0, 0, 0.2);
  }
  &::after {
    content: ' ';
    background: #fff;
    width: 101%;
    height: 16px;
    position: absolute;
    bottom: -17px;
    left: -2px;
  }
}
.behindTable.tableFooter {
  position: unset;
}
.behindTable.table {
  margin-bottom: 0px;
}
.refresh {
  position: sticky;
  bottom: 56px;
  background: #f5f6f9;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #646b76;
  height: 30px;
  line-height: 30px;
}
</style>
