import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { VNode, CreateElement } from 'vue';
import { inputGroupType, typeDic } from './config';
import spreadFilterForm from './getFilterForm';
import getFilterButtons from './FilterButtons';
import '../style/index.scss';
import { i18n } from '@/i18n';
import { getUTCSecondsBasedWhs, getUTCSecondsAtZero } from '@/utils/date';
import { State } from 'vuex-class';
import Store from '@/store';

export type filterConfigItem = {
  filterType?: string;
  tLabel?: string;
  label?: string;
  prop?: string | Array<Date | number>;
  selections?: Array<Record<string, any>> | Record<string, any>;
  useUTCZeroFormatDate?: boolean; // 使用utc zero方式转换时间戳，如：生产日期，过期日期
  defaultValue?: any;
  [propName: string]: any;
};

@Component
export default class SFilterPart extends Vue {
  @Prop()
  filterConfig!: Array<filterConfigItem>;
  @Prop()
  readonly onChange: (
    prevValue: any,
    value: any,
    key: string | Array<string>,
    filterConfig: Record<string, any>,
    changeFilterData: Function
  ) => void | Record<string, any>;
  @Prop()
  readonly onSearch: (data: any) => void;
  @Prop({ default: 'Search' }) searchText: string;
  @Prop({ default: 'Reset' }) resetText: string;

  @Prop()
  readonly onCancel: (data?: any) => void;
  @Prop() noSearch!: false;
  // true: 提供collapse能力, false: 默认展示所有filter item
  @Prop({ default: true }) autoCollapse: boolean;
  @Prop({ default: true }) enterSearch: boolean;
  @Prop({ default: false }) skipResetMapWhileInit: boolean; // init 时不进行 defaultValueMap 设置

  @Prop({ default: false }) showBookmark: boolean;
  @Prop({ default: false }) showFilterSelect: boolean;
  @Prop({ default: '' }) keyName: string;
  @Prop({ default: '' }) bookMarkToolTip: string;

  filterData: any = {};
  collapseList: any = [];
  filterList: any = [];
  showCollapse = false;
  showMore = true;
  lastSelect = '';
  selectFilterList: any = [];

  @State
  filterOptions: any;

  @Watch('filterConfig', { deep: true })
  onAfterChanged() {
    this.init();
  }

  @Watch('$route', { deep: true })
  initFilterMap() {
    // 获取当前路由的名称，并查看是否保存了筛选条件
    const name = this.$route.name;
    let defaultValueMap: any = {};
    if (this.filterOptions.filterOptions[name as string]) {
      defaultValueMap = { ...this.filterOptions.filterOptions[name as string] };
    }
    this.filterConfig.forEach((item: any, index: number) => {
      const { defaultValue } = item;
      item.prop instanceof Array
        ? this.$set(
            this.filterData,
            item.prop.join('+'),
            defaultValueMap[item.prop.join('+')] ?? defaultValue
          )
        : this.$set(
            this.filterData,
            item.prop,
            defaultValueMap[item.prop] ?? defaultValue
          );
      this.setInputGroupValue(item);
    });
    if (!this.autoCollapse) {
      this.showCollapse = true;
    }
  }

  // 按照store service 勾选的情况排序 filterConfig
  sortFilterBySelect(ConfigList: Array<filterConfigItem>) {
    const array = [...ConfigList];
    const sortedArray = array.sort((a, b) => {
      const indexA = this.selectFilterList.indexOf(a.tLabel);
      const indexB = this.selectFilterList.indexOf(b.tLabel);

      if (indexA === -1 && indexB === -1) {
        return 0; // 保持原始顺序
      } else if (indexA === -1) {
        return 1; // a.tLabel 不在 order 中，将 b 放在前面
      } else if (indexB === -1) {
        return -1; // b.tLabel 不在 order 中，将 a 放在前面
      } else {
        return indexA - indexB; // 根据 order 数组的顺序进行排序
      }
    });
    return sortedArray;
  }

  /* 处理 defalutValue */
  mounted() {
    // 获取当前路由的名称，并查看是否保存了筛选条件
    const name = this.$route.name;
    let defaultValueMap: any = {};
    if (this.filterOptions.filterOptions[name as string]) {
      defaultValueMap = { ...this.filterOptions.filterOptions[name as string] };
    }
    this.filterConfig.forEach((item: any) => {
      const { defaultValue } = item;
      item.prop instanceof Array
        ? this.$set(
            this.filterData,
            item.prop.join('+'),
            defaultValueMap[item.prop.join('+')] ?? defaultValue
          )
        : this.$set(
            this.filterData,
            item.prop,
            defaultValueMap[item.prop] ?? defaultValue
          );
      this.setInputGroupValue(item);
    });
    if (!this.autoCollapse) {
      this.showCollapse = true;
    }
  }

  init(newFilterList?: any) {
    const name = this.$route.name;
    let defaultValueMap: any = {};
    if (
      this.filterOptions.filterOptions[name as string] &&
      !this.skipResetMapWhileInit
    ) {
      defaultValueMap = { ...this.filterOptions.filterOptions[name as string] };
    }
    //重置数据
    // this.filterData = [];
    this.collapseList = [];
    this.filterList = [];

    let showMoreSum = 0;
    // only first time using filterConfig
    let filterParams = newFilterList || this.filterConfig;

    if (this.showFilterSelect && this.selectFilterList.length) {
      filterParams = this.sortFilterBySelect(filterParams);
    }

    /* TODO: 需要处理 prop warning 更新问题 */
    if (newFilterList) {
      this.filterConfig = newFilterList;
    }
    filterParams.forEach(async (item: any) => {
      if (item.hide) {
        this.$delete(this.filterData, item.prop);
        return;
      }
      item.label = i18n(item.label);
      // get data from filterData while value is existed
      /* eslint-disable */
      item.prop instanceof Array
        ? this.$set(
            this.filterData,
            item.prop.join('+'),
            this.filterData[item.prop.join('+')] ?? defaultValueMap[item.prop.join('+')] ?? item.defaultValue ?? undefined
          )
        : this.$set(
            this.filterData,
            item.prop,
            this.filterData[item.prop] ?? defaultValueMap[item.prop] ?? item.defaultValue
          );
      /* eslint-enable */
      this.setInputGroupValue(item);
      const formItemDic = typeDic[item.filterType as keyof typeof typeDic];
      if (typeof formItemDic === 'undefined') {
        throw 'must registered the filtertype type dic';
      }

      // 未勾选的不计算到showMoreSum中
      if (this.showFilterSelect && this.selectFilterList?.length) {
        if (this.selectFilterList.includes(item.tLabel))
          showMoreSum += formItemDic;
      } else {
        showMoreSum += formItemDic;
      }

      if (showMoreSum < 12) {
        this.collapseList.push(item);
      }
      if (typeof item.selections === 'function') {
        item.selections = await item.selections();
      }
      this.filterList.push(item);
    });
    this.showMore = showMoreSum > 11;
  }
  // 当filterConfig有变化的时候，需要调用addItem来重新渲染
  addItem(newFilterList: any) {
    this.init(newFilterList);
  }

  created() {
    this.init();
  }

  changeFilterData(value: any, prop: any) {
    this.filterData[prop] = value;
  }

  input(value: any, prop: string, combinedOptions: string) {
    this.filterData[prop] = value;
    if (combinedOptions === 'select') {
      this.$set(this.filterData, value, undefined);
      this.$delete(this.filterData, this.lastSelect);
      this.lastSelect = value;
    }
    if (combinedOptions === 'input') {
      this.filterData[prop] = value;
    }
  }

  async change(prevValue: any, value: any, prop: string | Array<string>) {
    if (this.onChange) {
      const newSetting = await this.onChange(
        prevValue,
        value,
        prop,
        this.filterConfig,
        this.changeFilterData
      );
      newSetting && this.addItem(newSetting);
    }
  }

  showMoreList() {
    if (!this.autoCollapse) {
      return;
    }
    Object.keys(this.filterData).forEach((key) => {
      if (this.filterData[key] === undefined) return;
      const propItem = this.collapseList.find((item: any) => {
        let prop = item.prop;
        if (Array.isArray(item.prop)) {
          prop = item.prop.join('+');
        }
        return prop === key;
      });

      if (!propItem) {
        this.showCollapse = true;
      }
    });
  }

  getTimeValue(item: string, value: string[]) {
    if (!value) return;
    const res: any = [];
    if (this.shouldUseUTCZeroFormatDate(item)) {
      /* eslint-disable */
      // 使用 UTC zero 转换时间戳
      res[0] =value[0]
        ? getUTCSecondsAtZero(
            Math.ceil(new Date(value[0]).valueOf())
          )
        : undefined;
      res[1] = value[1]
        ? getUTCSecondsAtZero(
            Math.ceil(new Date(value[1]).valueOf())
          )
        : undefined;
    } else {
      // 使用仓库时区转换时间戳
      res[0] =value[0]
        ? (getUTCSecondsBasedWhs(
            Math.ceil(new Date(value[0]).valueOf() / 1000)
          )as number)*1000
        : undefined;
      res[1] =value[1]
        ? (getUTCSecondsBasedWhs(
            Math.ceil(new Date(value[1]).valueOf() / 1000)
          ) as number)*1000
        : undefined;
    }
    return res;
  }

  getFilterData() {
    const res: any = {};
    Object.keys(this.filterData).forEach((item) => {
      if (this.filterData[item] === undefined || this.filterData[item] === '') {
        return;
      }
      if (item.indexOf('+') >= 0 && Array.isArray(this.filterData[item])) {
        const left = item.split('+')[0];
        const right = item.split('+')[1];
        // input range 方式
        if (this.shouldUseInputRangeFormat(item)) {
          res[left] = this.filterData[item][0];
          res[right] = this.filterData[item][1];
        }else if (this.shouldUseCardFilterFormat(item)) {
          // card 方式
          const value = this.filterData[item][0]
          if(!value || !value.length) return

          if(!Array.isArray(value)){
            res[left] = value
              .replace(/(\s|,|，|\.)/g, ' ')
              ?.split(' ')
              .map((_:any) => _.trim()).join(',');
          }else{
            res[left] = this.filterData[item][0].join(',');
          }
          if(right){
            res[right] = this.filterData[item][1];
          }

        }else {
          res[left] = Math.ceil(this.filterData[item][0]/1000)
          res[right] = Math.ceil(this.filterData[item][1]/1000)
        }
      } else {
        res[item] = this.filterData[item];
      }

      if (Array.isArray(res[item]) && res[item].length > 0) {
        res[item] = res[item].join(',');
      }
      //判断是否是Date类型，转换成int
      if (res[item] instanceof Date) {
        res[item] = new Date(res[item]).valueOf();
      }
    });
    return res;
  }

  /* 是否使用utc zero 方式格式化时间戳 */
  shouldUseUTCZeroFormatDate(key: string) {
    return this.filterConfig.find((item: filterConfigItem) => {
      if (Array.isArray(item.prop)) {
        if (key === `${item.prop[0]}+${item.prop[1]}`) {
          return item.useUTCZeroFormatDate;
        }
      }
    });
  }

  /* 是否使用input range 格式化数据 */
  shouldUseInputRangeFormat(key: string) {
    return this.filterConfig.find((item: filterConfigItem) => {
      if (
        Array.isArray(item.prop) &&
        (item.filterType === 'inputrange' ||
          item.filterType === 'inputnumberrange' ||  item.filterType === 'small-input-number-range')
      ) {
        if (key === `${item.prop[0]}+${item.prop[1]}`) {
          return true;
        }
      }
    });
  }

  /* 是否使用card 格式化数据 */
  shouldUseCardFilterFormat(key: string) {
    return this.filterConfig.find((item: filterConfigItem) => {
      if (
        Array.isArray(item.prop) &&
        (item.filterType === 'text-card' || item.filterType === 'select-card' || item.filterType === 'big-text-card' || item.filterType === 'big-select-card')
      ) {
        if (key === `${item.prop[0]}+${item.prop[1]}`) {
          return true;
        }
      }
    });
  }

  // 清空 filterData
  clearFilterData() {
    Object.keys(this.filterData).forEach(
      (key) => (this.filterData[key] = undefined)
    );
    this.init();
  }

  /* set default select value */
  setInputGroupValue(item: any) {
    if (inputGroupType.includes(item.filterType)) {
      this.$set(this.filterData, item.selectProp, item.defaultSelectValue);
    }
  }

  /**
   * 将其他参数保存到 store
   * @param params - 保存到 store 的其他参数
   */
  setOtherParamsToStore(params: any) {
    const routeName = this.$route.name?.toString();
    const obj: any = {
      [routeName!]: {
        ...params,
      },
    };
    Store.dispatch('getSortOptions', obj);
  }

  render(h: CreateElement): VNode {
    // eslint-disable-next-line
    const _tmp = this.filterConfig; //这个变量没啥用的，就是为了filterConfig改变，触发render重新熏染
    const filterPart = spreadFilterForm(this, h, this.input);
    const filterButtons = getFilterButtons(this, h);
    const submit = (e: any) => {
      e.preventDefault();
    };
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    return (
      <s-form class="s-filter-part" ref="s-filter" nativeOnSubmit={submit}>
        {filterPart}
        {this.noSearch ? undefined : filterButtons}
      </s-form>
    );
  }
}
