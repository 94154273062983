<template>
  <s-menu-item :key="item.name" :name="item.name" :index="item.index">
    <router-link class="ellipsis-item menu-quota" :to="{ name: item.name }">
      <span class="ellipsis-text">{{ $i18n(item.meta.title) }}</span>
    </router-link>
    <span
      v-if="
        $hasPermission('PC.RuleCenter.QuotaConfig.ASNQuotaConfiguration.Switch')
      "
      @click.stop="handleClickSwitch"
    >
      <svg-icon
        style="width: 16px; height: 16px"
        icon-class="cycle-count-switcher"
        class="cycle-count-switcher-icon"
      />
    </span>
  </s-menu-item>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import Store from '@/store';
import { ElLoadingComponent } from 'element-ui/types/loading';
import { switchAsnQuotaMode } from '@/api/wms-config/quota-config';

@Component
export default class AsnQuotaConfigMenuItem extends Vue {
  // @Prop() readonly openLink: Function;
  @Prop() readonly item: Record<string, any>;

  loadingRef: null | ElLoadingComponent = null;

  async handleClickSwitch() {
    try {
      const houseDetail = Store.state.warehouseDetail?.warehouse;
      const curQuotaMode = houseDetail?.quota_mode || 0;
      if (!curQuotaMode) {
        await this.$confirm(
          this.$i18n('Confirm to switch into Separated Quota?'),
          'Notice'
        );
      } else {
        await this.$confirm(
          this.$i18n('Confirm to switch into Integrated Quota?'),
          'Notice'
        );
      }
      this.loadingRef = this.$loading({
        fullscreen: true,
        text: this.$i18n('Loading'),
      });
      await switchAsnQuotaMode({
        current_mode: +curQuotaMode,
        new_mode: +!curQuotaMode,
      });
      (this as any).loadingRef.close();

      houseDetail.quota_mode = +!curQuotaMode;
      Store.commit('setWarehouseDetail', houseDetail);
      await this.$message.success(this.$i18n('Switch successfully!'));
      if (this.$route.name === 'ruleCenter.quotaConfig.asnQuotaConfig.edit') {
        this.$router.push({ name: 'ruleCenter.quotaConfig.asnQuotaConfig' });
      }
    } catch (e) {
      if ((e as any).retcode) {
        await this.$message.error(
          this.$i18n('Switch failed, please try again later!')
        );
      }
    } finally {
      this.loadingRef && (this.loadingRef as ElLoadingComponent).close();
    }
  }
}
</script>

<style scoped lang="scss">
.ellipsis-item {
  display: flex;

  .ellipsis-text {
    overflow: hidden;
    width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

::v-deep .menu-quota {
  color: rgba(255, 255, 255, 0.6);

  &:hover {
    color: rgb(255, 255, 255);
  }

  &::before {
    position: absolute;
    top: 0;
    right: 25px;
    width: 100%;
    height: 100%;
    content: '';
  }
}
// ::v-deep {
//   .ssc-menu-item-selected .menu-item {
//     font-weight: 700;
//     color: rgb(255, 255, 255);
//   }
// }
.cycle-count-switcher-icon {
  vertical-align: middle;
  // margin-left: 10px;
}
</style>
