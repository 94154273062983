import { isEmpty, omit } from 'lodash';
import { getEnv, getPFB } from '@/utils/getEnv';
import { getCookie } from '@/utils/cookie';
import api from '@/api/login/login';

export function getCapitalizeEnv() {
  const regex = /https:\/\/wms\.ssc\.(\w+)\.shopee\.\w+/;
  const match = window.location.origin.match(regex);
  let env = match && match[1];

  if (isEmpty(env)) {
    const pfbTag = getPFB();
    env = pfbTag === 'PFB' ? 'test' : getEnv().toLowerCase();
  }

  if (isEmpty(env) || env === 'dev') {
    env = 'test';
  }
  return env;
}

export function getTobDomain() {
  const env = getCapitalizeEnv();

  if (env === 'live') {
    return 'https://wms.business.accounts.shopee.com';
  }

  return `https://wms.business.accounts.${env}.shopee.com`;
}

const langMap: { [key: string]: string } = {
  'en': 'en',
  'id': 'id',
  'ms-MY': 'ms',
  'pt-BR': 'pt-BR',
  'sp-MX': 'es-MX',
  'th': 'th',
  // 'tl-PH': '',
  'vi': 'vi',
  'zh-CN': 'zh-Hans',
  'zh-TW': 'zh-Hant',
};

export function getLang() {
  const lang = getCookie('setting_lang');
  const mappingLang = langMap?.[lang ?? 'en'];
  return mappingLang ?? 'en';
}

export function getRedirectUrl() {
  const List = ['/403', '/404'];
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  let redirectUrl = queryParams.get('redirect_url');
  const canRedirect = !List.some((item) => redirectUrl?.includes(item));

  if (window.location.origin.startsWith('http://localhost')) {
    return encodeURIComponent('https://wms.ssc.test.shopee.sg/v2/home');
  }

  if (isEmpty(redirectUrl) || !canRedirect) {
    redirectUrl = encodeURIComponent(`${window.location.origin}/v2/home`);
  }
  return redirectUrl as string;
}

export function getNextUrl() {
  if (window.location.origin.startsWith('http://localhost')) {
    return 'https://wms.ssc.test.shopee.sg/v2/tob/callback';
  }
  return `${window.location.origin}/v2/tob/callback`;
}

export function getGoogleRedirectUrl() {
  if (window.location.origin.startsWith('http://localhost')) {
    return 'https://wms.ssc.test.shopee.sg/v2/google/login';
  }
  return `${window.location.origin}/v2/google/login`;
}

export async function checkLoginSwitch() {
  return await api
    .enableTobLogin()
    .then((res) => {
      const configValue =
        res?.data?.list?.find?.(
          (item: any) => item.configKey === 'enable_tob_login'
        )?.configValue ?? '0';
      return configValue === '1';
    })
    .catch((_e) => {
      return false;
    });
}

export async function getTobURL(path: string, omitBy: string[] = []) {
  const client_id = '19';
  const next = getNextUrl();
  const googleRedirectUrl = getGoogleRedirectUrl();
  const urlParams = new URLSearchParams();
  const lang = getLang();
  const params: Record<string, string> = {
    lang,
    client_id,
    next,
    google_login_redirect: googleRedirectUrl,
  };
  const omitParams: any = omit(params, omitBy);
  for (const key in omitParams) {
    urlParams.set(key, omitParams[key]);
  }
  return `${getTobDomain()}${path}?${urlParams.toString()}`;
}
