import { addComponentInstance, rmComponentInstance } from './components';

declare type ObjectWithAnyKey = { [key: string]: any };

export default {
  beforeCreate(this: ObjectWithAnyKey) {
    addComponentInstance(this);
    this._subscribing = true;
  },

  beforeDestroy(this: ObjectWithAnyKey) {
    if (this._subscribing) {
      rmComponentInstance(this);
      delete this._subscribing;
    }
  },
};
