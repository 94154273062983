export default [
  {
    path: 'scanLog',
    name: 'laborManagement.scanLog',
    meta: {
      title: 'Scan Log',
    },
    component: () => import('@/views/labor-management/scan-log/index.vue'),
  },
];
