<template>
  <s-select
    ref="filter_select"
    class="filter-select"
    v-model="value"
    placeholder="Select a city"
    :automatic-dropdown="true"
    :clearable="true"
    :need-confirm="true"
    :filterable="true"
    @change="changeFilter"
    @clear="resetFilter"
  >
    <template v-slot:reference>
      <SIconSetting class="setting-svg" />
    </template>
    <s-table
      :data.sync="tableData"
      row-key="name"
      class="g-filter-table"
      :row-selection="rowSelection"
      align="left"
      :style="{ '--table-height': tableHeight }"
    >
      <s-table-column :width="8" type="selection" />
      <s-table-column prop="name" :label="$i18n('Select All')" :width="100" />
      <s-table-column label="" :width="10">
        <template v-slot="scope">
          <img v-if="scope" class="handle" src="@/assets/icons/anchor.svg" />
        </template>
      </s-table-column>
    </s-table>
  </s-select>
</template>

<script lang="ts">
import {
  createTemplate,
  getTemplateList,
  updateTemplate,
} from '@/components/store-service/api';
import { TrackingHistory } from 'react-pro-components';
import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class FilterSelect extends Vue {
  @Prop({ default: () => [] })
  allFilter: string[];
  @Prop() keyName: string;
  pickDayCondition: Function | null;
  tableData: { name: string }[] = [];
  value = [];
  templateID = undefined;

  get tableHeight() {
    const height = this.allFilter.length * 32 + 5;
    return height + 'px';
  }

  rowSelection: any = {
    selectedRowKeys: [],
    onChange: this.onSelectChange,
  };

  initFilterData() {
    this.tableData = this.allFilter.map((item) => {
      return { name: item };
    });
  }

  searchFilterData(inputValue: string) {
    if (!inputValue) {
      this.initFilterData();
    } else {
      this.tableData = this.allFilter
        .filter((item) => item.includes(inputValue))
        .map((item) => {
          return { name: item };
        });
    }
  }

  mounted() {
    this.$watch(
      () => (this.$refs['filter_select'] as any).filterText,
      (newValue, oldValue) => {
        this.searchFilterData(newValue);
      }
    );
    this.getList();
  }

  async getList() {
    const res = await getTemplateList(this.keyName);
    this.templateID = res[0]?.template_id;

    // 首次使用 和 reset之后data为空的情况
    if (!res.length || !res[0].data) {
      this.initFilterData();
      this.rowSelection.selectedRowKeys = this.allFilter;
      this.$emit('filerSelectChange', []);
    } else {
      {
        this.templateID = res[0]?.template_id;
        this.setSelectValue(res[0]?.data);
      }
    }
  }

  onSelectChange(rows: any, rowData: any) {
    this.rowSelection.selectedRowKeys = rows;
  }

  setSelectValue(value: string) {
    const params = JSON.parse(value);
    const tableResult: { name: string }[] = [];
    const tableSelect: string[] = [];

    Object.keys(params).forEach((key) => {
      if (this.allFilter.includes(key)) {
        tableResult.push({ name: key });

        if (params[key]) {
          tableSelect.push(key);
        }
      }
    });

    // allFilter含有params没有存储的数据的情况
    this.allFilter.forEach((item: string, index) => {
      // eslint-disable-next-line no-prototype-builtins
      if (!params.hasOwnProperty(item)) {
        tableResult.splice(index, 0, { name: item });
        tableSelect.splice(index, 0, item);
      }
    });

    this.tableData = tableResult;
    this.rowSelection.selectedRowKeys = tableSelect;
    const visibleFilter = this.tableData
      .filter((item) => this.rowSelection.selectedRowKeys.includes(item.name))
      .map((item) => item.name);
    this.$emit('filerSelectChange', visibleFilter);
  }

  // 勾选为true 未勾选设置为false
  formatSelectData() {
    const result: any = {};
    this.tableData.forEach((item) => {
      if (this.rowSelection.selectedRowKeys.includes(item.name)) {
        result[item.name] = true;
      } else {
        result[item.name] = false;
      }
    });
    return JSON.stringify(result);
  }

  async changeFilter() {
    if (!this.rowSelection.selectedRowKeys.length) {
      this.$message.error(this.$i18n('Must be at least one filter!'));
      return;
    }
    const data = this.formatSelectData();
    if (this.templateID === undefined) {
      await createTemplate(data, this.keyName, 'FilterSelect');
    } else {
      await updateTemplate(this.templateID, data);
    }

    const visibleFilter = this.tableData
      .filter((item) => this.rowSelection.selectedRowKeys.includes(item.name))
      .map((item) => item.name);
    this.$emit('filerSelectChange', visibleFilter);
  }

  async resetFilter() {
    this.rowSelection.selectedRowKeys = this.allFilter;
    if (this.templateID === undefined) {
      await createTemplate('', this.keyName, 'FilterSelect');
    } else {
      await updateTemplate(this.templateID, '');
    }
    this.$emit('filerSelectChange', []);

    this.$nextTick(() => {
      this.initFilterData();
    });
  }
}
</script>

<style scoped lang="scss">
.filter-select {
  width: 34px !important;
  border: 1px solid #ecf0f4;
  border-radius: 4px !important;
  margin-left: 16px;

  &:hover {
    background: #f5f5f5;
  }

  .setting-svg {
    width: 16px;
    margin: 8px;
    color: #959ba4;
  }
}
.g-filter-table {
  max-height: 400px;
  border: none;

  ::v-deep .ssc-table-fixed-none {
    .ssc-table-header-ruler:before {
      content: none; /* 或者 content: none; */
    }
    .ssc-table-header-scroll-bar-wrapper .simplebar-wrapper {
      height: 32px;
    }
    & tr td {
      height: 32px !important;
      border-bottom: none;
    }

    & td {
      padding: 4px 0px 4px;
    }
    & tr th {
      background-color: #fff !important;
      height: 32px !important;
      padding: 4px 0px 4px;
      font-weight: 400;
    }
    .simplebar-horizontal {
      height: 0px;
    }
    .simplebar-content-wrapper {
      height: 100% !important;
      .first-row {
        display: none;
      }
    }

    .ssc-table-body .simplebar-placeholder {
      height: var(--table-height) !important;
    }
  }
}
</style>
