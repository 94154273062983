interface DynamicTitleMap {
  [key: string]: {
    handler: () => Promise<boolean>;
    title: string;
  };
}

// 根据权限过滤不需要处理的路由
export const filterByPermission: (
  config: DynamicTitleMap,
  permissionMenu: string[]
) => DynamicTitleMap = (config, permissionMenu) => {
  const keys = Object.keys(config).filter((key) =>
    permissionMenu.includes(key)
  );
  return keys.reduce((acc, key) => {
    acc[key] = { ...config[key] };
    return acc;
  }, {} as DynamicTitleMap);
};

// 并发拉取业务接口，没有使用 Promise.all，所以即使单个接口出错也不影响
export const asyncConfigQueue = async (config: DynamicTitleMap) => {
  const result: Record<string, string> = {};
  try {
    await Object.keys(config)
      .map((key) => [config[key].handler(), key])
      .reduce((task, [handler, key]) => {
        return task
          .then(() => handler as Promise<boolean>)
          .then((res) => {
            if (res) {
              result[key as string] = config[key as string].title;
            }
          })
          .catch();
      }, Promise.resolve());
    return result;
  } catch (err) {
    return {};
  }
};

export const changeMenuList = (
  menuList: any,
  resultMap: Record<string, string>
) => {
  //目前 menuList 只有两层，暂时不使用递归
  const newMenuList = menuList.map((item: any) => {
    if (resultMap[item.name]) {
      console.log(resultMap[item.name]);
      return {
        ...item,
        title: resultMap[item.name],
      };
    }
    if (Array.isArray(item.children)) {
      item.children = item.children.map((child: any) => {
        if (resultMap[child.name]) {
          console.log(resultMap[child.name]);
          return {
            ...child,
            meta: {
              ...child.meta,
              title: resultMap[child.name],
            },
          };
        } else {
          return { ...child };
        }
      });
    }
    return { ...item };
  });
  return newMenuList;
};

export const patchNavbarItemTitle = (item: any, config: DynamicTitleMap) => {
  if (config[item.name]) {
    return config[item.name].title;
  } else {
    return item.name;
  }
};

export const changeRouteConfig = (
  routerConfig: any,
  resultMap: Record<string, string>
) => {
  const newRouterConfig = routerConfig.map((item: any) => {
    if (resultMap[item.name]) {
      return {
        ...item,
        title: resultMap[item.name],
      };
    }
    if (Array.isArray(item.children)) {
      item.children = item.children.map((child: any) => {
        const newChild = { ...child };
        if (resultMap[child.name]) {
          newChild.meta = {
            ...child.meta,
            title: resultMap[child.name],
          };
        }
        return newChild;
      });
    }
    return { ...item };
  });
  return newRouterConfig;
};
