<template>
  <div class="app-wrapper">
    <sidebar class="sidebar-container"></sidebar>
    <app-main class="app-main" :class="padClass"></app-main>
    <wms-portal-target name="app-page-portal-target" />
  </div>
</template>

<script lang="ts">
import { AppMain } from './components';
import Sidebar from './components/sidebar/index.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    Sidebar,
    AppMain,
  },
})
export default class Layout extends Vue {
  name = 'layout';
  isPad = false;

  created() {
    this.isPad = /NQ1000/gi.test(window.navigator.userAgent);
  }

  get padClass() {
    return this.isPad ? 's-pad-width' : '';
  }
}
</script>

<style scoped lang="scss">
.app-wrapper {
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.app-main {
  width: 100%;
  min-width: 1126px;
  flex-direction: column;

  &.s-pad-width {
    min-width: 900px;
  }
}
.app-container {
  flex: 1;
}
</style>
