const config = {
  storageKey: 'sp-locale',
  defaultLang: 'en',
  componentUpdateMethodName: '$forceUpdate',
};
function setConfig(opts: Partial<typeof config>) {
  Object.assign(config, opts);
}

export { setConfig, config };
