<template>
  <s-menu-item
    v-if="showMenu"
    :key="item.name"
    :name="item.name"
    :index="item.index"
    @click.native="openLink(item.name)"
  >
    <router-link class="ellipsis-item menu-item" :to="{ name: item.name }">{{
      $i18n(item.meta.title)
    }}</router-link>
  </s-menu-item>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import Store from '@/store';
import {
  DASHBOARD_CYCLE_COUNT_ROUTE_NAME,
  DASHBOARD_FULL_STOCK_COUNT_ROUTE_NAME,
} from '@/router/constant';
import { ElLoadingComponent } from 'element-ui/types/loading';

@Component
export default class CycleCountDashMenuItem extends Vue {
  @Prop() readonly openLink: Function;
  @Prop() readonly item: Record<string, any>;

  loadingRef: null | ElLoadingComponent = null;

  get currentCountRouteName() {
    const status = Store.state.cycleCount.isFullStockCount;
    return status
      ? DASHBOARD_FULL_STOCK_COUNT_ROUTE_NAME
      : DASHBOARD_CYCLE_COUNT_ROUTE_NAME;
  }

  get showMenu() {
    const { item, currentCountRouteName } = this;
    return item?.name === currentCountRouteName;
  }
}
</script>

<style scoped lang="scss"></style>
