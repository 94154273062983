import Layout from '../../views/layout/index.vue';

const controlManagement = {
  path: '/controlManagement',
  name: 'controlManagement',
  meta: {
    title: 'Control Management',
    icon: 'Inbound',
  },
  redirect: '/controlManagement/postPackMachine',
  component: () => import('@/views/layout/index.vue'),
  children: [
    {
      path: '/controlManagement/postPackMachine',
      name: 'controlManagement.postPackMachine',
      meta: {
        title: 'Post-pack Machine',
      },
      component: () =>
        import(
          '../../views/control-management/post-pack-machine/sortingLane.vue'
        ),
    },
    {
      path: 'autoShip',
      name: 'controlManagement.postPackMachine.autoShip',
      meta: {
        title: 'Post-pack Machine',
        menuHide: true,
      },
      component: () =>
        import('../../views/control-management/post-pack-machine/autoShip.vue'),
    },
  ],
};

export default controlManagement;
