/**
 * code copy from my stock
 * https://git.garena.com/shopee/bg-logistics/wms-lite/wms-lite-fe/-/blob/feature/SWIM-10325-multi-warehouse-pre-version/apps/web/src/utils/version.ts
 * design doc:
 * https://confluence.shopee.io/pages/viewpage.action?pageId=1483156442
 */
const MAX_EXCEED_LENGTH = 60;

const cutOutput = (output: Record<string, any>) => {
  const newOutput: Record<string, any> = {};
  const keys = Object.keys(output);
  keys.forEach((key) => {
    newOutput[key.slice(0, MAX_EXCEED_LENGTH)] = `${
      output[key].length > MAX_EXCEED_LENGTH
        ? `${output[key].slice(0, MAX_EXCEED_LENGTH)}...`
        : output[key]
    }`;
  });
  return newOutput;
};

const getMaxLengthOfOutput = (output: Record<string, any>) => {
  const keys = Object.keys(output);
  let keyMaxLength = 0;
  let valueMaxLength = 0;
  keys.forEach((key) => {
    keyMaxLength = Math.max(keyMaxLength, key.length);
    valueMaxLength = Math.max(valueMaxLength, output[key].length);
  });
  return [keyMaxLength, valueMaxLength];
};

const fillToMaxLength = (str: string, maxLength: number) => {
  return str.concat(
    new Array(Math.max(maxLength - str.length, 0)).fill(' ').join('')
  );
};

const parseOutputWithinMaxLength = (
  output: Record<string, any>,
  keyMaxLength: number,
  valueMaxLength: number
): Record<string, string> => {
  const newOutput: Record<string, any> = {};
  const keys = Object.keys(output);
  keys.forEach((key) => {
    newOutput[fillToMaxLength(key, keyMaxLength)] = fillToMaxLength(
      output[key],
      valueMaxLength
    );
  });
  return newOutput;
};

const parseOutput = (output: Record<string, any>) => {
  let outputString = '%c🚗 Deploy Info';
  let outputStyle = ['color: #ee4d2d;padding: 10px;'];

  const keys = Object.keys(output);
  keys.forEach((key, index) => {
    outputString = outputString
      .concat('%c\n')
      .concat(`%c ${key} %c ${output[key]}`);

    outputStyle = outputStyle.concat([
      'background-color: transparent',

      // name
      `background-color: ${
        index === 0 ? '#113366' : index % 2 === 0 ? '#66666660' : 'transparent'
      };padding: 10px;margin-left: 10px;border:1px solid #999999; color: ${
        index === 0 ? 'white' : undefined
      };font-weight: bold`,

      // value
      `background-color: ${
        index === 0 ? '#113366' : index % 2 === 0 ? '#66666660' : 'transparent'
      };padding: 10px;border:1px solid #999999; color: ${
        index === 0 ? 'white' : undefined
      };font-weight: ${index === 0 ? 'bold' : undefined}`,
    ]);
  });
  return [
    outputString.concat('%c\n').concat('%c🚗 Deploy Info '),
    outputStyle.concat([
      'background-color: transparent',
      'color: #ee4d2d;padding: 10px;',
    ]),
  ];
};

export const logBuildInfo = () => {
  /**
   * 注意 window.__JENKINS_BUILD_MSG__ 是 webpack 提供的编译时变量，
   * 执行时 window 并不存在 __JENKINS_BUILD_MSG__ 这个属性
   */
  const JENKINS_INFO = window.__JENKINS_BUILD_MSG__ || {};
  if (!JENKINS_INFO?.env) {
    return;
  }

  // 手动在执行时对 window 进行赋值，后续可以访问
  (window as any).__MSG__ = window.__JENKINS_BUILD_MSG__;

  const originOutput = {
    Name: 'Value',
    ...JENKINS_INFO,
  };
  const afterCutOutput = cutOutput(originOutput);
  const [keyMaxLength, valueMaxLength] = getMaxLengthOfOutput(afterCutOutput);
  const output = parseOutputWithinMaxLength(
    afterCutOutput,
    keyMaxLength,
    valueMaxLength
  );
  const [outputString, outputStyle] = parseOutput(output);

  console.log(outputString, ...outputStyle, '\n ', originOutput);
};

export function consoleBuildMsg() {
  if (window?.__JENKINS_BUILD_MSG__?.env !== 'live') {
    logBuildInfo();
  }
}
