import reactContainerFactory from '@/components/react-container/reactContainerFactory';
import { lazy } from 'react';

const salesOutbound = {
  path: '/salesoutbound',
  name: 'salesOutbound',
  meta: {
    title: 'Sales Outbound',
    icon: 'Sales Outbound',
  },
  redirect: '/salesoutbound/order',
  component: () => import('@/views/layout/index.vue'),
  children: [
    {
      path: 'order',
      name: 'salesOutbound.order',
      meta: {
        title: 'Order',
        filterSave: true,
      },
      component: () => import('../../views/sales-outbound/order/index.vue'),
    },
    {
      path: 'cancel',
      name: 'salesOutbound.order.cancel',
      meta: {
        title: 'Cancel After Checking Order',
        menuHide: true,
        filterSave: true,
      },
      component: () => import('../../views/sales-outbound/order/index.vue'),
    },
    {
      path: 'order/create',
      name: 'salesOutbound.order.create',
      meta: {
        title: 'Create',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.order',
            title: 'Order',
          },
        ],
      },
      component: () =>
        import('../../views/sales-outbound/order/sales-outbound/create.vue'),
    },
    {
      path: 'order/detail/:id',
      name: 'salesOutbound.order.detail',
      meta: {
        title: 'Order Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.order',
            title: 'Order',
          },
        ],
      },
      component: () =>
        import('../../views/sales-outbound/order/sales-outbound/detail.vue'),
    },
    {
      path: 'childlmtn',
      name: 'salesOutbound.order.childlmtn',
      meta: {
        menuHide: true,
        title: 'Child LMTN',
        icon: '',
        filterSave: true,
      },
      component: () => import('../../views/sales-outbound/order/index.vue'),
    },
    {
      path: 'childlmtn/detail/:id',
      name: 'salesOutbound.order.childlmtn.detail',
      meta: {
        menuHide: true,
        title: 'Child LMTN Detail',
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.order.childlmtn',
            title: 'Child LMTN',
          },
        ],
      },
      component: () =>
        import('../../views/sales-outbound/order/child-lmtn/detail.vue'),
    },
    {
      path: 'waveplan',
      name: 'salesOutbound.wavePlan',
      meta: {
        title: 'Wave Plan',
        filterSave: true,
      },
      component: () => import('../../views/sales-outbound/wave-plan/index.vue'),
    },
    {
      path: 'waveLog',
      name: 'salesOutbound.waveLog',
      meta: {
        title: 'Wave Log',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.wavePlan',
            title: 'Wave Plan',
          },
        ],
        filterSave: true,
      },
      component: () => import('@/views/sales-outbound/wave-log/index.vue'),
    },
    {
      path: 'waveLog/detail/:wave_sn/is_manual_wave=:is_manual_wave',
      name: 'salesOutbound.waveLog.detail',
      meta: {
        title: 'View',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.wavePlan',
            title: 'Wave Plan',
          },
          {
            routeName: 'salesOutbound.waveLog',
            title: 'Wave Log',
          },
        ],
      },
      component: () => import('@/views/sales-outbound/wave-log/detail.vue'),
    },
    // 新增 Manual Wave 界面
    {
      path: 'manualWave',
      name: 'salesOutbound.manualwave',
      meta: {
        title: 'Manual Wave',
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
        ],
      },
      component: () =>
        import('@/views/sales-outbound/wave-plan/manual-wave/index.vue'),
    },
    {
      path: 'flowPick',
      name: 'salesOutbound.flowpick',
      meta: {
        title: 'Flow Pick',
      },
      component: reactContainerFactory({
        name: 'salesOutbound.flowpick',
      }),
      props: {
        component: () =>
          lazy(() => import('@/react-app/pages/sales-outbound/flow-pick')),
      },
    },
    {
      path: 'waveList',
      name: 'salesOutbound.wavelist',
      meta: {
        title: 'Wave List',
      },
      component: reactContainerFactory({
        name: 'salesOutbound.wavelist',
      }),
      props: {
        component: () =>
          lazy(
            () => import('@/react-app/pages/sales-outbound/wave-list/index')
          ),
      },
    },
    {
      path: 'task',
      name: 'salesOutbound.task',
      meta: {
        title: 'Task',
      },
      redirect: '/salesoutbound/task/subPicking',
      component: () => import('../../views/layout/subPage.vue'),
    },
    {
      path: 'task/picking',
      name: 'PC.SalesOutbound.Task.PickingTask.View',
      meta: {
        title: 'Picking',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.task',
            title: 'Task',
          },
        ],
        filterSave: true,
      },
      component: () =>
        import('../../views/sales-outbound/task/sales-outbound/index.vue'),
    },
    {
      path: 'task/transiting',
      name: 'PC.SalesOutbound.Task.TransitingTask.View',
      meta: {
        title: 'Merging',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.task',
            title: 'Task',
          },
        ],
        filterSave: true,
      },
      component: () =>
        import('../../views/sales-outbound/task/sales-outbound/index.vue'),
    },
    {
      path: 'task/subPicking',
      name: 'PC.SalesOutbound.Task.SubPickingTask.View',
      meta: {
        title: 'Sub Picking',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.task',
            title: 'Task',
          },
        ],
        filterSave: true,
      },
      component: () =>
        import('../../views/sales-outbound/task/sales-outbound/index.vue'),
    },
    {
      path: 'task/preSorting',
      name: 'PC.SalesOutbound.Task.PreSortingTask',
      meta: {
        title: 'Pre Sorting',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.task',
            title: 'Task',
          },
        ],
        filterSave: true,
      },
      component: () =>
        import('../../views/sales-outbound/task/sales-outbound/index.vue'),
    },
    {
      path: 'preSortingTask/detail/:task_number',
      name: 'salesOutbound.preSortingTaskDetail',
      meta: {
        title: 'Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'PC.SalesOutbound.Task.PreSortingTask',
            title: 'Pre Sorting Task',
          },
        ],
      },
      component: () =>
        import(
          '../../views/sales-outbound/task/sales-outbound/components/pre-sorting-task-detail.vue'
        ),
    },
    {
      path: 'task/sorting',
      name: 'PC.SalesOutbound.Task.SortingTask',
      meta: {
        title: 'Sorting',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.task',
            title: 'Task',
          },
        ],
        filterSave: true,
      },
      component: () =>
        import('../../views/sales-outbound/task/sales-outbound/index.vue'),
    },
    {
      path: 'task/packing',
      name: 'PC.SalesOutbound.Task.PackingTask.View',
      meta: {
        title: 'Packing',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.task',
            title: 'Task',
          },
        ],
        filterSave: true,
      },
      component: () =>
        import('../../views/sales-outbound/task/sales-outbound/index.vue'),
    },
    {
      path: 'task/checking',
      name: 'PC.SalesOutbound.Task.CheckingTask.View',
      meta: {
        title: 'Checking',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.task',
            title: 'Task',
          },
        ],
        filterSave: true,
      },
      component: () =>
        import('../../views/sales-outbound/task/sales-outbound/index.vue'),
    },
    {
      path: 'task/reversePicking',
      name: 'PC.SalesOutbound.Task.ReversePickingTask.View',
      meta: {
        title: 'Reverse Picking',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.task',
            title: 'Task',
          },
        ],
        filterSave: true,
      },
      component: () =>
        import('../../views/sales-outbound/task/sales-outbound/index.vue'),
    },
    {
      path: 'task/subPicking/detail/:sub_picking_id',
      name: 'salesOutbound.task.subPickingTaskDetail',
      meta: {
        title: 'Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.task',
            title: 'Task',
          },
          {
            routeName: 'PC.SalesOutbound.Task.SubPickingTask.View',
            title: 'Sub Picking',
          },
        ],
      },
      component: () =>
        import(
          '../../views/sales-outbound/task/sales-outbound/components/sub-picking-task-detail.vue'
        ),
    },
    {
      path: 'task/reversePicking/:task_number',
      name: 'salesOutbound.task.reversePickingDetail',
      meta: {
        title: 'Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.task',
            title: 'Task',
          },
          {
            routeName: 'PC.SalesOutbound.Task.ReversePickingTask.View',
            title: 'Reverse Picking',
          },
        ],
      },
      component: () =>
        import(
          '../../views/sales-outbound/task/sales-outbound/components/reverse-picking-task-detail.vue'
        ),
    },
    // 新增 Delivering Task
    {
      path: 'task/deliverTask',
      name: 'PC.SalesOutbound.Task.DeliveringTask',
      meta: {
        title: 'Delivering Task',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.task',
            title: 'Task',
          },
        ],
        filterSave: true,
      },
      component: () =>
        import('../../views/sales-outbound/task/sales-outbound/index.vue'),
    },
    {
      path: 'task/deliverTask/detail/',
      name: 'PC.SalesOutbound.Task.DeliveringTask.Action.View',
      meta: {
        title: 'Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.task',
            title: 'Task',
          },
          {
            routeName: 'PC.SalesOutbound.Task.DeliveringTask',
            title: 'Delivering Task',
          },
        ],
      },
      component: () =>
        import(
          '../../views/sales-outbound/task/sales-outbound/components/deliver-task-detail.vue'
        ),
    },
    {
      path: 'task/checking/detail/:task_number',
      name: 'salesOutbound.task.checkingDetail',
      meta: {
        title: 'Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.task',
            title: 'Task',
          },
          {
            routeName: 'PC.SalesOutbound.Task.CheckingTask.View',
            title: 'Checking',
          },
        ],
      },
      component: () =>
        import(
          '../../views/sales-outbound/task/sales-outbound/components/checking-task-detail.vue'
        ),
    },
    {
      path: 'task/reversePicking',
      name: 'salesOutbound.task.reversePicking',
      meta: {
        title: 'Reverse Picking',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.task',
            title: 'Task',
          },
        ],
      },
      component: () =>
        import('../../views/sales-outbound/task/sales-outbound/index.vue'),
    },
    {
      path: 'picking/:pickup_id?',
      name: 'salesOutbound.picking',
      meta: {
        title: 'Picking',
      },
      component: () => import('../../views/sales-outbound/picking/index.vue'),
    },
    {
      path: 'mergingwhilesorting/:station_id?',
      name: 'salesOutbound.mergingWhileSorting',
      meta: {
        title: 'Merging While Sorting',
      },
      component: () =>
        import('../../views/sales-outbound/merging-while-sorting/index.vue'),
    },
    {
      path: 'mergeLane',
      name: 'salesOutbound.mergeLane',
      meta: {
        title: 'Merge Lane',
      },
      component: () =>
        import('../../views/sales-outbound/mergeLane/merge-lane.vue'),
    },
    {
      path: 'pickingTask',
      name: 'salesOutbound.pickingTask',
      meta: {
        title: 'Picking Task',
        menuHide: true,
      },
      component: () =>
        import('../../views/sales-outbound/mergeLane/picking-task.vue'),
    },
    {
      path: 'sorting',
      name: 'salesOutbound.sorting',
      meta: {
        title: 'Sorting',
      },
      component: () => import('../../views/sales-outbound/sorting/index.vue'),
    },
    {
      path: 'sortingwhilechecking',
      name: 'salesOutbound.sortingwhilechecking',
      meta: {
        title: 'Sorting While Checking',
      },
      component: () =>
        import('../../views/sales-outbound/sorting-while-checking/index.vue'),
    },
    {
      path: 'checking',
      name: 'salesOutbound.checking',
      meta: {
        title: 'Checking',
      },
      component: () =>
        import('../../views/sales-outbound/checking/switch-checking-root.vue'),
    },
    {
      path: 'packing',
      name: 'salesOutbound.packing',
      meta: {
        title: 'Packing',
      },
      component: () => import('../../views/sales-outbound/packing/index.vue'),
    },
    {
      path: 'shipping',
      name: 'salesOutbound.shipping',
      meta: {
        title: 'Shipping',
        icon: '',
        filterSave: true,
      },
      component: () => import('../../views/sales-outbound/shipping/index.vue'),
    },
    {
      path: 'shipping/detail/:id',
      name: 'salesOutbound.shipDetail',
      meta: {
        title: 'Detail',
        icon: '',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.shipping',
            title: 'Shipping',
          },
        ],
      },
      component: () =>
        import('../../views/task-center/shipping/ship-order.vue'),
    },
    {
      path: 'sortingTask/detail/:task_number',
      name: 'salesOutbound.sortingTaskDetail',
      meta: {
        title: 'Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'PC.SalesOutbound.Task.SortingTask',
            title: 'Sorting Task',
          },
        ],
      },
      component: () =>
        import(
          '../../views/sales-outbound/task/sales-outbound/components/sorting-task-detail.vue'
        ),
    },
    {
      path: 'outbound/detail/:id/:number/:type',
      name: 'salesOutbound.pickingDetail',
      meta: {
        title: 'Picking Task Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.task',
            title: 'Task',
          },
        ],
      },
      component: () =>
        import(
          '../../views/sales-outbound/task/sales-outbound/picking-detail.vue'
        ),
    },
    {
      path: 'transitingTask/detail/:pickup_id',
      name: 'salesOutbound.transitingTaskDetail',
      meta: {
        title: 'Merging Task Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'PC.SalesOutbound.Task.TransitingTask.View',
            title: 'Outbound',
          },
        ],
      },
      component: () =>
        import(
          '../../views/sales-outbound/task/sales-outbound/components/transiting-task-detail.vue'
        ),
    },
    // 新增 Picking Assignment 详情页
    {
      path: 'task/subPicking/detail/assignmentDetail/:assignment_id',
      name: 'salesOutbound.task.subPickingTaskDetail.pickingAssignment',
      meta: {
        title: 'Picking Assignment Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.task',
            title: 'Task',
          },
          {
            routeName: 'PC.SalesOutbound.Task.SubPickingTask.View',
            title: 'Sub Picking',
          },
        ],
      },
      component: () =>
        import(
          '../../views/sales-outbound/task/sales-outbound/components/picking-assignment.vue'
        ),
    },
    // 新增 group log 界面
    {
      path: 'task/subPicking/grouplog',
      name: 'salesOutbound.task.subPickingTask.groupLog',
      meta: {
        title: 'Group Log',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.task',
            title: 'Task',
          },
          {
            routeName: 'PC.SalesOutbound.Task.SubPickingTask.View',
            title: 'Sub Picking',
          },
        ],
      },
      component: () =>
        import('../../views/sales-outbound/task/group-log/index.vue'),
    },
    // 新增 group log detail 界面
    {
      path: 'task/subPicking/grouplog/detail',
      name: 'salesOutbound.task.subPickingTask.groupLog.detail',
      meta: {
        title: 'Group Log Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.task',
            title: 'Task',
          },
          {
            routeName: 'PC.SalesOutbound.Task.SubPickingTask.View',
            title: 'Sub Picking',
          },
          {
            routeName: 'salesOutbound.task.subPickingTask.groupLog',
            title: 'Group Log',
          },
        ],
      },
      component: () =>
        import(
          '../../views/sales-outbound/task/group-log/group-log-detail.vue'
        ),
    },
    {
      path: 'groupshipping',
      name: 'salesOutbound.groupShipping',
      meta: {
        title: 'Group Shipping',
        icon: '',
        filterSave: true,
      },
      component: () =>
        import('@/views/sales-outbound/group-shipping/index.vue'),
    },
    {
      path: 'groupshipping/detail/:id',
      name: 'salesOutbound.groupShipping.detail',
      meta: {
        title: 'Detail',
        icon: '',
        menuHide: true,
        navList: [
          {
            routeName: 'salesOutbound',
            title: 'Sales Outbound',
          },
          {
            routeName: 'salesOutbound.groupShipping',
            title: 'Group Shipping',
          },
        ],
      },
      component: () =>
        import('@/views/sales-outbound/group-shipping/detail/index.vue'),
    },
    {
      path: 'instantDelivery',
      name: 'salesOutbound.instantdelivery',
      meta: {
        title: 'Instant Delivery',
      },
      redirect: '/salesoutbound/instantDelivery/pigeonhole',
    },
    {
      path: 'instantDelivery/pigeonhole',
      name: 'salesOutbound.instantdelivery.pigeonhole',
      meta: {
        title: 'Instant Delivery Pigeonhole',
        menuHide: true,
      },
      component: () =>
        import('../../views/sales-outbound/instant-delivery/pigeonhole.vue'),
    },
    {
      path: 'instantDelivery/zoneCode',
      name: 'salesOutbound.instantdelivery.zoneCode',
      meta: {
        title: 'Zone Code',
        menuHide: true,
      },
      component: () =>
        import('../../views/sales-outbound/instant-delivery/zone-code.vue'),
    },
    {
      path: 'instantDelivery/configuration',
      name: 'salesOutbound.instantdelivery.configuration',
      meta: {
        title: 'Configuration',
        menuHide: true,
      },
      component: () =>
        import('../../views/sales-outbound/instant-delivery/configuration.vue'),
    },
  ],
};

export default salesOutbound;
