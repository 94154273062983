<template>
  <div class="card">
    <header class="g-header">
      <div class="g-header-left">
        <span class="title">{{ $i18n(title) }}</span>
      </div>
      <div class="g-header-right">
        <s-select
          class="filter-select"
          v-model="inputValue[1]"
          @visible-change="handleHeaderSelectChange"
          @change="handleChange"
        >
          <template v-slot:reference>
            <span class="filter-span"> {{ $i18n(filterTypeLabel) }} </span>
            <SIconChevronDown v-if="!isArrowsExpand" />
            <SIconChevronUp v-else />
          </template>
          <s-option
            v-for="item in selectFilterOptions"
            :key="item.value"
            :value="item.value"
            :label="$i18n(item.label)"
          >
            {{ $i18n(item.label) }}
          </s-option>
        </s-select>
      </div>
    </header>

    <content class="g-content">
      <s-textarea
        clearable
        class="g-textarea"
        resize="vertical"
        :auto-rows="{ minRows: 2 }"
        :placeholder="placeholder"
        v-model="inputValue[0]"
        @blur="handleChange"
      />
    </content>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class TextAreaCard extends Vue {
  @Model('change') inputValue: Array<any>;
  @Prop() title!: string;
  @Prop() placeholder: string;
  @Prop() selectFilterOptions!: any[];

  isArrowsExpand = false;

  get filterTypeLabel() {
    let label = '';
    this.selectFilterOptions.forEach((item: any) => {
      if (item.value === this.inputValue[1]) {
        label = item.label;
      }
    });
    return label;
  }

  handleHeaderSelectChange(visible: boolean) {
    this.isArrowsExpand = visible;
  }

  handleChange() {
    this.$emit('change', [this.inputValue[0], this.inputValue[1]]);
  }
}
</script>

<style scoped lang="scss">
.card {
  height: 100%;
  width: 100%;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(236 240 244);
}

.g-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin: 12px;
  height: 16px;

  .g-header-left {
    display: flex;
    align-items: center;
    width: 100%;

    .title {
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      line-height: 16px;
    }
  }

  .g-header-right {
    display: inline-block;
    width: 100%;
    ::v-deep .ssc-select {
      width: 100%;
      margin: -5px 0 0;
      .ssc-select-reference-wrapper {
        justify-content: flex-end;
      }
    }
  }
}

.g-content {
  display: flex;
  overflow-y: auto;
  height: 63px;

  &::-webkit-scrollbar {
    width: 4px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    margin: 0 8px 0 0;
    border-radius: 10px;
    background: #acb3bf;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #999999;
  }

  &::-webkit-scrollbar-corner {
    background: #179a16;
  }
}

.g-textarea {
  min-width: 100%;
  padding: 0px 12px;

  & ::v-deep .ssc-textarea {
    min-width: 100%;
  }
}
.filter-select .ssc-options {
  min-width: 72px !important;
}
</style>

<style lang="scss"></style>
