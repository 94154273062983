import request from '@/utils/request';

interface InterfaceCreateStorey {
  storey: number;
  mezzanine?: number;
  storey_id?: string;
  storey_name?: string;
}

export const createStorey = async (params: InterfaceCreateStorey) => {
  const res = await request.post('/api/v2/apps/basic/storey/create_storey', {
    ...params,
  });
  return res;
};

interface InterfaceDelStorey {
  storey_uuid: string;
}

export const deleteStorey = async (params: InterfaceDelStorey) => {
  const res = await request.post('/api/v2/apps/basic/storey/delete_storey', {
    ...params,
  });
  return res;
};

export const getWhsStoreyNameSetup = async () => {
  const res = await request.get(
    '/api/v2/apps/basic/storey/get_whs_storey_name_setup'
  );
  return res;
};

interface InterfaceSearchStorey {
  storey_id?: string;
  count?: number;
  pageno?: number;
}

export const searchStorey = async (params: InterfaceSearchStorey) => {
  const res = await request.get('/api/v2/apps/basic/storey/search_storey', {
    params: {
      ...params,
    },
  });
  return res;
};

interface InterfaceUpdateStorey {
  storey: number;
  mezzanine?: number;
  storey_id?: string;
  storey_name?: string;
  storey_uuid: string;
}

export const updateStorey = async (params: InterfaceUpdateStorey) => {
  const res = await request.post('/api/v2/apps/basic/storey/update_storey', {
    ...params,
  });
  return res;
};
