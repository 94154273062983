import Layout from '../../views/layout/index.vue';

const rtsOutbound = {
  path: '/rtsoutbound',
  name: 'rtsOutbound',
  meta: {
    title: 'RTS Outbound',
    icon: 'RTS Outbound',
  },
  redirect: '/rtsoutbound/order',
  component: () => import('@/views/layout/index.vue'),
  children: [
    {
      path: 'order',
      name: 'rtsOutbound.order',
      meta: {
        title: 'Order',
        filterSave: true,
      },
      component: () => import('../../views/rts-outbound/order/index.vue'),
    },
    {
      path: 'order/create',
      name: 'rtsOutbound.order.create',
      meta: {
        title: 'Create',
        menuHide: true,
        navList: [
          {
            routeName: 'rtsOutbound',
            title: 'RTS Outbound',
          },
          {
            routeName: 'rtsOutbound.order',
            title: 'Order',
          },
        ],
      },
      component: () => import('../../views/rts-outbound/order/create.vue'),
    },
    {
      path: 'order/edit/:orderNo',
      name: 'rtsOutbound.order.edit',
      meta: {
        title: 'Edit',
        menuHide: true,
        navList: [
          {
            routeName: 'rtsOutbound',
            title: 'RTS Outbound',
          },
          {
            routeName: 'rtsOutbound.order',
            title: 'Order',
          },
        ],
      },
      component: () => import('../../views/rts-outbound/order/create.vue'),
    },
    {
      path: 'order/detail/:id',
      name: 'rtsOutbound.order.detail',
      meta: {
        title: 'Order Detail',
        menuHide: true,
        navList: [
          {
            routeName: 'rtsOutbound',
            title: 'RTS Outbound',
          },
          {
            routeName: 'rtsOutbound.order',
            title: 'Order',
          },
        ],
      },
      component: () => import('../../views/rts-outbound/order/detail.vue'),
    },
    {
      path: 'order/allocate/:orderNo',
      name: 'rtsOutbound.order.allocate',
      meta: {
        title: 'Allocate',
        menuHide: true,
        navList: [
          {
            routeName: 'rtsOutbound',
            title: 'RTS Outbound',
          },
          {
            routeName: 'rtsOutbound.order',
            title: 'Order',
          },
        ],
      },
      component: () =>
        import('../../views/rts-outbound/order/allocate-approve.vue'),
    },
    {
      path: 'order/approve/:orderNo',
      name: 'rtsOutbound.order.approve',
      meta: {
        title: 'Approve',
        menuHide: true,
        navList: [
          {
            routeName: 'rtsOutbound',
            title: 'RTS Outbound',
          },
          {
            routeName: 'rtsOutbound.order',
            title: 'Order',
          },
        ],
      },
      component: () =>
        import('../../views/rts-outbound/order/allocate-approve.vue'),
    },
    {
      path: 'task',
      name: 'rtsOutbound.task',
      meta: {
        title: 'Task',
      },
      redirect: '/rtsoutbound/task/picking',
      component: () => import('@/views/rts-outbound/task/index.vue'),
      children: [
        {
          path: 'picking',
          name: 'PC.RTSOutbound.Task.PickingTask.View',
          meta: {
            title: 'Picking Task',
            menuHide: true,
          },
          component: () =>
            import('@/views/rts-outbound/task/picking/index.vue'),
        },
        {
          path: 'detail/:task_number/:order_no',
          props: true,
          name: 'PC.RTSOutbound.Task.PickingTask.Detail',
          meta: {
            title: 'Detail',
            menuHide: true,
            navList: [
              {
                routeName: 'rtsOutbound',
                title: 'RTS Outbound',
              },
              {
                routeName: 'rtsOutbound.task',
                title: 'Task',
              },
              {
                routeName: 'PC.RTSOutbound.Task.PickingTask.View',
                title: 'Picking Task',
              },
            ],
          },
          component: () =>
            import('@/views/rts-outbound/task/picking/detail.vue'),
        },
      ],
    },
    {
      path: 'picking/:id?',
      name: 'rtsOutbound.picking',
      meta: {
        title: 'Picking',
      },
      component: () => import('../../views/rts-outbound/picking/index.vue'),
    },
    {
      path: 'shipping',
      name: 'rtsOutbound.shipping',
      meta: {
        title: 'Shipping',
        navList: [
          {
            routeName: 'rtsOutbound',
            title: 'RTS Outbound',
          },
        ],
        filterSave: true,
      },
      component: () => import('../../views/rts-outbound/shipping/index.vue'),
    },
    {
      path: 'shipping/create/:taskNumber',
      name: 'rtsOutbound.shipping.create',
      meta: {
        title: 'Shipping Task',
        menuHide: true,
        navList: [
          {
            routeName: 'rtsOutbound',
            title: 'RTS Outbound',
          },
          {
            routeName: 'rtsOutbound.shipping',
            title: 'Shipping',
          },
        ],
      },
      component: () => import('../../views/rts-outbound/shipping/shipping.vue'),
    },
  ],
};

export default rtsOutbound;
