import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { getCookie } from '@/utils/cookie';
import wmsBasicApi from '@/api/wms-basic/warehouse';

@Module
class WarehouseDetail extends VuexModule {
  warehouse: any = {};

  @Mutation
  setWarehouseDetail(warehouseDetail: any): void {
    this.warehouse = warehouseDetail;
  }

  @Action({ commit: 'setWarehouseDetail' })
  async loadWarehouseDetail() {
    try {
      const curWhsId = getCookie('setting_whs_v2');
      const { data } = await wmsBasicApi.detailWarehouse(curWhsId);
      /** 对于 VN 的仓库，开放 rms 入口 */
      if (curWhsId && curWhsId.toLowerCase().startsWith('vn')) {
        data.is_rms = true;
      }
      /** 对于 VN 的仓库，开放 rms 入口 */
      return data;
    } catch (err) {
      return {};
    }
  }
}
export default WarehouseDetail;
