import '../style/index.scss';
import { VNode, CreateElement } from 'vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import spreadCustomHooks from './getCustomHooks';

@Component
export default class SCustomHooks extends Vue {
  @Prop()
  readonly customHooks!: [];
  render(h: CreateElement): VNode {
    const customHooks = spreadCustomHooks(this, h);
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    return <div class="s-custom-hooks">{customHooks}</div>;
  }
}
