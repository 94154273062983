/**
 * Creates a Ping instance.
 * @returns {Ping}
 * @constructor
 */
class Ping {
  private static instance: Ping;
  favicon = '/favicon.ico';
  timeout = 0;
  logError = false;

  timer: any = undefined;
  start: any = 0;
  promise: any = undefined;
  resolve: any = undefined;
  reject: any = undefined;
  wasSuccess = false;
  img: any = undefined;
  // constructor(opt) {
  // }

  public static getInstance(): Ping {
    if (!this.instance) {
      const instance = new Ping();
      this.instance = instance;
    }
    return this.instance;
  }
  public ping(source: any, callback: any) {
    if (typeof Promise !== 'undefined') {
      this.promise = new Promise((_resolve, _reject) => {
        this.resolve = _resolve;
        this.reject = _reject;
      });
    }

    this.wasSuccess = false;
    this.img = new Image();
    this.img.onload = (e: any) => {
      this.wasSuccess = true;
      this.pingCheck(e, callback);
    };
    this.img.onerror = (e: any) => {
      this.wasSuccess = false;
      this.pingCheck(e, callback);
    };

    this.start = new Date();
    if (this.timeout) {
      this.timer = setTimeout(() => {
        this.pingCheck(undefined, callback);
      }, this.timeout);
    }

    this.img.src = source + this.favicon + '?' + +new Date();
    return this.promise;
  }

  pingCheck(event: any, callback: any) {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    const pong = Number(new Date()) - this.start;

    if (!callback) {
      if (this.promise) {
        return this.wasSuccess ? this.resolve(pong) : this.reject(pong);
      } else {
        throw new Error(
          'Promise is not supported by your browser. Use callback instead.'
        );
      }
    } else if (typeof callback === 'function') {
      if (!this.wasSuccess) {
        if (this.logError) {
          console.error('error loading resource');
        }
        if (this.promise) {
          this.reject(pong);
        }
        return callback('error', pong);
      }
      if (this.promise) {
        this.resolve(pong);
      }
      return callback(null, pong);
    } else {
      throw new Error('Callback is not a function.');
    }
  }
}

const ping = Ping.getInstance();

// export const pingAll = async function () {
//   const urlList = ['https://google.com', 'https://spx.shopee.co.id/assets'];

//   const requestList = urlList.map((url) => {
//     return new Promise((resolve, reject) => {
//       ping.ping(url, (err: any, data: any) => {
//         if (err) {
//           data = data + ' ' + err;
//           reject(data);
//         }
//         resolve({ data, url });
//       });
//     });
//   });
//   return Promise.all(requestList);
// };

export const pingInOrder = async function (
  pingUrl: string,
  count = 3
): Promise<any[]> {
  const resList: any = [];
  // const pingUrl = 'https://google.com';
  for (let i = 0; i < count; i++) {
    await ping.ping(pingUrl, (err: any, data: any) => {
      if (err) {
        data = data + ' ' + err;
      }
      resList.push({ data, url: pingUrl });
    });
  }
  return resList;
};

export default ping;
