<template>
  <div class="dashboard-tv">
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class DashboardTV extends Vue {}
</script>

<style scoped>
.dashboard-tv {
  margin: auto;
  width: 1920px;
  height: 1080px;
  font-family: 'Roboto', sans-serif;
  color: var(--color-white-8);
  background: var(--color-black);
}
</style>
