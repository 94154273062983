import store from '@/store';
import { hasPermission } from '@/utils/checkPermission';

function isTabPage(routerName: any) {
  return routerName in store.state.permission.tabPageNamesMap;
}

function hasTabPagePermission(routerName: any) {
  return hasPermission(routerName);
}

function getNextTabPageNameWithPermission(curRouterName: any) {
  let nextRouterName = '403';
  if (isTabPage(curRouterName) && !hasTabPagePermission(curRouterName)) {
    for (const index in store.state.permission.tabPageNamesMap[curRouterName]) {
      if (
        hasTabPagePermission(
          store.state.permission.tabPageNamesMap[curRouterName][index]
        )
      ) {
        nextRouterName =
          store.state.permission.tabPageNamesMap[curRouterName][index];
        break;
      }
    }
    return nextRouterName;
  }
  return curRouterName;
}

export { getNextTabPageNameWithPermission, isTabPage, hasTabPagePermission };
