import request from '@/utils/request';
export interface TreeNode {
  id: number;
  parent_id: number;
  node_name: string;
  sound_type: number;
  disabled?: boolean;
  children: TreeNode[];
}

export const getNotificationSoundListV2 = async () => {
  const res = await request.get(
    '/api/v2/apps/config/notification/get_notification_sound_list_v2'
  );
  return res;
};

export const getNotificationTreeV2 = async () => {
  const res = await request.get(
    '/api/v2/apps/config/notification/get_notification_tree_v2'
  );
  return res;
};

interface InterfaceUpdateNotificationSoundV2 {
  list?: object;
}

export const updateNotificationSoundV2 = async (
  params: InterfaceUpdateNotificationSoundV2
) => {
  const res = await request.post(
    '/api/v2/apps/config/notification/update_notification_sound_v2',
    {
      ...params,
    }
  );
  return res;
};
