<template>
  <div :key="id">
    <slot></slot>
    <more-action
      v-if="showMoreButton"
      :actionList="filterActionList"
      :addKey="addKey"
    ></more-action>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import moreAction from './basicButton';

@Component({
  components: {
    moreAction,
  },
})
export default class WmsTableAction extends Vue {
  filterActionList: any = [];
  showMoreButton: any = false;
  localActionList: any = [];
  id = 0;

  mounted() {
    this.coumputedActionList();
  }

  updated() {
    if (
      Array.isArray(this.$slots.default) &&
      this.localActionList.length === (this.$slots.default as any).length
    ) {
      this.showMoreButton = false;
      this.coumputedActionList();
      if (this.showMoreButton) {
        this.addKey();
      }
    }
  }

  addKey() {
    this.id += 1;
  }

  coumputedActionList() {
    this.localActionList = this.$slots.default
      ? [...(this.$slots.default as any)]
      : [];
    this.filterActionList = this.localActionList.filter((item: any) => {
      return item.tag;
    });
    if (this.filterActionList.length > 3) {
      this.showMoreButton = true;
      this.$slots.default = this.filterActionList.slice(0, 2);
    } else {
      this.showMoreButton = false;
      this.$slots.default = this.filterActionList;
    }
  }
}
</script>
