<template>
  <section class="app-main" :class="appMainClass">
    <Navbar />
    <transition name="fade" mode="out-in">
      <router-view
        ref="routeView"
        class="main-page"
        :class="padClass"
      ></router-view>
    </transition>
  </section>
</template>

<script lang="ts">
import { Navbar } from '../components';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    Navbar,
  },
})
export default class AppMain extends Vue {
  name = 'AppMain';
  isPad = false;
  extraClassFromChildren = '';

  created() {
    this.isPad = /NQ1000/gi.test(window.navigator.userAgent);
  }

  get padClass() {
    return this.isPad ? 's-pad' : '';
  }

  get appMainClass() {
    const { extraClassFromChildren } = this;
    return `${extraClassFromChildren}`;
  }

  /**
   * 用于外部组件或者子组件需要更新 app-main 容器的 className
   * eg: src/views/sales-outbound/checking/index.vue
   */
  childrenViewUpdateMainClass(className: string) {
    this.extraClassFromChildren = className;
  }
}
</script>

<style>
.main-page {
  display: flex;
  overflow: auto;
  height: calc(100vh - 64px);
  flex-direction: column;
}

.main-page.s-pad {
  height: calc(100vh - 164px);
}
</style>
