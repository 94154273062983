import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import {
  getCycleCountSwitch,
  operateFullStockCount,
} from '@/api/inventory-management/fsc-common';

interface PayloadType {
  isOpen: boolean;
  // 获取修改 isOpen 的操作来源
  from?: 'loadOpenFullStockCountSign' | 'operateFullStockCountSign' | 'apiCode';
  [property: string]: any;
}
@Module
class CycleCount extends VuexModule {
  isFullStockCount = false;

  @Mutation
  setFullCycleCountFlag(payload: PayloadType = { isOpen: false }): void {
    this.isFullStockCount = payload.isOpen;
  }

  @Action({ commit: 'setFullCycleCountFlag' })
  async loadOpenFullStockCountSign() {
    try {
      const { data } = await getCycleCountSwitch();
      return {
        isOpen: !!data.is_open_full_stock_count,
        from: 'loadOpenFullStockCountSign',
      };
    } catch (err) {
      return false;
    }
  }

  @Action({ commit: 'setFullCycleCountFlag' })
  async operateFullStockCountSign(isOpen: any) {
    /* try { */
    const { data } = await operateFullStockCount({
      is_open_full_stock_count: +isOpen, // 关闭全仓盘点
    });
    console.log(data);
    return {
      isOpen: !!isOpen,
      from: 'operateFullStockCountSign',
    };
    /* } catch (err) {
      // return this.;
    } */
  }
}
export default CycleCount;
