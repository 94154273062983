import request from '@/utils/request';

interface LoginInput {
  name: string;
  passwd: string;
  remember: number;
  captcha_id?: string;
  captcha?: string;
}

export default {
  login(data: LoginInput) {
    return request.post(
      '/api/v2/apps/system/user/user_login',
      {
        ...data,
      },
      {
        noErrorCode: [-224003],
      }
    );
  },
  getCaptcha(name?: string) {
    return request.get('/api/v2/apps/system/user/get_captcha', {
      params: { name },
    });
  },
  googleLogin(redirectUrl: string) {
    return request.get('/api/v2/apps/system/user/user_google_auth', {
      params: {
        redirect_url: redirectUrl,
      },
    });
  },
  sendEmail(email: string) {
    return request.post(
      '/api/v2/apps/system/user/user_forgot',
      {
        email,
      },
      {
        noErrorCode: [-224005],
      }
    );
  },
  resetPassword(passwd: string, token: string, user_id: number) {
    return request.post('/api/v2/apps/system/user/user_password_reset', {
      passwd,
      token,
      user_id,
    });
  },
  getModifyPasswordDeadline() {
    /* PermissionKey: ALL */
    return request.get('/api/v2/apps/system/user/simple_password_deadline');
  },

  enableTobLogin(configKeyList = 'enable_tob_login') {
    /* PermissionKey: ALL */
    return request.get('/api/v2/config/adminconfig/get_admin_config', {
      params: {
        configKeyList,
      },
    });
  },

  tobUserLogin(params: any) {
    /* PermissionKey: ALL */
    return request.get(
      '/api/v2/apps/system/user/tob_user_login',
      {
        params,
      },
      {
        noError: true,
      }
    );
  },
};
