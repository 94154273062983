const localStorageName = 'SKU_ID_MAP';
const localStorageArrayName = 'SKU_ID_ARRAY';

// 最大存储条数，最多 2000 条，占用大概 2MB 内存
const MAX_CACHE_NUM = 2000;

function findAndAdjust(key: string, array: string[]) {
  let index = 0;

  array.some((item: any, idx: number) => {
    if (item === key) {
      index = idx;
      return true;
    }
  });

  array.splice(index, 1);
  array.unshift(key);
}

function getStorage(key: any) {
  const data = localStorage.getItem(localStorageName);
  const linkList: any = localStorage.getItem(localStorageArrayName);

  if (!data) {
    return '';
  }

  const mapData = JSON.parse(data);
  const linkedData = JSON.parse(linkList);
  const value = mapData[key];

  if (typeof value === 'object') {
    findAndAdjust(key, linkedData);
    localStorage.setItem(localStorageArrayName, JSON.stringify(linkedData));

    return (value as any).new_id;
  }

  return '';
}

function setStorage(key: string, value: any) {
  if (typeof key !== 'string') {
    return;
  }

  const data: any = localStorage.getItem(localStorageName);
  const linkList: any = localStorage.getItem(localStorageArrayName);

  let mapData: any = {};
  let linkedData: any = [];

  if (data === null) {
    mapData = {};
    linkedData = [];
  } else {
    mapData = JSON.parse(data);
    linkedData = JSON.parse(linkList);
  }

  linkedData.unshift(key);
  (mapData as any)[key] = { id: key, new_id: value };

  // 是否需要删除最旧的 key
  if (linkedData.length > MAX_CACHE_NUM) {
    const deleteKey = linkedData.splice(MAX_CACHE_NUM, 1)[0];
    delete (mapData as any)[deleteKey];
  }

  localStorage.setItem(localStorageArrayName, JSON.stringify(linkedData));
  localStorage.setItem(localStorageName, JSON.stringify(mapData));
}

export default {
  getStorage,
  setStorage,
};
