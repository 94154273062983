const cycleCount = {
  path: 'cc',
  name: 'inventoryManagement.cycleCount',
  meta: {
    title: 'Cycle Count',
  },
  redirect: '/inventorymanage/cc/ccorder',
  component: () => import('@/views/layout/subPage.vue'),
  children: [
    {
      path: 'ccorder',
      name: 'inventoryManagement.cycleCount.order',
      meta: {
        title: 'CC Order',
        filterSave: true,
      },
      component: () => import('@/views/inventory-management/cycle-count/order'),
      children: [
        {
          path: 'view',
          name: 'inventoryManagement.cycleCount.order.view',
          meta: {
            title: 'Order Detail',
            menuHide: true,
          },
          exact: true,
          component: () =>
            import('@/views/inventory-management/cycle-count/order/view'),
        },
        {
          path: 'create',
          name: 'inventoryManagement.cycleCount.order.create',
          meta: {
            title: 'Create Cycle Count',
            menuHide: true,
          },
          exact: true,
          component: () =>
            import('@/views/inventory-management/cycle-count/order/create'),
        },
        {
          path: 'count',
          name: 'inventoryManagement.cycleCount.order.count',
          meta: {
            title: 'Count',
            menuHide: true,
          },
          exact: true,
          component: () =>
            import('@/views/inventory-management/cycle-count/order/count'),
        },
      ],
    },
    {
      path: 'cctask',
      name: 'inventoryManagement.cycleCount.task',
      meta: {
        title: 'CC Task',
        filterSave: true,
      },
      component: () => import('@/views/inventory-management/cycle-count/task'),
      children: [
        {
          path: 'createsecondcc',
          name: 'inventoryManagement.cycleCount.task.createSecondCC',
          meta: {
            title: 'Create Second CC',
            menuHide: true,
          },
          exact: true,
          component: () =>
            import('@/views/inventory-management/cycle-count/task/second-task'),
        },
        {
          path: ':task_id',
          name: 'inventoryManagement.cycleCount.task.view',
          meta: {
            title: 'Task Detail',
            menuHide: true,
          },
          exact: true,
          component: () =>
            import('@/views/inventory-management/cycle-count/task/view'),
        },
      ],
    },
    {
      path: 'difforder',
      name: 'inventoryManagement.cycleCount.diffOrder',
      meta: {
        title: 'CC Diff Order',
        filterSave: true,
      },
      component: () =>
        import('@/views/inventory-management/cycle-count/diff-order'),
      children: [
        {
          path: ':order_id/approve',
          name: 'inventoryManagement.cycleCount.diffOrder.approve',
          meta: {
            title: 'Approve',
            menuHide: true,
          },
          exact: true,
          component: () =>
            import(
              '@/views/inventory-management/cycle-count/diff-order/detail'
            ),
        },
        {
          path: ':order_id',
          name: 'inventoryManagement.cycleCount.diffOrder.view',
          meta: {
            title: 'View',
            menuHide: true,
          },
          exact: true,
          component: () =>
            import(
              '@/views/inventory-management/cycle-count/diff-order/detail'
            ),
        },
      ],
    },
  ],
};

export default cycleCount;
