import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { getDevConf, getDevConfList } from '@/api/basic/displaySetting';
import { getCookie } from '@/utils/cookie';
import { Judge } from '@/ConstEnums';

@Module
class System extends VuexModule {
  lang = 'en'; // 当前 i18n lang
  staff_name_display_switch = 0;
  weak_network_switch = 0;
  weak_network_show_time_again = 30;
  weak_network_time_threshold = 3;
  weak_network_count_threshold = 3;
  weak_network_rtt_threshold = 300;
  weak_network_ping_threshold = 100;
  weak_network_ping_domain = '';
  weak_network_api_whiteList = '';
  weak_network_router_blackList = '';
  isOnPrintDANFEButton = false;
  isOnCheckingPrintDANFE = false;
  /** 是否开启履约线开关 */
  enableFulfilChain = false;
  /** 是否使用旧的Rule center inbound putaway 页面 */
  EnableFulfillChainInboundDiversionPutawayRule = false;
  /** 是否展示的 Storage Area Setting Zone/Pathway/Cell 页面的Hot Level */
  CellHotLevelMgmt = false;
  /** 是否开启补货开关 */
  enableDynamicReplenishment = false;
  showZoneColor = false;
  /** 是否展示 dashboard configuration 目录 */
  showDashboardConfiguration = false;

  @Mutation
  setCurrentLang(data: any = {}): void {
    this.lang = data.lang;
  }
  /* @Action({ commit: 'setCurrentLang' })
  async getCurrentLang(route: any) {
    return route;
  } */

  @Mutation
  setStaffNameDisplaySwitch(data: number): void {
    this.staff_name_display_switch = data;
  }

  @Action({ commit: 'setStaffNameDisplaySwitch' })
  async loadStaffSwitch() {
    const res = await getDevConf({
      conf_key: 'Is_staffname_display',
      whs_id: getCookie('setting_whs_v2') || '',
    });
    return Number(res.data.conf_value) || 0;
  }
  @Mutation
  setDisplaySettings(data: any = {}): void {
    this.weak_network_switch = Number(data[0].conf_value) || 0;
    this.weak_network_show_time_again = Number(data[1].conf_value) || 0;
    this.weak_network_time_threshold = Number(data[2].conf_value) || 0;
    this.weak_network_count_threshold = Number(data[3].conf_value) || 0;
    this.weak_network_rtt_threshold = Number(data[4].conf_value) || 0;
    this.weak_network_ping_threshold = Number(data[5].conf_value) || 0;
    this.weak_network_ping_domain = data[6].conf_value;
    this.weak_network_api_whiteList = data[7].conf_value;
    this.weak_network_router_blackList = data[8].conf_value;
    this.isOnPrintDANFEButton = Number(data[9].conf_value) === 1;
    this.isOnCheckingPrintDANFE = Number(data[10].conf_value) === 1;
    this.enableFulfilChain =
      (Number(data[11].conf_value) || 0) === Judge.Truthy;
    this.EnableFulfillChainInboundDiversionPutawayRule =
      (Number(data[12].conf_value) || 0) === Judge.Falsy;
    this.CellHotLevelMgmt = (Number(data[13].conf_value) || 0) === Judge.Truthy;
    this.enableDynamicReplenishment =
      (Number(data[14].conf_value) || 0) === Judge.Truthy;
    this.showZoneColor = Number(data[15].conf_value) === 1;
    this.showDashboardConfiguration = Number(data[16].conf_value) === 1;

    // 若有一个配置关闭，则要关闭此功能
    if (
      !this.weak_network_show_time_again ||
      !this.weak_network_time_threshold ||
      !this.weak_network_count_threshold ||
      !this.weak_network_rtt_threshold ||
      !this.weak_network_ping_threshold ||
      !this.weak_network_ping_domain
    ) {
      this.weak_network_switch = 0;
    }
  }

  @Action({ commit: 'setDisplaySettings' })
  async loadDisplaySettings() {
    const res = await getDevConfList({
      conf_key: [
        'weak_network_switch',
        'weak_network_show_time_again',
        'weak_network_time_threshold',
        'weak_network_count_threshold',
        'weak_network_rtt_threshold',
        'weak_network_ping_threshold',
        'weak_network_ping_domain',
        'weak_network_api_whiteList',
        'weak_network_router_blackList',
        'print_danfe_button_switch',
        'print_danfe_button_switch_checking',
        'Enable_Fulfill_Chain',
        'Enable_Fulfill_Chain_Inbound_Diversion_Putaway_Rule',
        'Cell_hotlevel_mgmt',
        'Enable_Dynamic_Replenishment',
        'zone_by_color',
        'dashboard_configuration_color',
      ],
      whs_id: getCookie('setting_whs_v2') || '',
    });
    return res.data.conf_list || {};
  }
}
export default System;
