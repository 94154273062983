import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { getUserInfo } from '../../api/basic/user';

@Module
class User extends VuexModule {
  userInfo = {};
  @Mutation
  setUserInfo(data = {}): void {
    this.userInfo = data;
  }
  @Mutation
  clearUserInfo(): void {
    this.userInfo = {};
  }
  @Action({ commit: 'setUserInfo' })
  async getUserInfo() {
    const resp = await getUserInfo();
    console.log('getUserInfo', resp);
    return resp.data;
  }
}
export default User;
