import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { getWarehouseSwitchTime } from '../../api/system-config/get-warehouse-switch-time';

@Module
class SwtichTime extends VuexModule {
  warehouseSwitchTime = {};

  @Mutation
  setWarehouseSwitchTime(switchTime: object): void {
    this.warehouseSwitchTime = switchTime;
  }

  @Action({ commit: 'setWarehouseSwitchTime' })
  async loadWarehouseSwitchTime() {
    const resp = await getWarehouseSwitchTime();
    const data: any = resp.data || {};

    return data;
  }
}
export default SwtichTime;
