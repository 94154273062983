import saleOutbound from './sale-outbound';
import reportCenter from './report-center';
import inventoryManagement from './inventory-management';
import inbound from './inbound';
import rms from './rms';

export default [
  ...saleOutbound,
  ...inbound,
  ...reportCenter,
  ...inventoryManagement,
  ...rms,
];
