// TODO:  确认后端 全仓盘点 字段
export const FULL_STOCK_COUNT_ROUTE_NAME = 'inventoryManagement.fullStockCount';
export const CYCLE_COUNT_ROUTE_NAME = 'inventoryManagement.cycleCount';
export const DASHBOARD_FULL_STOCK_COUNT_ROUTE_NAME = 'dashboard.fullStockCount';
export const DASHBOARD_CYCLE_COUNT_ROUTE_NAME = 'dashboard.locationCycleCount';
export const ASN_QUOTA_CONFIG_ROUTE_NAME =
  'ruleCenter.quotaConfig.asnQuotaConfig';

export const cycleCountMainMenuRouteName = [
  CYCLE_COUNT_ROUTE_NAME,
  FULL_STOCK_COUNT_ROUTE_NAME,
];

export const dashboardCycleCountMainMenuRouteName = [
  DASHBOARD_CYCLE_COUNT_ROUTE_NAME,
  DASHBOARD_FULL_STOCK_COUNT_ROUTE_NAME,
];
