import request, { UserConfig } from '@/utils/request';

interface InterfaceCheckGroupCompleteRecord {
  group_id: string | (string | null)[];
}

export const checkGroupCompleteRecord = async (
  params: InterfaceCheckGroupCompleteRecord
) => {
  const res = await request.get(
    '/api/v2/apps/process/inbound/counting/check_group_complete_record',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceCheckSurplus {
  group_id: string | (string | null)[];
  tracking_id: string;
  qty: number | undefined;
  sku_id: string;
}

export const checkSurplus = async (params: InterfaceCheckSurplus) => {
  const res = await request.get(
    '/api/v2/apps/process/inbound/counting/check_surplus',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceCreateGroupCompleteRecord {
  group_id: string | (string | null)[];
}

export const createGroupCompleteRecord = async (
  params: InterfaceCreateGroupCompleteRecord
) => {
  const res = await request.post(
    '/api/v2/apps/process/inbound/counting/create_group_complete_record',
    {
      ...params,
    }
  );
  return res;
};

interface InterfaceCreateTrackingId {
  group_id: string | (string | null)[];
  tracking_id: string;
  dock_no_list?: string;
  sku_id?: string;
}

export const createTrackingId = async (params: InterfaceCreateTrackingId) => {
  const res = await request.post(
    '/api/v2/apps/process/inbound/counting/create_tracking_id',
    {
      ...params,
    }
  );
  return res;
};

interface InterfaceCreateWrongSkuRecord {
  group_id: string | (string | null)[];
  image_url?: any[];
  qty: number | undefined;
  sku_id: string;
  remark?: string | undefined;
}

export const createWrongSkuRecord = async (
  params: InterfaceCreateWrongSkuRecord,
  requestSetting?: UserConfig
) => {
  const res = await request.post(
    '/api/v2/apps/process/inbound/counting/create_wrong_sku_record',
    {
      ...params,
    },
    requestSetting || {}
  );
  return res;
};

interface InterfaceDeleteTrackingId {
  group_id: string | (string | null)[];
  tracking_id: string;
}

export const deleteTrackingId = async (params: InterfaceDeleteTrackingId) => {
  const res = await request.post(
    '/api/v2/apps/process/inbound/counting/delete_tracking_id',
    {
      ...params,
    }
  );
  return res;
};

export interface InterfaceSaveCountingRecord {
  group_id: string | (string | null)[];
  tracking_id: string;
  damage_sku: boolean;
  exception_tracking_id?: string;
  qty: number | undefined;
  sku_id: string;
  remark?: string | undefined;
  carton_size?: number | undefined;
  carton_qty?: number | undefined;
  piece_qty?: number | undefined;
  qc_tracking_id?: number;
}

export const saveCountingRecord = async (
  params: InterfaceSaveCountingRecord
) => {
  const res = await request.post(
    '/api/v2/apps/process/inbound/counting/save_counting_record',
    {
      ...params,
    }
  );
  return res;
};

interface InterfaceSearchGroupDetail {
  sheet_id: string | (string | null)[];
}

export const searchGroupDetail = async (params: InterfaceSearchGroupDetail) => {
  const res = await request.get(
    '/api/v2/apps/process/inbound/counting/search_group_detail',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceSearchGroupList {
  pageno?: number;
  count?: number;
}

export const searchGroupList = async (params: InterfaceSearchGroupList) => {
  const res = await request.get(
    '/api/v2/apps/process/inbound/counting/search_group_list',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceSearchGroupWorkingList {
  group_id: string;
}

export const searchGroupWorkingList = async (
  params: InterfaceSearchGroupWorkingList
) => {
  const res = await request.get(
    '/api/v2/apps/process/inbound/counting/search_group_working_list',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceSearchSkuScanning {
  group_id: string | (string | null)[];
  sku_id: string;
}

export const searchSkuScanning = async (params: InterfaceSearchSkuScanning) => {
  const res = await request.get(
    '/api/v2/apps/process/inbound/counting/search_sku_scanning',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

export interface InterfaceUpdateTrackingId {
  group_id: string;
  tracking_id: string;
  counting_qty: number | undefined;
  remark?: string;
  exception_tracking_id?: string | undefined;
}

export const updateTrackingId = async (params: InterfaceUpdateTrackingId) => {
  const res = await request.post(
    '/api/v2/apps/process/inbound/counting/update_tracking_id',
    {
      ...params,
    }
  );
  return res;
};

interface InterfaceCheckExceptionTrackingId {
  group_id?: string | (string | null)[];
  tracking_id?: string;
  sku_id?: string;
  is_wrong_sku?: number;
  counting_qty?: number;
  exception_tracking_id?: string;
}

export const checkExceptionTrackingId = async (
  params: InterfaceCheckExceptionTrackingId
) => {
  const res = await request.get(
    '/api/v2/apps/process/inbound/counting/check_exception_tracking_id',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceGetDevConf {
  conf_key: string;
  whs_id: string;
}

export const getDevConf = async (params: InterfaceGetDevConf) => {
  const res = await request.get('/api/v2/configuration/get_dev_conf', {
    params: {
      ...params,
    },
  });
  return res;
};

interface InterfaceGetDevConfList {
  conf_key: string[];
  whs_id: string;
}

export const getDevConfList = async (params: InterfaceGetDevConfList) => {
  const res = await request.post('/api/v2/configuration/get_conf_list', {
    ...params,
  });
  return res;
};

interface InterfaceCheckTrackingId {
  tracking_id: string;
}

export const checkTrackingId = async (params: InterfaceCheckTrackingId) => {
  const res = await request.get(
    '/api/v2/apps/process/inbound/counting/check_tracking_id',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};

interface InterfaceGetExpectedQcQty {
  group_id: string | (string | null)[];
  supplier_id: string;
  sku_id: string;
}

export const getExpectedQcQty = async (params: InterfaceGetExpectedQcQty) => {
  const res = await request.get(
    '/api/v2/apps/process/inbound/counting/get_expected_qc_qty',
    {
      params: {
        ...params,
      },
    }
  );
  return res;
};
