<template>
  <div
    :class="[
      'fuzzy-search-wrap',
      isCollapse ? 'collapse-fuzzy-search-wrap' : '',
    ]"
  >
    <s-input
      class="fuzzy-search"
      v-model="searchText"
      @input="filter"
      @focus="showSearchContent = true"
      @blur="handleBlur"
      :show-search-btn="!searchText || !searchText.length"
      :clearable="searchText && !!searchText.length"
    ></s-input>
    <div
      v-if="showSearchContent"
      :class="[
        'fuzzy-search-content',
        searchText && !searchText.length && historyList.length
          ? 'history-fuzzy-search-content'
          : '',
      ]"
    >
      <ul>
        <li v-for="item in showList" :key="item.title">
          <p
            @click="showSearchContent = true"
            :class="[
              'title',
              !searchText || !searchText.length ? 'history-title' : '',
            ]"
            v-html="getHighlight(item.title)"
          ></p>
          <ul>
            <li
              class="item"
              v-for="subItem in item.list"
              :key="subItem.title"
              @click="goLink(item.title, subItem)"
            >
              <svg-icon
                icon-class="clock"
                class="clock-icon"
                v-if="!searchText || !searchText.length"
              ></svg-icon>
              <span v-html="getHighlight(subItem.title)"></span>
            </li>
          </ul>
        </li>
      </ul>
      <div
        class="no-result"
        v-if="searchText && searchText.length > 1 && !showMenuList.length"
      >
        {{ $i18n('No Result') }}
      </div>
      <div class="clear-btn">
        <s-button
          type="text"
          v-if="(!searchText || !searchText.length) && historyList.length"
          @click="clearHistory"
        >
          Clear
        </s-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Debounce } from '@/utils/decorator';

interface SearchMenuItemInterface {
  title: string;
  list: Array<Record<'title' | 'name', string>>;
}

interface SearchHistoryMenuItemInterface {
  title: string;
  subItem: Record<'title' | 'name', string>;
}

const SEARCH_HISTORY_KEY = 'searchMenuHistory';

@Component
export default class FuzzySearch extends Vue {
  @Prop({ default: () => [] }) menuList: any;
  @Prop({ default: false }) isCollapse: boolean;
  searchText = '';
  showSearchContent = false;
  showMenuList: Array<SearchMenuItemInterface> = [];
  historyList: Array<SearchMenuItemInterface> = [];

  get showNoResult() {
    return (
      this.searchText &&
      this.searchText.length > 1 &&
      !this.showMenuList.length &&
      !this.historyList.length
    );
  }

  get showList() {
    if (!this.searchText || this.searchText.length === 0)
      return this.historyList;
    if (this.searchText.length === 1) return [];
    return this.showMenuList;
  }

  @Debounce(300)
  filter() {
    this.showMenuList = [];
    if (!this.searchText || this.searchText.length < 2) return;
    const list: Array<SearchMenuItemInterface> = [];
    this.menuList.forEach((item: any) => {
      this.filterShowMenuList(list, item, '');
    });
    this.showMenuList = list;
  }

  handleBlur() {
    setTimeout(() => {
      this.showSearchContent = false;
    }, 300);
  }

  getHighlight(text: string) {
    text = this.$i18n(text);
    if (!this.searchText || this.searchText.length < 1) return text;
    return text.replace(
      new RegExp(this.searchText, 'ig'),
      (m) => `<span style="color:#EE4D2D">${m}</span>`
    );
  }

  filterShowMenuList(
    res: Array<SearchMenuItemInterface>,
    item: any,
    pre: string
  ) {
    if (!item || !item.children) return;
    const tempList: Array<Record<'title' | 'name', string>> = [];
    item.children.forEach((subItem: any) => {
      if (subItem.children) {
        this.filterShowMenuList(res, subItem, pre + item.meta.title + ' / ');
      } else {
        const title = this.$i18n(subItem.meta.title);
        title.toUpperCase().includes(this.searchText.toUpperCase()) &&
          tempList.push({ title: subItem.meta.title, name: subItem.name });
      }
    });
    if (tempList.length)
      res.push({ title: pre + item.meta.title, list: tempList });
  }

  goLink(title: string, subItem: Record<'title' | 'name', string>) {
    if (this.$route.name !== subItem.name)
      this.$router.push({ name: subItem.name });
    this.searchText = '';
    this.showSearchContent = false;
    const tempList = JSON.parse(
      localStorage.getItem(SEARCH_HISTORY_KEY) as string
    );
    let preHistoryList: Array<SearchHistoryMenuItemInterface> = Array.isArray(
      tempList
    )
      ? tempList
      : [];
    const newSearchItem = { title, subItem };
    preHistoryList = preHistoryList.filter(
      (e) => JSON.stringify(e) !== JSON.stringify(newSearchItem)
    );
    preHistoryList.unshift(newSearchItem);
    localStorage.setItem(
      SEARCH_HISTORY_KEY,
      JSON.stringify(preHistoryList.slice(0, 5))
    );
    this.historyList = this.initHistoryList();
  }

  initHistoryList(): Array<SearchMenuItemInterface> {
    let list = JSON.parse(localStorage.getItem(SEARCH_HISTORY_KEY) as string);
    list = Array.isArray(list) ? list : [];
    const hash: Record<string, Array<Record<'title' | 'name', string>>> = {};
    list.forEach((e: SearchHistoryMenuItemInterface) => {
      !hash[e.title] && (hash[e.title] = []);
      hash[e.title].push(e.subItem);
    });
    return Object.keys(hash).map((key: string) => ({
      title: key,
      list: hash[key],
    }));
  }

  clearHistory() {
    localStorage.removeItem(SEARCH_HISTORY_KEY);
    this.historyList = this.initHistoryList();
  }

  mounted() {
    this.historyList = this.initHistoryList();
  }
}
</script>

<style lang="scss" scoped>
.fuzzy-search-wrap {
  position: relative;
  margin: 4px 16px;
  width: 208px;
}

.fuzzy-search {
  width: 100% !important;
  background: #222933;

  ::v-deep input {
    color: #ffffff !important;
  }
}

.collapse-fuzzy-search-wrap {
  border-radius: 4px 4px 0 0;
  padding: 8px 12px;
  width: 360px;
  background: #ffffff;

  .fuzzy-search-content {
    top: 48px;
    border-radius: 0 0 4px 4px;
    width: 362px;
  }
}

.fuzzy-search-content {
  position: absolute;
  top: 36px;
  left: 0;
  z-index: 99;
  overflow: auto;
  border-radius: 4px;
  //position: relative;
  padding: 0 0 16px;
  min-width: 208px;
  max-width: 360px;
  min-height: 48px;
  max-height: 320px;
  background: #ffffff;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;

  .no-result {
    margin: 16px 12px 0;
    font-size: 14px;
    font-weight: 400;
    color: #b5bbc6;
    line-height: 16px;
  }

  .title {
    overflow: hidden;
    margin: 16px 0 4px;
    padding: 0 12px;
    font-size: 14px;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #b5bbc6;
    line-height: 16px;
  }

  .history-title {
    padding: 0 36px;
  }

  .item {
    overflow: hidden;
    padding: 0 14px;
    height: 32px;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #303844;
    line-height: 32px;
    cursor: pointer;

    &:hover {
      background: #f5f6f9;
    }

    .clock-icon {
      margin-right: 10px;
      width: 12px;
      height: 12px;
      vertical-align: -1;
    }
  }

  .clear-btn {
    display: flex;
    justify-content: right;
    margin-top: 12px;
    padding-right: 12px;

    .ssc-button:hover.ssc-btn-type-text {
      text-decoration: none;
    }
  }
}

::v-deep .clear-icon {
  width: 14px;
  height: 14px;
  cursor: pointer;
}
</style>
