import { DataCollectorInstance, TransactionCollector } from '@scfe-common/core';
import { handleResponseData } from '../../utils';
import { ActionType, RequestState } from '@scfe-common/chain-tracker';
import { SLA_CHAINS_TYPE } from '@/utils/chain-tracker/constants';

export default [
  {
    name: 'SLASaleOutboundPickingChain',
    status: {
      errorCount: 0,
    },
    collector: {} as TransactionCollector,
    ALLName: 'custom_SaleOutboundPicking',
    HandleOneSkuName: 'custom_SaleOutboundPicking_HandleOneSku',
    GetTaskDetailName: 'custom_SaleOutboundPicking_get_task_detail',
    ErrorCount: 'custom_SaleOutboundPicking_error_count',
    getPointList: function () {
      return [
        // Picking开始：扫描 picking id
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/picking\/get_task_detail(\?.*)?$/,
            state: RequestState.Before,
          },
          handler: () => {
            this.collector = DataCollectorInstance.createTransactionCollector();
            this.collector.startTrack({
              name: this.ALLName,
            });
            this.collector.startTrack({
              name: this.GetTaskDetailName,
            });
          },
        },
        // 可以开始做任务
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/picking\/get_task_detail(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, sendDataFn: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode === 0) {
              const [result] = this.collector.endTrackByName({
                name: this.GetTaskDetailName,
                isSuccess: true,
              });
              sendDataFn({
                pointId: result.name,
                customData: { ...result, type: 'experienceTime' },
              });
            }
          },
        },
        // 开始做单条 SKU 明细
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/picking\/validate_picking_location(\?.*)?$/,
            state: RequestState.Before,
          },
          handler: () => {
            this.collector.startTrack({
              name: this.HandleOneSkuName,
              parentName: this.ALLName,
            });
          },
        },
        // 结束做单条 SKU 明细
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/picking\/scan_record(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, sendDataFn: any) => {
            const response = handleResponseData(trackData);
            if (response.retcode === 0) {
              const [result] = this.collector.endTrackByName({
                name: this.HandleOneSkuName,
                isSuccess: true,
              });
              sendDataFn({
                pointId: result.name,
                customData: { ...result, type: 'experienceTime' },
              });
            }
          },
        },
        // 监听所有的请求报错
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/picking\//,
            state: RequestState.After,
          },
          handler: (trackData: any, _: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode !== 0) {
              this.status.errorCount += 1;
            }
          },
        },
        // Picking结束：验证LMTN
        {
          type: ActionType.REQUEST,
          rule: {
            url: /^\/api\/v2\/apps\/process\/outbound\/picking\/complete_picking(\?.*)?$/,
            state: RequestState.After,
          },
          handler: (trackData: any, sendDataFn: any) => {
            const response = handleResponseData(trackData);
            if (response?.retcode === 0) {
              const results = this.collector.endTrackByName({
                name: this.ALLName,
                isSuccess: true,
                endAll: true,
              });
              results.forEach((result) => {
                sendDataFn({
                  pointId: result.name,
                  customData: { ...result, type: 'experienceTime' },
                });
              });
              sendDataFn({
                pointId: this.ErrorCount,
                customData: {
                  errorCount_n: this.status.errorCount,
                  type: 'experienceCount',
                },
              });
            }
            DataCollectorInstance.removeTransactionCollector({
              traceId: this.collector.traceId,
            });
          },
        },
      ];
    },
  },
];
