<template>
  <svg class="svg-icon" aria-hidden="true" @click="$emit('click')">
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({
  name: 'SvgIcon',
})
export default class SvgIcon extends Vue {
  @Prop() readonly iconClass!: string;
  get iconName() {
    return `#icon-${this.iconClass}`;
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  width: 24px;
  height: 24px;
  fill: currentColor;
  overflow: hidden;
}
</style>
