<template>
  <div class="shop-filter-card">
    <header class="shop-filter-header">
      <div class="shop-filter-header-left">
        <span class="title">{{ $i18n(title) }}</span>
      </div>
      <div class="shop-filter-header-right">
        <s-select
          v-if="filterTypeLabel"
          class="filter-select"
          v-model="inputValue[1]"
          @visible-change="handleHeaderSelectChange"
          @change="handleLatitudeChange"
        >
          <template v-slot:reference>
            <span class="filter-span"> {{ $i18n(filterTypeLabel) }} </span>
            <SIconChevronDown v-if="!isArrowsExpand" />
            <SIconChevronUp v-else />
          </template>
          <s-option
            v-for="item in selectFilterOptions"
            :key="item.value"
            :value="item.value"
            :label="$i18n(item.label)"
          >
            {{ $i18n(item.label) }}
          </s-option>
        </s-select>
        <s-checkbox
          v-if="multipleInputMode"
          :value="selectMode"
          :checked="selectMode"
          @change="selectModeChange"
        />
        <s-select
          ref="ssc-select"
          :remote="true"
          :multiple="true"
          :clearable="true"
          :filterable="true"
          :need-confirm="true"
          :automatic-dropdown="true"
          :loading="loading"
          :remote-method="remoteMethod"
          :syncRemoteLoading="searchOptionsLoading"
          v-model="selectChooseValue"
          @change="handleLatitudeChange"
        >
          <template v-slot:reference>
            <s-button type="text" class="add-group-btn" :disabled="!selectMode">
              <span>{{ $i18n('Select') }}</span></s-button
            >
          </template>

          <template>
            <s-option
              v-for="_ in checkBoxOptions"
              :key="_.value"
              :value="_.value"
              @click.native.prevent="handleClickOption(_.value)"
            >
              {{ $i18n(_.label) }}
            </s-option>
          </template>
        </s-select>
      </div>
    </header>

    <content v-if="selectMode" class="shop-filter-content">
      <s-tag
        class="item-tag"
        max-width="180px"
        :key="item.value"
        :closable="item.value !== -1"
        v-for="item in tagOptions"
        @close="handleRemoveTag(item.value)"
      >
        {{ $i18n(item.label) }}
      </s-tag>
    </content>
    <content class="g-content" v-else>
      <s-textarea
        clearable
        class="g-textarea"
        resize="vertical"
        :auto-rows="{ minRows: 2 }"
        :placeholder="$i18n('IDs are separated by comma,newline,space')"
        v-model="inputChosenValue"
        @blur="handleInputBlur"
      />
    </content>
  </div>
</template>

<script lang="ts">
import {
  Component,
  Vue,
  Prop,
  Ref,
  Watch,
  Model,
} from 'vue-property-decorator';
@Component({
  components: {},
})
export default class SelectCard extends Vue {
  @Model('change') inputValue: Array<any>;
  @Prop() title!: string;
  @Prop({ default: () => [] }) selectFilterOptions!: any[];
  @Prop({ default: () => [] }) options: any[];
  @Prop({ default: () => false }) multipleInputMode: boolean;
  @Ref('ssc-select') SelectComponent: any;
  @Prop() remoteMethod: Function;
  @Prop() loading: false;
  isArrowsExpand = false;
  searchOptionsLoading = false;
  selectMode = true;

  selectChooseValue: any[] = [];
  inputChosenValue = '';

  tagValueLabelMap: Map<string | number, string> = new Map();

  @Watch('inputValue', { deep: true })
  inoutValueChange(value: any) {
    if (value[0].length) {
      this.selectChooseValue = value[0];
    } else {
      this.selectChooseValue = [-1];
      this.inputChosenValue = (value[0] || []).join(',');
    }
  }

  @Watch('options')
  getTagValueLabelMap() {
    if (this.options.length === 0) {
      this.tagValueLabelMap.set(-1, 'All');
    } else {
      this.options.map((item: any) => {
        this.tagValueLabelMap.set(item.value, item.label);
      });
    }
  }

  get filterTypeLabel() {
    let label = '';
    (this.selectFilterOptions || []).forEach((item: any) => {
      if (item.value === this.inputValue[1]) {
        label = item.label;
      }
    });
    return label;
  }

  get checkBoxOptions() {
    const res = [
      {
        label: 'All',
        value: -1,
      },
    ].concat(this.options);
    return res;
  }

  get tagOptions() {
    if (!this.selectChooseValue || this.selectChooseValue[0] === -1) {
      return [
        {
          label: 'All',
          value: -1,
        },
      ];
    }
    const res = this.selectChooseValue.map((value: number | string) => {
      return {
        label: this.tagValueLabelMap.get(value) || value,
        value,
      };
    });
    return res;
  }

  selectModeChange(value: boolean) {
    this.$emit('change', [[], this.inputValue[1]]);
    this.selectMode = !this.selectMode;
  }

  handleClickOption(val: number) {
    const ifAll = this.SelectComponent.valueToConfirm.indexOf(-1) !== -1;

    // 如果点击的是 all
    if (val === -1) {
      if (ifAll) {
        this.SelectComponent.valueToConfirm = [-1];
      } else {
        const index = this.SelectComponent.valueToConfirm.indexOf(-1);
        this.SelectComponent.valueToConfirm.splice(index, 1);
      }
    }
    // 如果点击的是非 all
    else {
      if (ifAll) {
        const index = this.SelectComponent.valueToConfirm.indexOf(-1);
        this.SelectComponent.valueToConfirm.splice(index, 1);
      }
    }
  }

  handleHeaderSelectChange(visible: boolean) {
    this.isArrowsExpand = visible;
  }

  handleLatitudeChange() {
    const value =
      this.selectChooseValue[0] === -1 ? [] : this.selectChooseValue;
    this.$emit('change', [value, this.inputValue[1]]);
  }

  handleInputBlur() {
    this.$emit('change', [this.inputChosenValue, this.inputValue[1]]);
  }

  handleRemoveTag(value: number | string) {
    const index = this.selectChooseValue.findIndex((_: string | number) => {
      return value === _;
    });
    this.selectChooseValue.splice(index, 1);
    if (!this.selectChooseValue.length) {
      this.selectChooseValue = [-1];
    }
  }
}
</script>

<style scoped lang="scss">
.shop-filter-card {
  border: 1px solid #ecf0f4;
  border-radius: 4px;
  width: 100%;
  height: 112px;
  flex-grow: 0;
  flex-shrink: 0;
  background: #ffffff;

  .shop-filter-header {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin: 12px;
    height: 16px;

    .shop-filter-header-left {
      display: flex;
      align-items: center;

      .title {
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        line-height: 16px;
      }
    }

    .shop-filter-header-right {
      display: flex;
      align-items: flex-start;
      .filter-select {
        width: 100%;
        margin-right: 12px;
        ::v-deep .ssc-select-reference-wrapper {
          margin: -4px 0 0;

          // width: unset;
          justify-content: flex-end;
        }
      }
    }

    ::v-deep .ssc-select {
      width: 40px;
    }
  }

  .shop-filter-content {
    display: flex;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px 12px;
    height: 63px;
    flex-wrap: wrap;

    .item-tag {
      margin: 0 500px 4px 0;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      margin: 0 8px 0 0;
      border-radius: 10px;
      background: #acb3bf;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #999999;
    }

    &::-webkit-scrollbar-corner {
      background: #179a16;
    }
  }
  .g-content {
    display: flex;
    overflow-y: auto;
    height: 63px;
  }
  .g-textarea {
    min-width: 100%;
    padding: 0px 12px;

    & ::v-deep .ssc-textarea {
      min-width: 100%;
    }
  }
}
</style>
