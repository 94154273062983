<template>
  <s-dialog
    :visible="true"
    :title="$i18n('Change Password')"
    :append-to-body="true"
    :close-on-click-modal="false"
    :before-close="handleCancel"
    width="400px"
    height="470px"
  >
    <s-form
      :model="form"
      class="create-menu-perm-node-form"
      ref="createFormRef"
      :rules="rules"
    >
      <s-form-item
        :label="$i18n('User ID')"
        label-position="top"
        prop="user_id"
      >
        <span>{{ form.user_id }}</span>
      </s-form-item>
      <s-form-item :label="$i18n('Email')" label-position="top" prop="email">
        <span>{{ form.email }}</span>
      </s-form-item>
      <s-form-item
        :label="$i18n('Current Password')"
        label-position="top"
        prop="old_pwd"
      >
        <s-input v-model="form.old_pwd" :show-password="true" type="password" />
      </s-form-item>
      <s-form-item
        :label="$i18n('New Password')"
        label-position="top"
        prop="new_pwd"
      >
        <template #label>
          <span>{{ $i18n('New Password') }}</span>
          <s-popover
            placement="top"
            trigger="hover"
            :content="
              $i18n(
                'Password must be 8-16 characters long, and contain one uppercase and one lowercase character.'
              )
            "
          >
            <template #reference>
              <s-icon-help-outline class="s-icon-help" />
            </template>
          </s-popover>
        </template>
        <s-input v-model="form.new_pwd" :show-password="true" type="password" />
      </s-form-item>
    </s-form>
    <div slot="footer" class="create-menu-perm-node-modal-footer">
      <s-button @click="handleCancel" class="dialog-cancel-btn">
        {{ $i18n('Cancel') }}
      </s-button>
      <s-button type="primary" @click="handleConfirm">
        {{ $i18n('Confirm') }}
      </s-button>
    </div>
  </s-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import formErrorScroll from '@/utils/form-error';
import { getRenderValue } from '@/utils/safeGet';
import { Md5 } from 'ts-md5/dist/md5';
import { isWeakPassword } from '@/utils/tools';
import { isEmpty, isEqual, isNil } from 'lodash';

@Component({
  components: {},
})
export default class ChangePasswordDialog extends Vue {
  @Prop()
  userDetail: Record<string, any>;

  @Prop()
  onConfirm: Function;

  @Prop()
  onCancel: Function;

  form: {
    user_id?: string;
    email?: string;
    new_pwd?: string;
    old_pwd?: string;
  } = {};

  getRenderValue = getRenderValue;

  validateNewPassword(_rule: any, value: string, callback: Function) {
    if (isEmpty(value)) {
      callback(new Error(this.$i18n('New Password Need!')));
      return false;
    }

    if (value.length < 8) {
      callback(
        new Error(
          this.$i18n('New Password length should between 8 and 20 letters')
        )
      );
      return false;
    }

    if (value.length > 16) {
      callback(
        new Error(
          this.$i18n('New Password length should between 8 and 20 letters')
        )
      );
      return false;
    }

    if (isWeakPassword(value)) {
      callback(
        new Error(
          this.$i18n(
            'New Password must contain one uppercase and one lowercase character.'
          )
        )
      );
      return false;
    }

    if (isEqual(value, this.form.old_pwd)) {
      callback(
        new Error(this.$i18n('The New Password can\'t equal Current Password'))
      );
      return false;
    }
    callback();
    return true;
  }

  rules = {
    old_pwd: [
      {
        required: true,
        trigger: 'blur',
        message: this.$i18n('Current Password Need!'),
      },
    ],
    new_pwd: [
      {
        required: true,
        validator: this.validateNewPassword,
        trigger: 'blur',
      },
    ],
  };

  mounted() {
    this.form = { ...this.userDetail };
  }

  handleConfirm() {
    (this.$refs['createFormRef'] as any).validate((valid: boolean) => {
      if (valid) {
        const requestParams = { ...this.form };
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        requestParams.new_pwd = Md5.hashStr(requestParams.new_pwd!);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        requestParams.old_pwd = Md5.hashStr(requestParams.old_pwd!);
        this.onConfirm(requestParams);
      } else {
        this.$nextTick(() => {
          formErrorScroll();
        });
        return false;
      }
    });
  }

  handleCancel() {
    (this.$refs['createFormRef'] as any).resetFields();
    this.onCancel();
  }
}
</script>

<style lang="scss">
.create-menu-perm-node-form {
  .ssc-form-item-content {
    width: 100%;

    .ssc-input {
      width: 100%;
    }
  }
}

.create-menu-perm-node-modal-footer {
  .dialog-cancel-btn {
    margin-right: 16px;
  }
}

.select-hire-status {
  width: 100%;
}

.s-icon-help {
  position: relative;
  top: 2px;
  left: 2px;
  width: 16px;
  color: #7e8692;
  cursor: pointer;
}

.s-tooltip-sub-content {
  margin-left: 20px;
}
</style>
